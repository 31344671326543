import { observer } from "mobx-react"
import React, { useContext } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useNavigate, useParams } from "react-router-dom"
import uuid from "react-uuid"
import styled from "styled-components"
import AdminComCardDeleteButton from "../../../components/buttons/AdminComCardDeleteButton"
import BackArrowButton from "../../../components/buttons/BackArrowButton"
import DragPointer from "../../../components/icons/DragPointer"
import UploadFile from "../../../components/icons/UploadFile"
import AdminInfoInput from "../../../components/inputs/AdminInfoInput"
import AdminSelectBox from "../../../components/inputs/AdminSelectBox"
import AdminTableCheckbox from "../../../components/inputs/AdminTableCheckbox"
import AdminHashtagSelectModal from "../../../components/modals/AdminHashtagSelectModal"
import AdminImageCropModal from "../../../components/modals/AdminImageCropModal"
import AdminInstagramImageUploadModal from "../../../components/modals/AdminInstagramImageUploadModal"
import Spacer from "../../../components/Spacer"
import { UtilService } from "../../../service/UtilService"
import { StoreContext } from "../../../store/store.context"

const S = {
  Container: styled.div`
    height: 975px;
    padding: 0px 36px;
    width: 100%;
    background-color: #f7f9fb;
    overflow-y: auto;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    width: 100%;
    height: 48px;
    padding-top: 36px;
  `,
  Title: styled.text`
    display: flex;
    width: auto;
    height: 36px;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f4a;
  `,
  TitleSubContainer: styled.div`
    display: flex;
    align-items: center;
  `,
  BackArrow: styled.div`
    margin-right: 10px;
  `,
  ControllBox: styled.div`
    display: flex;
  `,
  RegisterButton: styled.button`
    height: 48px;
    padding: 12px 60px;
    background: #00baff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    :hover {
      background: #0094ca;
    }
  `,
  Navigation: styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  `,
  NavigationItem: styled.div<{ isCurrentPage: boolean }>`
    display: flex;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => {
      if (props.isCurrentPage) {
        return `#3b3f4a`
      } else {
        return `#BEC1C7`
      }
    }};
    cursor: pointer;
  `,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  InputSubContainer: styled.div`
    gap: 8px;
  `,
  InfoImageContainer: styled.div`
    display: flex;
    gap: 20px;
  `,
  ComCardLabelContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  InputLabel: styled.div`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #5a5e6a;
    margin-bottom: 8px;
    span {
      margin-left: 12px;
      font-size: 10px;
    }
  `,
  RedText: styled.text`
    color: red;
  `,
  HashtagButton: styled.button`
    display: block;
    height: 48px;
    width: 200px;
    padding: 12px 32.5px;
    background: #e5f8ff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #00baff;
    :hover {
      background: #b3ebff;
    }
  `,
  HashtagNameContainer: styled.div`
    display: flex;
    width: 338px;
    height: 48px;
    /* justify-content: center; */
    align-items: center;
    padding: 0px 16px;
    background: #eff1f4;
    border: 1px solid #e4e6ea;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #5a5e6a;
  `,
  ImageUploadButton: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 180px;
    height: 236px;
    background: #bec1c7;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #ffffff;
    font-size: 12px;
    border: 1px solid #bec1c7;
    cursor: pointer;
    :hover {
      border: 1px solid #dedede;
    }
  `,
  MainImage: styled.img`
    display: flex;
    width: 180px;
    height: 236px;
    border-radius: 10px;
    border: 1px solid #bec1c7;
    cursor: pointer;
    :hover {
      border: 1px solid #dedede;
    }
  `,
  ComCardImage: styled.img`
    display: flex;
    width: auto;
    height: 236px;
    border-radius: 10px;
    border: 1px solid #bec1c7;
    cursor: pointer;
    :hover {
      border: 1px solid #dedede;
    }
  `,
  VideoUploadButton: styled.label`
    display: flex;
    align-items: center;
    padding: 0px;
    width: 340px;
    height: 52px;
    background: #ffffff;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #5a5e6a;
    font-size: 14px;
    padding: 0px 16px;
    font-weight: 400;
    line-height: 21px;
    gap: 13.5px;
    cursor: pointer;
    :hover {
      background: #e4e6ea;
    }
  `,
  VideoInput: styled.input`
    display: none;
  `,
  FileUploadButton: styled.div`
    display: flex;
    align-items: center;
    padding: 0px;
    width: 340px;
    height: 52px;
    background: #ffffff;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #5a5e6a;
    font-size: 14px;
    padding: 0px 16px;
    font-weight: 400;
    line-height: 21px;
    gap: 13.5px;
    cursor: pointer;
    :hover {
      background: #e4e6ea;
    }
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
  `,
  TableHedaer: styled.thead`
    padding: 0px;
    height: 20px;
    border-radius: 8px;
    flex: none;
  `,
  TableBody: styled.tbody``,
  TableHeaderRow: styled.tr`
    th:first-child {
      width: 85px;
    }
    th:last-child {
      width: 78px;
      border: none;
    }
    font-weight: 500;
  `,
  TableHeaderRowImages: styled.tr`
    th:first-child {
      width: 85px;
      vertical-align: middle;
    }
    th:nth-child(3) {
      width: 196px;
      vertical-align: middle;
    }
    th:last-child {
      width: 78px;
      border: none;
      vertical-align: middle;
    }
    font-weight: 500;
  `,
  TableBodyRow: styled.tr<{ isDragging?: boolean }>`
    background-color: ${(props) => (props.isDragging ? "#E5F8FF" : "white")};
    width: 100%;
    display: ${(props) => (props.isDragging ? "flex" : "table-row")};
    border-radius: 8px;
    vertical-align: middle;

    td:first-child {
      border-radius: 8px 0 0 8px;
      width: 85px;
      cursor: pointer;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
    td:nth-child(2) {
      align-items: center;
      text-align: start;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      flex: auto;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
      width: 78px;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
  `,
  TableBodyRowImages: styled.tr<{ isDragging?: boolean }>`
    background-color: ${(props) => (props.isDragging ? "#E5F8FF" : "white")};
    width: 100%;
    display: ${(props) => (props.isDragging ? "flex" : "table-row")};
    border-radius: 8px;
    vertical-align: middle;

    td:first-child {
      border-radius: 8px 0 0 8px;
      width: 85px;
      cursor: pointer;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
    td:nth-child(2) {
      align-items: center;
      text-align: start;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      flex: auto;
    }
    td:nth-child(3) {
      align-items: center;
      text-align: center;
      justify-content: center;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      width: 196px;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
      width: 78px;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
  `,
  TableHeaderCol: styled.th`
    box-sizing: border-box;
    align-items: center;
    vertical-align: middle;
    padding: 0px 20px;
    width: auto;
    height: 20px;
    border-right: 1px solid #e4e6ea;
    font-size: 12px;
  `,
  TableBodyCol: styled.td`
    box-sizing: border-box;
    align-items: center;
    padding: 8px;
    width: auto;
    align-items: center;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
    height: 52px;
  `,
  Link: styled.div`
    font-size: 10px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #5a5e6a;
    cursor: pointer;
  `,
  InstagramContainer: styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
  `,
  InstagramInputContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 784px);
    /* width: 372px; */
    gap: 20px;
  `,
  InstagramPreviewContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 788px;
    width: 568px;
    align-content: baseline;
    background-color: #000000;
    max-height: 568px;
    padding: 110px 98px;
    gap: 20px;
    row-gap: 20px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    /* justify-content: space-between; */
  `,
  ImagesImageContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  ImagesImage: styled.img`
    display: flex;
    width: 43px;
    height: 36px;
  `,
  ImagesImageName: styled.div`
    display: flex;
  `,
  InstagramImage: styled.img`
    display: flex;
    width: 176px;
    height: 176px;
  `,
}

const AdminModelDetail = () => {
  const { adminStore } = useContext(StoreContext)
  const {
    mainImageUploadModal,
    comCardImageUploadModal,
    modelImagesUploadModal,
    modelInstagramImagesCropModal,
    hashTagSelectModal,
    name,
    eLastName,
    eFirstName,
    birth,
    height,
    categoryId,
    hashTags: hashTag,
    mainImageUrl,
    comCardImageUrl,
    videos,
    images,
    instagramAddress,
    instagram,
    categoryList,
    hashTagList,
    // modal
    onCropSubmitMainImageFile,
    onCropSubmitComCardImageFile,
    onCropSubmitModelImageFile,
    onCropSubmitModelInstagramImageFile,
    onOpenMainImageUploadModal,
    onCloseMainImageUploadModal,
    onOpenComCardImageUploadModal,
    onCloseComCardImageUploadModal,
    onOpenModelImagesUploadModal,
    onCloseModelImagesUploadModal,
    onOpenModelInstagramImagesCropModal,
    onCloseModelInstagramImagesCropModal,
    onOpenHashTagSelectModal,
    onCloseHashTagSelectModal,
    // page
    onPageInitModelDetail,
    // onChange
    onChangeName,
    onChangeELastName,
    onChangeEFirstName,
    onChangeBirth,
    onChangeHeight,
    onChangeCategoryId,
    onDeleteComCardImage,
    onUploadVideo,
    onDeleteVideo,
    onDeleteImage,
    onDeleteInstagramImage,
    onDragEndModelRegisterVideo,
    onDragEndModelRegistImage,
    onDragEndModelRegistInstagramImage,
    onChangeImageShowOnly,
    onChangeInstagramAddress,
    // onSubmit
    onSubmitUpdateModel,
  } = adminStore
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = React.useState("INFO")

  const onBackPage = () => {
    navigate(-1)
  }

  const onClickPage = (page: string) => {
    setCurrentPage(page)
  }

  const params = useParams()

  const onSubmit = () => {
    onSubmitUpdateModel(onBackPage)
  }

  React.useEffect(() => {
    const id = params.id
    onPageInitModelDetail(id, onBackPage)
  }, [])

  return (
    <>
      {/* 대표이미지 모달 */}
      {mainImageUploadModal && (
        <AdminImageCropModal
          isOpen={mainImageUploadModal}
          aspectRatio={1 / 1.35}
          onRequestClose={onCloseMainImageUploadModal}
          onCropSubmit={onCropSubmitMainImageFile}
        />
      )}
      {/* 컴카드이미지 모달 */}
      {comCardImageUploadModal && (
        <AdminImageCropModal
          isOpen={comCardImageUploadModal}
          onRequestClose={onCloseComCardImageUploadModal}
          onCropSubmit={onCropSubmitComCardImageFile}
        />
      )}
      {/* 모델 이미지등록 모달 */}
      {modelImagesUploadModal && (
        <AdminImageCropModal
          isOpen={modelImagesUploadModal}
          aspectRatio={1 / 1.35}
          onRequestClose={onCloseModelImagesUploadModal}
          onCropSubmit={onCropSubmitModelImageFile}
        />
      )}
      {/* 모델 인스타그램 이미지크롭 등록 모달 */}
      {modelInstagramImagesCropModal && (
        <AdminImageCropModal
          isOpen={modelInstagramImagesCropModal}
          aspectRatio={1 / 1}
          onRequestClose={onCloseModelInstagramImagesCropModal}
          onCropSubmit={onCropSubmitModelInstagramImageFile}
        />
      )}
      {/* 모델 해시태그선택 모달 */}
      {hashTagSelectModal && (
        <AdminHashtagSelectModal
          isOpen={hashTagSelectModal}
          hashTagList={hashTagList}
          hashTag={hashTag}
          onRequestClose={onCloseHashTagSelectModal}
        />
      )}

      <S.Container>
        <S.TitleBox>
          <S.TitleSubContainer>
            <S.BackArrow onClick={onBackPage}>
              <BackArrowButton />
            </S.BackArrow>
            <S.Title>모델 상세</S.Title>
          </S.TitleSubContainer>
          <S.ControllBox>
            <S.RegisterButton onClick={onSubmit}>저장하기</S.RegisterButton>
          </S.ControllBox>
        </S.TitleBox>
        <S.Navigation>
          <S.NavigationItem
            isCurrentPage={currentPage === "INFO"}
            onClick={(e) => onClickPage("INFO")}
          >
            모델 정보
          </S.NavigationItem>
          <S.NavigationItem
            isCurrentPage={currentPage === "VIDEO"}
            onClick={(e) => onClickPage("VIDEO")}
          >
            비디오
          </S.NavigationItem>
          <S.NavigationItem
            isCurrentPage={currentPage === "IMAGE"}
            onClick={(e) => onClickPage("IMAGE")}
          >
            이미지
          </S.NavigationItem>
          <S.NavigationItem
            isCurrentPage={currentPage === "INSTAGRAM"}
            onClick={(e) => onClickPage("INSTAGRAM")}
          >
            인스타그램
          </S.NavigationItem>
        </S.Navigation>

        {currentPage === "INFO" && (
          <S.InputContainer>
            <S.InfoImageContainer>
              <S.InputSubContainer>
                <S.InputLabel>
                  대표 이미지<S.RedText>*</S.RedText>
                </S.InputLabel>
                {mainImageUrl === "" ? (
                  <S.ImageUploadButton
                    onClick={() => onOpenMainImageUploadModal()}
                  >
                    <UploadFile color="white" />
                    이미지 업로드하기
                  </S.ImageUploadButton>
                ) : (
                  <S.MainImage
                    src={mainImageUrl}
                    onClick={() => onOpenMainImageUploadModal()}
                  />
                )}
              </S.InputSubContainer>
              <S.InputSubContainer>
                <S.ComCardLabelContainer>
                  <S.InputLabel>컴카드 이미지</S.InputLabel>
                  <AdminComCardDeleteButton onClick={onDeleteComCardImage} />
                </S.ComCardLabelContainer>
                {comCardImageUrl === "" ? (
                  <S.ImageUploadButton
                    onClick={() => onOpenComCardImageUploadModal()}
                  >
                    <UploadFile color="white" />
                    이미지 업로드하기
                  </S.ImageUploadButton>
                ) : (
                  <S.ComCardImage
                    src={comCardImageUrl}
                    onClick={() => onOpenComCardImageUploadModal()}
                  />
                )}
              </S.InputSubContainer>
            </S.InfoImageContainer>
            <S.InputSubContainer>
              <S.InputLabel>
                이름<S.RedText>*</S.RedText>
              </S.InputLabel>
              <AdminInfoInput
                value={name}
                onChange={onChangeName}
                placeholder="이름을 입력하세요."
              />
            </S.InputSubContainer>
            <S.InputSubContainer>
              <S.InputLabel>
                성 (영문)<S.RedText>*</S.RedText>
              </S.InputLabel>
              <AdminInfoInput
                value={eLastName}
                onChange={onChangeELastName}
                placeholder="이름을 입력하세요."
              />
            </S.InputSubContainer>
            <S.InputSubContainer>
              <S.InputLabel>
                이름 (영문)<S.RedText>*</S.RedText>
              </S.InputLabel>
              <AdminInfoInput
                value={eFirstName}
                onChange={onChangeEFirstName}
                placeholder="이름을 입력하세요."
              />
            </S.InputSubContainer>
            <S.InputSubContainer>
              <S.InputLabel>
                Birth<S.RedText>*</S.RedText>
              </S.InputLabel>
              <AdminInfoInput
                value={birth}
                onChange={onChangeBirth}
                placeholder="출생년도를 입력하세요."
              />
            </S.InputSubContainer>
            <S.InputSubContainer>
              <S.InputLabel>
                Height (cm)<S.RedText>*</S.RedText>
              </S.InputLabel>
              <AdminInfoInput
                value={height}
                onChange={onChangeHeight}
                placeholder="키를 입력하세요."
              />
            </S.InputSubContainer>
            <S.InputSubContainer>
              <S.InputLabel>
                카테고리<S.RedText>*</S.RedText>
              </S.InputLabel>
              <AdminSelectBox
                options={categoryList.map((category) => (
                  <option value={category.id}>{category.name}</option>
                ))}
                value={categoryId}
                onChange={onChangeCategoryId}
              />
            </S.InputSubContainer>
            <S.InputSubContainer>
              <S.InputLabel>해시태그</S.InputLabel>
            </S.InputSubContainer>
            <S.HashtagButton onClick={() => onOpenHashTagSelectModal()}>
              해시태그 선택하기
            </S.HashtagButton>
            <S.HashtagNameContainer>
              {hashTagList
                .filter((hashtag) => hashTag.includes(hashtag.id))
                .map((hashtag) => hashtag.tagName)
                .join(", ")}
            </S.HashtagNameContainer>
            <Spacer height={8} />
          </S.InputContainer>
        )}
        {currentPage === "VIDEO" && (
          <S.InputContainer>
            <S.InputSubContainer>
              <S.InputLabel>
                파일 업로드<span>mp4 확장자 파일만 업로드 가능합니다.</span>
              </S.InputLabel>
              <S.VideoUploadButton htmlFor="videoUpload">
                <S.VideoInput
                  id="videoUpload"
                  accept="video/*"
                  type="file"
                  multiple={true}
                  onChange={onUploadVideo}
                />
                <UploadFile />
                비디오 파일 업로드하기
              </S.VideoUploadButton>
            </S.InputSubContainer>
            <S.InputSubContainer>
              <S.Table>
                <S.TableHedaer>
                  <S.TableHeaderRow>
                    <S.TableHeaderCol>순서변경</S.TableHeaderCol>
                    <S.TableHeaderCol>파일명</S.TableHeaderCol>
                    <S.TableHeaderCol>삭제</S.TableHeaderCol>
                  </S.TableHeaderRow>
                </S.TableHedaer>
                <DragDropContext onDragEnd={onDragEndModelRegisterVideo}>
                  <Droppable key={uuid()} droppableId="videoDroppable">
                    {(provided1, snapShot1) => (
                      <S.TableBody
                        ref={provided1.innerRef}
                        {...provided1.droppableProps}
                      >
                        {videos.map((video, index) => (
                          <Draggable
                            key={`videoDraggable/${index}`}
                            draggableId={`videoDraggable/${index}`}
                            index={index}
                          >
                            {(provided2, snapShot2) => (
                              <S.TableBodyRow
                                ref={provided2.innerRef}
                                {...provided2.draggableProps}
                                isDragging={snapShot2.isDragging}
                              >
                                <S.TableBodyCol {...provided2.dragHandleProps}>
                                  <DragPointer />
                                </S.TableBodyCol>
                                <S.TableBodyCol>
                                  {video.file.name === "existFile"
                                    ? UtilService.fileUrlToFileName(video.url)
                                    : video.file.name}
                                </S.TableBodyCol>
                                <S.TableBodyCol>
                                  <S.Link onClick={() => onDeleteVideo(index)}>
                                    삭제
                                  </S.Link>
                                </S.TableBodyCol>
                              </S.TableBodyRow>
                            )}
                          </Draggable>
                        ))}
                        {provided1.placeholder}
                      </S.TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </S.Table>
            </S.InputSubContainer>
          </S.InputContainer>
        )}
        {currentPage === "IMAGE" && (
          <S.InputContainer>
            <S.InputContainer>
              <S.InputSubContainer>
                <S.InputLabel>파일 업로드</S.InputLabel>
                <S.FileUploadButton
                  onClick={() => onOpenModelImagesUploadModal()}
                >
                  <UploadFile />
                  이미지 파일 업로드하기
                </S.FileUploadButton>
              </S.InputSubContainer>
              <S.InputSubContainer>
                <S.Table>
                  <S.TableHedaer>
                    <S.TableHeaderRowImages>
                      <S.TableHeaderCol>순서변경</S.TableHeaderCol>
                      <S.TableHeaderCol>파일명</S.TableHeaderCol>
                      <S.TableHeaderCol>화면에 1장(단독) 노출</S.TableHeaderCol>
                      <S.TableHeaderCol>삭제</S.TableHeaderCol>
                    </S.TableHeaderRowImages>
                  </S.TableHedaer>
                  <DragDropContext onDragEnd={onDragEndModelRegistImage}>
                    <Droppable droppableId="videoDroppable">
                      {(provided1, snapShot) => (
                        <S.TableBody
                          ref={provided1.innerRef}
                          {...provided1.droppableProps}
                        >
                          {images.map((image, imageIndex) => (
                            <Draggable
                              key={`imageDraggable/${imageIndex}`}
                              draggableId={`imageDraggable/${imageIndex}`}
                              index={imageIndex}
                            >
                              {(provided2, snapShot2) => (
                                <S.TableBodyRowImages
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  isDragging={snapShot2.isDragging}
                                >
                                  <S.TableBodyCol
                                    {...provided2.dragHandleProps}
                                  >
                                    <DragPointer />
                                  </S.TableBodyCol>
                                  <S.TableBodyCol>
                                    <S.ImagesImageContainer>
                                      <S.ImagesImage src={image.url} />
                                      <S.ImagesImageName>
                                        {image.file.name === "existFile"
                                          ? UtilService.fileUrlToFileName(
                                              image.url
                                            )
                                          : image.file.name}
                                      </S.ImagesImageName>
                                    </S.ImagesImageContainer>
                                  </S.TableBodyCol>
                                  <S.TableBodyCol>
                                    <AdminTableCheckbox
                                      checked={image.showOnly}
                                      onClick={() =>
                                        onChangeImageShowOnly(imageIndex)
                                      }
                                    />
                                  </S.TableBodyCol>
                                  <S.TableBodyCol>
                                    <S.Link
                                      onClick={() => onDeleteImage(imageIndex)}
                                    >
                                      삭제
                                    </S.Link>
                                  </S.TableBodyCol>
                                </S.TableBodyRowImages>
                              )}
                            </Draggable>
                          ))}
                          {provided1.placeholder}
                        </S.TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </S.Table>
              </S.InputSubContainer>
            </S.InputContainer>
          </S.InputContainer>
        )}
        {currentPage === "INSTAGRAM" && (
          <S.InstagramContainer>
            <S.InstagramInputContainer>
              <S.InputSubContainer>
                <S.InputLabel>인스타그램 계정연결</S.InputLabel>
                <AdminInfoInput
                  value={instagramAddress}
                  onChange={onChangeInstagramAddress}
                  // width="100%"
                  placeholder="인스타그램 주소를 입력해 주세요."
                />
              </S.InputSubContainer>
              <S.InputSubContainer>
                <S.InputLabel>파일 업로드</S.InputLabel>
                <S.FileUploadButton
                  onClick={() => onOpenModelInstagramImagesCropModal()}
                >
                  <UploadFile />
                  이미지 파일 업로드하기
                </S.FileUploadButton>
              </S.InputSubContainer>
              <S.InputSubContainer>
                <S.Table>
                  <DragDropContext
                    onDragEnd={onDragEndModelRegistInstagramImage}
                  >
                    <Droppable key={uuid()} droppableId="instagramDroppable">
                      {(provided1, snapShot1) => (
                        <S.TableBody
                          ref={provided1.innerRef}
                          {...provided1.droppableProps}
                        >
                          {instagram.map((instagramImage, index) => (
                            <Draggable
                              key={`instagramDraggable/${index}`}
                              draggableId={`instagramDraggable/${index}`}
                              index={index}
                            >
                              {(provided2, snapShot2) => (
                                <S.TableBodyRow
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  isDragging={snapShot2.isDragging}
                                >
                                  <S.TableBodyCol
                                    {...provided2.dragHandleProps}
                                  >
                                    <DragPointer />
                                  </S.TableBodyCol>
                                  <S.TableBodyCol>
                                    {instagramImage.file.name === "existFile"
                                      ? UtilService.fileUrlToFileName(
                                          instagramImage.url
                                        )
                                      : instagramImage.file.name}
                                  </S.TableBodyCol>
                                  <S.TableBodyCol>
                                    <S.Link
                                      onClick={() =>
                                        onDeleteInstagramImage(index)
                                      }
                                    >
                                      삭제
                                    </S.Link>
                                  </S.TableBodyCol>
                                </S.TableBodyRow>
                              )}
                            </Draggable>
                          ))}
                          {provided1.placeholder}
                        </S.TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </S.Table>
              </S.InputSubContainer>
            </S.InstagramInputContainer>
            <S.InstagramPreviewContainer>
              {instagram.map((instagramImage) => (
                <S.InstagramImage src={instagramImage.url} />
              ))}
            </S.InstagramPreviewContainer>
          </S.InstagramContainer>
        )}
        <Spacer height={120} />
      </S.Container>
    </>
  )
}

export default observer(AdminModelDetail)
