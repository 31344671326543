import React from "react"
import styled from "styled-components"

interface LogoutButtonProps {
  onClick?: () => void
  width?: number
  height?: number
}

const S = {
  Container: styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1px;

    width: 35px;
    height: 35px;

    background: #ffffff;
    /* Gray Scale/Gray3 */

    border: 1px solid #e4e6ea;
    border-radius: 8px;
    justify-content: center;
    align-items: center;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

    :hover {
      background: #e4e6ea;
    }

    :active {
      background: #b4b6ba;
    }
  `,
}

const LogoutButton: React.FC<LogoutButtonProps> = ({
  onClick,
  width = 20,
  height = 20,
}) => {
  return (
    <S.Container onClick={onClick}>
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.66699 17.796H3.79102C2.86605 17.7936 1.97959 17.4252 1.32544 16.7712C0.671293 16.1173 0.302689 15.231 0.300049 14.306V3.69301C0.302689 2.76804 0.671293 1.88173 1.32544 1.22777C1.97959 0.573809 2.86605 0.205362 3.79102 0.202988H5.66699C6.59196 0.205362 7.47842 0.573809 8.13257 1.22777C8.78672 1.88173 9.15532 2.76804 9.15796 3.69301V5.967C9.15769 6.08812 9.10945 6.20419 9.0238 6.28984C8.93816 6.37549 8.82217 6.42373 8.70105 6.424C8.57966 6.424 8.46322 6.37592 8.3772 6.29027C8.29118 6.20462 8.24247 6.08838 8.24194 5.967V3.69301C8.24141 3.01058 7.97027 2.35622 7.48792 1.87349C7.00556 1.39076 6.35137 1.11906 5.66895 1.118H3.79199C3.1094 1.11879 2.45499 1.39036 1.97241 1.87312C1.48984 2.35589 1.21855 3.01041 1.21802 3.69301V14.303C1.21855 14.9856 1.48984 15.6401 1.97241 16.1228C2.45499 16.6056 3.1094 16.8772 3.79199 16.878H5.66895C6.35137 16.8769 7.00556 16.6052 7.48792 16.1225C7.97027 15.6398 8.24141 14.9854 8.24194 14.303V12.029C8.24221 11.9074 8.29078 11.791 8.37683 11.7051C8.46288 11.6192 8.57949 11.571 8.70105 11.571C8.82235 11.5713 8.93849 11.6196 9.02417 11.7055C9.10985 11.7914 9.15796 11.9077 9.15796 12.029V14.303C9.15611 15.2285 8.78778 16.1156 8.13354 16.7702C7.47931 17.4248 6.59248 17.7936 5.66699 17.796Z"
          fill="black"
        />
        <path
          d="M13.322 14.006C13.2619 14.0063 13.2023 13.9948 13.1467 13.9719C13.0911 13.9491 13.0406 13.9155 12.998 13.873C12.9117 13.7868 12.8632 13.67 12.863 13.548C12.8629 13.488 12.8747 13.4285 12.8977 13.3731C12.9207 13.3176 12.9545 13.2673 12.9971 13.225L16.789 9.43299L4.55003 9.45701C4.42847 9.45701 4.31187 9.40878 4.22581 9.32291C4.13976 9.23705 4.09131 9.12056 4.09105 8.999C4.09158 8.87761 4.14016 8.76137 4.22618 8.67573C4.3122 8.59008 4.42864 8.542 4.55003 8.542L16.741 8.51801L12.999 4.775C12.9566 4.73261 12.9231 4.68225 12.9003 4.62681C12.8774 4.57137 12.8657 4.51196 12.866 4.452C12.8656 4.33065 12.9135 4.21411 12.999 4.128C13.0417 4.0854 13.0924 4.05166 13.1482 4.02866C13.2039 4.00567 13.2636 3.99388 13.324 3.994C13.384 3.99387 13.4434 4.00566 13.4989 4.02866C13.5543 4.05166 13.6046 4.08542 13.647 4.128L17.124 7.60499C17.4933 7.97555 17.7005 8.47736 17.7005 9.0005C17.7005 9.52363 17.4933 10.0254 17.124 10.396L13.647 13.873C13.6044 13.9157 13.5537 13.9494 13.4979 13.9723C13.4421 13.9951 13.3823 14.0066 13.322 14.006Z"
          fill="black"
        />
      </svg>
    </S.Container>
  )
}

export default LogoutButton
