import {
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore/lite"
import moment from "moment"
import { db } from "../firebase"
import { AdminGlobal } from "../models/interfaces/AdminGlobal"

export class GlobalService {
  static getGlobal = async () => {
    const ref = doc(db, "global/ol9Xn5Td5GewSYGSU7Pc")
    const global = await getDoc(ref)

    if (global.exists()) {
      const globalData: AdminGlobal = {
        hashtagState: global.data()["hashtagState"],
        updatedAt: moment(global.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      }

      return globalData
    }
  }

  static updateGlobal = async (updateData: AdminGlobal) => {
    const ref = doc(db, "global/ol9Xn5Td5GewSYGSU7Pc")
    await updateDoc(ref, {
      hashtagState: updateData.hashtagState,
      updatedAt: serverTimestamp(),
    })
  }
}
