const BackArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.259 21.2781C18.0794 21.2785 17.9043 21.2217 17.759 21.116L6.17297 12.681C6.06476 12.6018 5.97673 12.4983 5.91577 12.3788C5.85481 12.2593 5.82268 12.1272 5.82202 11.993C5.8226 11.8589 5.85469 11.7267 5.91565 11.6072C5.97661 11.4877 6.0647 11.3843 6.17297 11.3051L17.759 2.86908C17.9043 2.76343 18.0794 2.70665 18.259 2.70703C18.3933 2.70726 18.5256 2.73907 18.6453 2.79987C18.765 2.86067 18.8686 2.94879 18.948 3.05707C19.08 3.23948 19.1344 3.46673 19.0994 3.68915C19.0643 3.91157 18.9427 4.11109 18.761 4.24408L8.11804 11.9921L18.761 19.7401C18.9427 19.8732 19.0645 20.0729 19.0995 20.2955C19.1345 20.5181 19.0801 20.7455 18.948 20.928C18.8693 21.037 18.7657 21.1255 18.6459 21.1864C18.526 21.2473 18.3934 21.2787 18.259 21.2781Z"
        fill="#0A0A0A"
      />
    </svg>
  )
}

export default BackArrow
