const DragFile = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6383 22.5408H16.7998V24.7038C16.8002 24.9396 16.8705 25.17 17.0018 25.3659C17.1332 25.5618 17.3196 25.7144 17.5377 25.8043C17.7557 25.8942 17.9955 25.9174 18.2267 25.8711C18.458 25.8247 18.6703 25.7108 18.8368 25.5438C18.9477 25.4336 19.0356 25.3024 19.0953 25.158C19.1551 25.0135 19.1855 24.8586 19.1848 24.7023V22.5408H21.3463C21.6622 22.5408 21.9651 22.4153 22.1885 22.1919C22.4118 21.9686 22.5373 21.6656 22.5373 21.3498C22.5373 21.0339 22.4118 20.731 22.1885 20.5076C21.9651 20.2843 21.6622 20.1588 21.3463 20.1588H19.1893V17.9943C19.1893 17.678 19.0637 17.3747 18.84 17.151C18.6164 16.9274 18.3131 16.8018 17.9968 16.8018C17.6805 16.8018 17.3772 16.9274 17.1536 17.151C16.9299 17.3747 16.8043 17.678 16.8043 17.9943V20.1498H14.6383C14.322 20.1498 14.0187 20.2754 13.7951 20.4991C13.5714 20.7227 13.4458 21.026 13.4458 21.3423C13.4458 21.6585 13.5714 21.9619 13.7951 22.1855C14.0187 22.4092 14.322 22.5348 14.6383 22.5348V22.5408Z"
        fill="#BEC1C7"
      />
      <path
        d="M25.8179 7.85999H18.5204C18.359 7.86018 18.1997 7.82434 18.0539 7.75502L14.5274 5.98499C14.0504 5.74865 13.5253 5.62547 12.9929 5.625H10.1669C8.66634 5.62935 7.22844 6.2273 6.16722 7.28824C5.10599 8.34918 4.50768 9.7869 4.50293 11.2875V24.7035C4.5053 26.2056 5.10257 27.6457 6.16405 28.7086C7.22552 29.7715 8.66479 30.3706 10.1669 30.375H25.8164C27.3192 30.3714 28.7593 29.7724 29.8212 28.709C30.8832 27.6457 31.4803 26.2048 31.4819 24.702V13.524C31.478 12.023 30.88 10.5847 29.8186 9.52332C28.7573 8.46197 27.3189 7.86394 25.8179 7.85999V7.85999ZM6.88943 12.3315V11.2815C6.89062 10.4124 7.23651 9.57919 7.85123 8.96475C8.46595 8.35032 9.29929 8.00479 10.1684 8.004H12.9944C13.1565 8.00402 13.3162 8.04205 13.4609 8.11501L16.9874 9.88051C17.4649 10.1154 17.9898 10.238 18.5219 10.239H25.8164C26.4739 10.244 27.1148 10.4453 27.6571 10.8171C28.1993 11.1888 28.6182 11.7141 28.8599 12.3255L6.88943 12.3315ZM6.88943 14.7165H29.0894V24.7035L29.1029 24.774C29.0814 25.6297 28.7266 26.4431 28.1141 27.0411C27.5016 27.639 26.6799 27.9741 25.8239 27.975H10.1669C9.29895 27.9738 8.46674 27.629 7.85228 27.0159C7.23782 26.4029 6.8911 25.5715 6.88793 24.7035L6.88943 14.7165Z"
        fill="#BEC1C7"
      />
    </svg>
  )
}

export default DragFile
