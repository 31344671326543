import {
  collection,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore/lite"
import { db } from "../firebase"
import { AdminUpdated } from "../models/interfaces/AdminUpdated"
import { Model } from "../models/interfaces/Model"
import { ModelService } from "./ModelService"
import { S3Service } from "./S3Service"

export class UpdatedService {
  static getAllUpdated = async () => {
    const updateRef = collection(db, "updated")
    const updatedData = await getDocs(updateRef)
    const modelList = await ModelService.getAllModels()

    let updatedList: Array<AdminUpdated> = []

    for (let i = 0; i < updatedData.docs.length; i++) {
      const adminUpdated: AdminUpdated = {
        id: updatedData.docs[i].id,
        imageUrl: updatedData.docs[i].data()["imageUrl"],
        title: updatedData.docs[i].data()["title"],
        index: updatedData.docs[i].data()["index"],
        model: modelList.find(
          (model) => model.id === updatedData.docs[i].data()["modelId"]
        ),
      }
      updatedList.push(adminUpdated)
    }
    updatedList.sort((a, b) => {
      return a.index - b.index
    })

    return updatedList
  }

  // 업데이트 생성
  static createUpdated = async (
    imageFile: File,
    index: number,
    title: string,
    model?: Model
  ) => {
    const ref = collection(db, "updated")
    const newDoc = doc(ref)
    const newDocId = newDoc.id

    const updatedPrefix = "updated/" + newDocId + "/"

    const imageUrl = await S3Service.uploadFile(imageFile, updatedPrefix)

    await setDoc(newDoc, {
      title: title,
      index: index,
      imageUrl: imageUrl,
      modelId: model ? model.id : "",
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    })
  }

  static updateUpdated = async (
    oldUpdated: AdminUpdated,
    imageFile: File | undefined,
    title: string,
    model?: Model
  ) => {
    const ref = doc(db, `updated/${oldUpdated.id}`)

    const updatedPrefix = "updated/" + oldUpdated.id + "/"
    let imageUrl = oldUpdated.imageUrl
    if (imageFile) {
      imageUrl = await S3Service.uploadFile(imageFile, updatedPrefix)
      const deleteUrl = oldUpdated.imageUrl.split("amazonaws.com/")[1]
      await S3Service.deleteFile(deleteUrl)
    }
    await updateDoc(ref, {
      title: title,
      imageUrl: imageUrl,
      modelId: model ? model.id : "",
      updatedAt: serverTimestamp(),
    })
  }

  //업데이트 순서 정보 수정하기
  static updateAllIndex = async (newUpdatedList: Array<AdminUpdated>) => {
    for (let i = 0; i < newUpdatedList.length; i++) {
      const thisRef = doc(db, `updated/${newUpdatedList[i].id}`)
      await updateDoc(thisRef, {
        index: i,
        updatedAt: serverTimestamp(),
      })
    }
  }

  //업데이트 정보 삭제하기
  static deleteUpdated = async (
    updated: AdminUpdated,
    oldUpdatedList: Array<AdminUpdated>
  ) => {
    const remainUpdatedList = oldUpdatedList.filter((u) => u.id !== updated.id)
    for (let i = 0; i < remainUpdatedList.length; i++) {
      const thisRef = doc(db, `updated/${remainUpdatedList[i].id}`)
      await updateDoc(thisRef, {
        index: i,
        updatedAt: serverTimestamp(),
      })
    }

    //deleteImageFile
    const deleteUrl = updated.imageUrl.split("amazonaws.com/")[1]
    await S3Service.deleteFile(deleteUrl)

    const deleteRef = doc(db, `updated/${updated.id}`)
    await deleteDoc(deleteRef)
  }
}
