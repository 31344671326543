import React from "react";
import {useMediaQuery} from "react-responsive";

interface MobileProps{
    children?: any
}


const Mobile:React.FC<MobileProps> = ({children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024})
    return isMobile? children: null
}

export default Mobile
