import {makeAutoObservable, runInAction} from "mobx"
import {HashTagService} from "../service/HashTagService"
import {HashTag} from "../models/interfaces/HashTag"
import {Category} from "../models/interfaces/Category"
import {Model} from "../models/interfaces/Model"
import {ModelService} from "../service/ModelService"
import {CategoryService} from "../service/CategoryService"
import {ChangeEvent} from "react"
import {UpdatedService} from "../service/UpdatedService"
import {CompanyInfo} from "../models/interfaces/CompanyInfo"
import {CompanyInfoService} from "../service/CompanyInfoService"
import {Agreement} from "../models/interfaces/Agreement"
import {AgreementService} from "../service/AgreementService"
import {AdminUpdated} from "../models/interfaces/AdminUpdated";
import {MailService} from "../service/MailService";
import {AgreementHistory} from "../models/interfaces/AgreementHistory";
import {GlobalService} from "../service/GlobalService";
import {Layout} from "../models/interfaces/Layout";
import {LayoutService} from "../service/LayoutService";
import {UpdatedModel} from "../models/interfaces/UpdatedModel";


export class GlobalStore {
    constructor() {
        makeAutoObservable(this)
    }

    isLoading = true;
    showAllHashtag: boolean = false;
    selectedCategory: string = "UPDATED"
    selectedModel?: Model
    selectedHashTag?: HashTag
    searchInput: string = ""
    modelImageModalIsOpen: boolean = false
    modelDetailModalIsOpen: boolean = false
    agreementModalIsOpen: boolean = false
    histories: AgreementHistory[] = []
    selectedHistoryIndex: number = -1
    agreementHistoryModalIsOpen: boolean = false
    categories: Category[] = []
    hashTags: HashTag[] = []
    models: Model[] = []
    selectedModelDetailModalMenu: string = "PORTFOLIO"
    favoriteIds: string[] = []
    favorites: Model[] = []
    updatedModels: AdminUpdated[] = []
    modalImageUrl: string = ""
    modalModel?: AdminUpdated
    modelPortfolioCurrentImageUrl: string[] = []
    modelPortfolioCurrentIndex: number = 0
    modelVideoIndex: number = 0
    companyInfo?: CompanyInfo
    agreement?: Agreement
    name: string = ""
    phoneNumber: string = ""
    company: string = ""
    memo: string = ""
    showPdfImages: boolean = false
    pdfImages: string[] = []
    layout?: Layout

    //모달 상태값 초기화
    initializeModal = () => {
        runInAction(() => {
            this.selectedModelDetailModalMenu = "PORTFOLIO"
            this.modelPortfolioCurrentIndex = 0
            this.modelVideoIndex = 0
        })
    }
    //메일폼 초기화
    initializeMailForm = () =>
        runInAction(() => {
            this.name = ""
            this.phoneNumber = ""
            this.company = ""
        })
    getGlobalData = async () => {
        const globalData = await GlobalService.getGlobal()
        if (globalData) {
            this.showAllHashtag = globalData.hashtagState;
        }
    }

    //네비게이션 메뉴 관련
    changeMenu = (menu: string) => {
        this.selectedCategory = menu
        this.searchInput = ""
        window.scrollTo(0, 0)
    }
    //이미지 모달열기
    openImageModal = () => (this.modelImageModalIsOpen = true)
    //이미지 모달 닫기
    closeImageModal = () => (this.modelImageModalIsOpen = false)
    //모델 상세 모달 열기
    openModelDetailModal = () => (this.modelDetailModalIsOpen = true)
    //모델 상세 모달 닫기
    closeModelDetailModal = () => (this.modelDetailModalIsOpen = false)
    //약관 동의 모달 열기
    openAgreementModal = () => (this.agreementModalIsOpen = true)

    getAgreementHistories = async () =>
        (this.histories = await AgreementService.getAgreementHistory())
    selectHistoryItem = (index: number) => (this.selectedHistoryIndex = index)
    openAgreementHistoryModal = () => this.agreementHistoryModalIsOpen = true
    closeAgreementHistoryModal = () => this.agreementHistoryModalIsOpen = false
    getAgreementHistoryFromModal = async () => {
        runInAction(() => {
            this.agreement = this.histories[this.selectedHistoryIndex]
            this.agreementHistoryModalIsOpen = false
        })
    }
    //모델 상세 모달 이미지 Index
    showNextImage = () => {
        if (
            this.modelPortfolioCurrentImageUrl.length - 1 ===
            this.modelPortfolioCurrentIndex
        ) {
            this.setModelPortfolioCurrentIndex(0)
        } else {
            runInAction(() => this.modelPortfolioCurrentIndex++)
        }
    }
    showPreviousImage = () => {
        if (this.modelPortfolioCurrentIndex === 0) {
            this.setModelPortfolioCurrentIndex(
                this.modelPortfolioCurrentImageUrl.length - 1
            )
        } else {
            runInAction(() => this.modelPortfolioCurrentIndex--)
        }
    }
    showNextVideo = () => {
        if (this.selectedModel!.videos.length - 1 === this.modelVideoIndex) {
            this.setModelVideoIndex(0)
        } else {
             this.modelVideoIndex++
        }
    }
    showPreviousVideo = () => {
        if (this.modelVideoIndex === 0) {
            this.modelVideoIndex = this.selectedModel!.videos.length - 1
        } else {
           this.modelVideoIndex--
        }
    }
    //약관 동의 모달 닫기
    closeAgreementModal = () => (this.agreementModalIsOpen = false)

    //검색바 onChangeEvent
    onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) =>
        (this.searchInput = e.target.value)
    //즐겨찾기 모델 추가
    addFavoriteModel = (model: Model) => {
        runInAction(() => {
            this.favorites.push(model)
            this.favoriteIds.push(model.id)
        })
        const favoriteData = JSON.stringify(this.favoriteIds)
        localStorage.setItem("favorite", favoriteData)
    }
    //즐겨찾기 모델 삭제
    removeFavoriteModel = (id: string) => {
        this.setFavorite(
            this.favorites.filter((favoriteModel) => favoriteModel.id !== id)
        )
        this.setFavoriteIds(
            this.favoriteIds.filter((favoriteId) => favoriteId !== id)
        )
        const favoriteData = JSON.stringify(this.favoriteIds)
        localStorage.setItem("favorite", favoriteData)
    }

    getIntroData = async () => {
        this.isLoading = true;
        const categories = await CategoryService.getAllCategory()
        this.setCategories(categories)
        this.isLoading = false
    }
    //홈데이터 불러오기
    getHomeData = async () => {

        if (this.models.length === 0) {
            this.isLoading = true;
            const models = await ModelService.getAllModels()
            this.setModels(models)
            const favoriteData = await localStorage.getItem("favorite")
            if (favoriteData) {
                const favoriteList = JSON.parse(favoriteData)
                this.setFavoriteIds(favoriteList)
                let modelList: Model[] = []
                for (let i = 0; i < this.favoriteIds.length; i++) {
                    const modelData = await ModelService.getModelById(favoriteList[i])
                    if (modelData) {
                        modelList.push(modelData)
                    }
                }
                runInAction(() => (this.favorites = modelList))
            }
            const allUpdated = await UpdatedService.getAllUpdated()
            this.setUpdatedModels(allUpdated)
            const categories = await CategoryService.getAllCategory()
            this.setCategories(categories)
            const hashTags = await HashTagService.getAllHashTag()
            this.setHashTags(hashTags)

            this.layout = await LayoutService.getLayout();
            await this.getGlobalData();
            this.isLoading = false;
        }


    }
    getModelData = async (id: string) => {
        const modelData = await ModelService.getModelById(id)
        if (modelData) {
            if (this.favoriteIds.length === 0) {
                const favoriteData = await localStorage.getItem("favorite")
                if (favoriteData) {
                    const favoriteList = JSON.parse(favoriteData)
                    this.setFavoriteIds(favoriteList)
                    for (let i = 0; i < favoriteList.length; i++) {
                        const modelData = await ModelService.getModelById(favoriteList[i])
                        let modelList: Model[] = []
                        if (modelData) {
                            modelList.push(modelData)
                        }
                        runInAction(() => (this.favorites = modelList))
                    }
                }
            }
            if (this.hashTags.length === 0) {
                this.setHashTags(await HashTagService.getAllHashTag())
            }
            this.setSelectedModel(modelData)
            const imageList = modelData.images.map((image) => image.url)
            const videoList = modelData.videos.map((video) => video)
            this.setModelPortfolioCurrentImageUrl(
                [...videoList, ...imageList]
            )
        }
    }

    sendEmail = async () => {
        const favoriteData = this.favorites.map((model) => model.name)
        if (this.name === "") {
            alert("이름을 입력해주세요")
        } else if (this.phoneNumber === "") {
            alert("핸드폰 번호를 입력해주세요")
        } else if (this.company === "") {
            alert("회사명을 입력해주세요")
        } else if (this.memo === "") {
            alert("메모를 입력해주세요")
        } else {
            const status = await MailService.sendMail({
                name: this.name,
                phone: this.phoneNumber,
                company: this.company,
                favorites: favoriteData,
                memo: this.memo
            });
            this.name = ""
            this.phoneNumber = ""
            this.company = ""
            this.memo = ""

            if (status === "Success") {
                alert('전송되었습니다.')
            } else {
                alert('전송실패')
            }
        }
    }

    renderPortfolioContent = () => {

    }

    //카테고리 클릭시 모델데이터 불러오기
    getModelsDataByCategory = async (categoryId: string) => {
        const modelList = await ModelService.getModelsByCategory(categoryId)
        console.log(modelList)
        this.setModels(modelList)
    }

    //전체 태그 클릭시 모델데이터 불러오기
    getModelsDataByAllTag = async (categoryId: string) => {

        this.setModels(await ModelService.getModelsByCategory(categoryId))
    }

    //해시 태그 클릭시 해시태그 관련 모델 불러오기
    getModelsDataByHashTag = async (hashTagId: string) => {
        const category = this.categories.find((category) => category.name === this.selectedCategory)
        if (category) {
            this.setModels(await ModelService.getModelsByHashTag(hashTagId, category.id))
        }

    }
    //회사정보 불러오기
    getCompanyInfo = async () =>
        (this.companyInfo = await CompanyInfoService.getCompanyInfo())
    //개인정보 처리방침 불러오기
    getAgreement = async () =>
        (this.agreement = await AgreementService.getAgreement())
    //이메일 발송하기

    //onChangeEvents
    onChangeName = (e: ChangeEvent<HTMLInputElement>) => this.name = e.target.value
    onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => this.phoneNumber = e.target.value
    onChangeCompany = (e: ChangeEvent<HTMLInputElement>) => this.company = e.target.value
    onChangeMemo = (e: ChangeEvent<HTMLTextAreaElement>) => this.memo = e.target.value

    createDummyCategory = async () => await CategoryService.createDummyCategory()

    //Setters
    setCategories = (categories: Category[]) => (this.categories = categories)
    setHashTags = (hashTags: HashTag[]) => (this.hashTags = hashTags)
    setModels = (models: Model[]) => (this.models = models)
    setUpdatedModels = (updatedModels: AdminUpdated[]) =>
        (this.updatedModels = updatedModels)
    setModalImageUrl = (modelImageUrl: string) =>
        (this.modalImageUrl = modelImageUrl)
    setSearchInput = (input: string) => (this.searchInput = input)
    setFavorite = (models: Model[]) => (this.favorites = models)
    setFavoriteIds = (ids: string[]) => (this.favoriteIds = ids)
    setSelectedModel = (model: Model) => (this.selectedModel = model)
    setSelectedHashTag = (hashTag: HashTag) => this.selectedHashTag = hashTag
    setModelDetailModalMenu = (menu: string) => {
        this.setModelPortfolioCurrentIndex(0)
        this.setModelVideoIndex(0);
        this.selectedModelDetailModalMenu = menu
    }
    setModelPortfolioCurrentImageUrl = (imageUrls: string[]) =>
        (this.modelPortfolioCurrentImageUrl = imageUrls)
    setModelPortfolioCurrentIndex = (index: number) =>
        (this.modelPortfolioCurrentIndex = index)
    setModelVideoIndex = (index: number) => (this.modelVideoIndex = index)
    setPdfImages = (images: string[]) => (this.pdfImages = images)
    showPdfPage = () => (this.showPdfImages = true)
    setModalModel = (model: UpdatedModel) => this.modalModel = model;
}
