import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

interface MailDto {
    name : string,
    phone: string,
    company: string,
    favorites: string[],
    memo: string
}

export class MailService {
    static sendMail = async (dto: MailDto) => {
        const firebaseSendMail = httpsCallable<MailDto, { status: string }>(functions, 'mail' )

        const res = await firebaseSendMail(dto)
        return res.data.status
    }
}