import styled from "styled-components"
import { IoMdClose } from "react-icons/io"
import React, { useContext } from "react"
import Spacer from "../Spacer"
import DragFile from "../icons/DragFile"
import { StoreContext } from "../../store/store.context"
import { observer } from "mobx-react"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"

interface AdminMainImageUplodeModalProps {
  isOpen: boolean
  imgUrl?: string
  aspectRatio?: number
  onRequestClose: () => void
  onCropSubmit: (cropImageDataUrl: string) => void
}

const S = {
  Modal: styled.div<{ isOpen: boolean }>`
    animation: ${(props) => {
      if (props.isOpen) {
        return `FadeIn 0.1s ease-out`
      }
    }};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(25px); */
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  ModalContainer: styled.div`
    width: 414px;
    padding: 21px 20px;
    height: 532px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    border-radius: 16px;
  `,
  ModalHeader: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 28px;
  `,
  ModalHeaderBalencer: styled.div`
    display: flex;
    width: 28px;
    height: 28px;
  `,
  ModalTitle: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    color: #000000;
    @media only screen and (max-width: 764px) {
      font-size: 24px;
    }
  `,
  ModalDivider: styled.div`
    background-color: white;
    height: 2px;
    width: 100%;
  `,
  ModalCloseButton: styled(IoMdClose)`
    /* top: calc(10vh + 40px); */
    /* position: absolute; */
    /* right: 20vw; */
    display: flex;
    color: #0a0a0a;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
  `,
  ModalImageUploadDiv: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: 372px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #bec1c7;
    font-size: 12px;
    border: 1px solid #bec1c7;
    cursor: pointer;
    :hover {
      border: 3px dashed #006912;
    }
  `,
  SubmitButton: styled.button`
    display: block;
    height: 48px;
    width: 100%;
    text-align: center;
    padding: 12px 0px;
    background: #00baff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  `,
  DragInput: styled.input`
    display: none;
  `,
  DragLabel: styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: 372px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #bec1c7;
    font-size: 12px;
    border: 1px solid #bec1c7;
    z-index: 200;
    cursor: pointer;
    :hover {
      border: 3px dashed #006912;
    }
  `,
  CropContainer: styled.div`
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: 372px;
    background: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #bec1c7;
    font-size: 12px;
    border: 1px solid #bec1c7;
  `,
  CustomCropper: styled(Cropper)`
    .cropper-view-box {
      display: block;
      height: 100%;
      outline: 1px solid #ffffff;
      outline-color: #ffffff;
      overflow: hidden;
      width: 100%;
    }
    .cropper-line {
      background-color: #ffffff;
    }
    .cropper-point {
      background-color: #ffffff;
      height: 5px;
      opacity: 0.75;
      width: 5px;
    }
    .cropper-point.point-se::before {
      background-color: #ffffff;
      bottom: -50%;
      content: " ";
      display: block;
      height: 200%;
      opacity: 0;
      position: absolute;
      right: -50%;
      width: 200%;
    }
  `,
}

const AdminImageCropModal: React.FC<AdminMainImageUplodeModalProps> = ({
  isOpen,
  aspectRatio,
  onRequestClose,
  onCropSubmit,
}) => {
  const { adminStore } = useContext(StoreContext)
  const {
    cropModalImageFile,
    cropModalImageSrc,
    onDragCropModalFile: onDragMainImageFile,
    onChangeCropModalFile: onChangeMainImageFile,
  } = adminStore

  const [isDragging, setIsDragging] = React.useState<boolean>(false)
  const dragRef = React.useRef<HTMLLabelElement | null>(null)

  const handleDragIn = React.useCallback((e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDragOut = React.useCallback((e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)
  }, [])

  const handleDragOver = React.useCallback((e: DragEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer!.files) {
      setIsDragging(true)
    }
  }, [])

  const handleDrop = React.useCallback(
    (e: DragEvent): void => {
      e.preventDefault()
      e.stopPropagation()

      onDragMainImageFile(e)
      setIsDragging(false)
    },
    [onDragMainImageFile]
  )

  const initDragEvents = React.useCallback((): void => {
    // 앞서 말했던 4개의 이벤트에 Listener를 등록합니다. (마운트 될때)

    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn)
      dragRef.current.addEventListener("dragleave", handleDragOut)
      dragRef.current.addEventListener("dragover", handleDragOver)
      dragRef.current.addEventListener("drop", handleDrop)
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

  const resetDragEvents = React.useCallback((): void => {
    // 앞서 말했던 4개의 이벤트에 Listener를 삭제합니다. (언마운트 될때)

    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn)
      dragRef.current.removeEventListener("dragleave", handleDragOut)
      dragRef.current.removeEventListener("dragover", handleDragOver)
      dragRef.current.removeEventListener("drop", handleDrop)
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

  React.useEffect(() => {
    initDragEvents()

    return () => resetDragEvents()
  }, [initDragEvents, resetDragEvents])

  const imageRef = React.useRef<HTMLImageElement>(null)
  const [cropper, setCropper] = React.useState<Cropper>()

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const newCropData = cropper.getCroppedCanvas().toDataURL()
      onCropSubmit(newCropData)
    }
  }
  return (
    <>
      {isOpen && (
        <S.Modal isOpen={isOpen}>
          <S.ModalContainer>
            <S.ModalHeader>
              <S.ModalHeaderBalencer />
              <S.ModalTitle>이미지 등록하기</S.ModalTitle>
              <S.ModalCloseButton onClick={onRequestClose} />
            </S.ModalHeader>
            <Spacer height={20} />
            {cropModalImageFile === undefined && (
              <>
                <S.DragLabel htmlFor="fileUpload" ref={dragRef}>
                  <S.DragInput
                    id="fileUpload"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    multiple={false}
                    onChange={onChangeMainImageFile}
                  />
                  <DragFile />
                  여기로 파일을 드래그하세요.
                </S.DragLabel>
              </>
            )}
            {cropModalImageFile !== undefined && (
              <S.CropContainer>
                <S.CustomCropper
                  style={{ height: "100%", width: "100%" }}
                  zoomTo={0}
                  initialAspectRatio={aspectRatio}
                  aspectRatio={aspectRatio}
                  preview=".img-preview"
                  src={cropModalImageSrc}
                  ref={imageRef}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance)
                  }}
                  guides={true}
                />
              </S.CropContainer>
            )}
            <Spacer height={40} />

            <S.SubmitButton onClick={getCropData}>편집 완료</S.SubmitButton>
          </S.ModalContainer>
        </S.Modal>
      )}
    </>
  )
}

export default observer(AdminImageCropModal)
