import { makeAutoObservable, runInAction, toJS } from "mobx"
import React, { ChangeEvent } from "react"
import { AdminCategory } from "../models/interfaces/AdminCategory"
import { AdminModel } from "../models/interfaces/AdminModel"
import { Category } from "../models/interfaces/Category"
import { CreateModelDTO } from "../models/interfaces/dto/CreateModelDTO"
import { HashTag } from "../models/interfaces/HashTag"
import { Model } from "../models/interfaces/Model"
import AdminModelImage from "../models/interfaces/AdminModelImage"
import ModelInstagramImage from "../models/interfaces/ModelInstagramImage"
import { ModelVideo } from "../models/interfaces/ModelVideo"
import { CategoryService } from "../service/CategoryService"
import { HashTagService } from "../service/HashTagService"
import { ModelService } from "../service/ModelService"
import { UtilService } from "../service/UtilService"
import { UpdateModelDTO } from "../models/interfaces/dto/UpdateModelDTO"
import { AdminHashTag } from "../models/interfaces/AdminHashTag"
import { CompanyInfo } from "../models/interfaces/CompanyInfo"
import { CompanyInfoService } from "../service/CompanyInfoService"
import { Agreement } from "../models/interfaces/Agreement"
import { AgreementService } from "../service/AgreementService"
import { AgreementHistory } from "../models/interfaces/AgreementHistory"
import { Layout } from "../models/interfaces/Layout"
import { LayoutService } from "../service/LayoutService"
import { AdminUpdated } from "../models/interfaces/AdminUpdated"
import { UpdatedService } from "../service/UpdatedService"
import { GlobalService } from "../service/GlobalService"

export class AdminStore {
  constructor() {
    makeAutoObservable(this)
  }

  // 로그인 화면 //////////////////////////////////////////////////////////////////////////////////////////
  loggedIn: boolean = false
  id: string = ""
  password: string = ""

  login = () => {
    if (this.id === "admin" && this.password === "admin123") {
      this.loggedIn = true
    } else {
      alert("인증정보가 일치하지 않습니다")
    }
  }

  onKeyEnterLogin = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.login()
    }
  }

  logout = () => {
    this.loggedIn = false
    this.id = ""
    this.password = ""
  }

  onChangeId = (e: ChangeEvent<HTMLInputElement>) => (this.id = e.target.value)

  onChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    (this.password = e.target.value)

  // 공통모달 컴포넌트 //////////////////////////////////////////////////////////////////////////////////////////
  craeteModal: boolean = false
  updateModal: boolean = false
  deleteModal: boolean = false
  noticeModal: boolean = false
  createValue: string = ""
  updateValue: string = ""
  deleteMessage: string = ""
  resourceIndex: number = -1
  noticeTitle: string = ""
  noticeMessage: string = ""
  loading: boolean = false
  noticeCloseAction: (() => void) | undefined

  onChangeCreateValue = (e: ChangeEvent<HTMLInputElement>) => {
    this.createValue = e.target.value
  }

  onChangeUpdateValue = (e: ChangeEvent<HTMLInputElement>) => {
    this.updateValue = e.target.value
  }

  onOpenNoticeModal = (title: string, message: string) => {
    this.loading = false
    this.noticeModal = true
    this.noticeTitle = title
    this.noticeMessage = message
  }
  onCloseNoticeModal = () => {
    this.loading = false
    this.noticeModal = false
    this.noticeTitle = ""
    this.noticeMessage = ""
    if (this.noticeCloseAction !== undefined) {
      this.noticeCloseAction()
      this.noticeCloseAction = undefined
    }
  }

  // 모델 관리 //////////////////////////////////////////////////////////////////////////////////////////
  adminModelList: Array<AdminModel> = []
  adminModelCheckAll: boolean = false
  adminDeleteModelModal: boolean = false

  onChangeAdminModelCheckAll = () => {
    this.adminModelCheckAll = !this.adminModelCheckAll
    const newCheckedAdminModelList = this.adminModelList.map((adminModel) => {
      return { ...adminModel, checked: this.adminModelCheckAll }
    })
    this.adminModelList = newCheckedAdminModelList
  }

  onChangeAdminModelCheck = (index: number) => {
    const newCheckedAdminModelList = [...this.adminModelList]
    newCheckedAdminModelList[index].checked =
      !newCheckedAdminModelList[index].checked
    this.adminModelList = newCheckedAdminModelList
  }

  getAllAdminModelList = async () => {
    this.adminModelList = await ModelService.adminGetAllModel()
  }

  onOpenDeleteModelModal = () => {
    if (this.adminModelList.every((model) => !model.checked)) {
      this.noticeModal = true
      this.noticeTitle = "삭제하기"
      this.noticeMessage =
        "삭제할 모델이 선택되지 않았습니다.\n 삭제할 모델을 선택해주세요."
    } else {
      this.adminDeleteModelModal = true
      this.deleteMessage =
        "해당 콘텐츠를 삭제하시겠습니까\n삭제 후 복구할 수 없습니다."
    }
  }
  onCloseDeleteModelModal = () => {
    this.adminDeleteModelModal = false
    this.deleteMessage = ""
  }

  onSubmitDeleteModels = async () => {
    await ModelService.deleteModels(
      this.adminModelList.filter((model) => model.checked)
    )
    this.onCloseDeleteModelModal()
    this.onOpenNoticeModal("모델 삭제", "선택하신 모델이 삭제되었습니다.")
    this.getAllAdminModelList()
  }

  // 모델 등록 //////////////////////////////////////////////////////////////////////////////////////////
  name: string = ""
  eLastName: string = ""
  eFirstName: string = ""
  birth: string = ""
  height: string = ""
  categoryId: string = ""
  hashTags: Array<string> = []
  mainImageFile: File | undefined = undefined
  mainImageUrl: string = ""
  comCardImageFile: File | undefined = undefined
  comCardImageUrl: string = ""
  videos: Array<ModelVideo> = []
  images: Array<AdminModelImage> = []
  instagramAddress: string = ""
  instagram: Array<ModelInstagramImage> = []

  hashTagList: Array<HashTag> = []
  categoryList: Array<Category> = []

  // 모달
  mainImageUploadModal: boolean = false // 대표이미지 모달
  comCardImageUploadModal: boolean = false // 컴카드이미지 모달
  modelImagesUploadModal: boolean = false // 모델 이미지등록 모달
  modelInstagramImagesUploadModal: boolean = false // 모델 인스타그램 이미지등록 모달
  modelInstagramImagesCropModal: boolean = false
  hashTagSelectModal: boolean = false // 모델 해시태그 선택모달

  cropModalImageFile: File | undefined = undefined
  cropModalImageSrc: string = ""
  instagramImageLink: string = ""

  onDragCropModalFile = (e: ChangeEvent<HTMLInputElement> | any): void => {
    const reader = new FileReader()

    reader.onload = () => {
      const { result } = reader
      this.cropModalImageSrc = result as string
    }

    if (e.dataTransfer.files[0]) {
      this.cropModalImageFile = e.dataTransfer.files[0]
      reader.readAsDataURL(e.dataTransfer.files[0])
    }
  }

  onChangeCropModalFile = (e: ChangeEvent<HTMLInputElement> | any): void => {
    const reader = new FileReader()

    reader.onload = () => {
      const { result } = reader
      this.cropModalImageSrc = result as string
    }

    if (e.target.files[0]) {
      this.cropModalImageFile = e.target.files[0]
      reader.readAsDataURL(e.target.files[0])
    }
  }

  onOpenMainImageUploadModal = () => {
    this.mainImageUploadModal = true
  }
  onCloseMainImageUploadModal = () => {
    this.mainImageUploadModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onOpenComCardImageUploadModal = () => {
    this.comCardImageUploadModal = true
  }
  onCloseComCardImageUploadModal = () => {
    this.comCardImageUploadModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onOpenModelImagesUploadModal = () => {
    this.modelImagesUploadModal = true
  }
  onCloseModelImagesUploadModal = () => {
    this.modelImagesUploadModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onOpenModelInstagramImagesUploadModal = () => {
    this.modelInstagramImagesUploadModal = true
  }
  onCloseModelInstagramImagesUploadModal = () => {
    this.modelInstagramImagesUploadModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
    this.instagramImageLink = ""
  }

  onOpenModelInstagramImagesCropModal = () => {
    this.modelInstagramImagesCropModal = true
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }
  onCloseModelInstagramImagesCropModal = () => {
    this.modelInstagramImagesCropModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onPageInitModelRegister = async () => {
    this.name = ""
    this.eLastName = ""
    this.eFirstName = ""
    this.birth = ""
    this.height = ""
    this.categoryId = ""
    this.hashTags = []
    this.mainImageFile = undefined
    this.mainImageUrl = ""
    this.comCardImageFile = undefined
    this.comCardImageUrl = ""
    this.videos = []
    this.images = []
    this.instagramAddress = ""
    this.instagram = []

    this.categoryList = await CategoryService.getAllCategory()
    this.hashTagList = await HashTagService.getAllHashTag()
  }

  onOpenHashTagSelectModal = () => {
    this.hashTagSelectModal = true
  }
  onCloseHashTagSelectModal = () => {
    this.hashTagSelectModal = false
  }

  onCropSubmitMainImageFile = async (cropImageDataUrl: string) => {
    if (this.cropModalImageFile !== undefined) {
      this.mainImageUrl = cropImageDataUrl
      this.mainImageFile = await UtilService.dataURLtoFile(
        cropImageDataUrl,
        this.cropModalImageFile.name,
        this.cropModalImageFile.type
      )
      this.mainImageUploadModal = false
      this.cropModalImageFile = undefined
      this.cropModalImageSrc = ""
    }
  }

  onCropSubmitComCardImageFile = async (cropImageDataUrl: string) => {
    if (this.cropModalImageFile !== undefined) {
      this.comCardImageUrl = cropImageDataUrl
      this.comCardImageFile = await UtilService.dataURLtoFile(
        cropImageDataUrl,
        this.cropModalImageFile.name,
        this.cropModalImageFile.type
      )
      this.comCardImageUploadModal = false
      this.cropModalImageFile = undefined
      this.cropModalImageSrc = ""
    }
  }

  onCropSubmitModelImageFile = async (cropImageDataUrl: string) => {
    if (this.cropModalImageFile !== undefined) {
      const newImage = {
        url: cropImageDataUrl,
        file: await UtilService.dataURLtoFile(
          cropImageDataUrl,
          this.cropModalImageFile.name,
          this.cropModalImageFile.type
        ),
        showOnly: false,
      }
      this.images = this.images.concat(newImage)
      this.modelImagesUploadModal = false
      this.cropModalImageFile = undefined
      this.cropModalImageSrc = ""
    }
  }

  onCropSubmitModelInstagramImageFile = async (cropImageDataUrl: string) => {
    if (this.cropModalImageFile !== undefined) {
      this.modelInstagramImagesCropModal = false
      this.cropModalImageFile = await UtilService.dataURLtoFile(
        cropImageDataUrl,
        this.cropModalImageFile.name,
        this.cropModalImageFile.type
      )
      this.cropModalImageSrc = cropImageDataUrl

      const newInstagramImage: ModelInstagramImage = {
        url: this.cropModalImageSrc,
        file: this.cropModalImageFile,
        link: this.instagramImageLink,
      }
      this.instagram = this.instagram.concat(newInstagramImage)
      this.cropModalImageFile = undefined
      this.instagramImageLink = ""
      this.cropModalImageSrc = ""
    }
  }

  onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    this.name = e.target.value
  }
  onChangeELastName = (e: ChangeEvent<HTMLInputElement>) => {
    this.eLastName = e.target.value
  }
  onChangeEFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    this.eFirstName = e.target.value
  }
  onChangeBirth = (e: ChangeEvent<HTMLInputElement>) => {
    const replaceOption = /[^0-9]/gi
    this.birth = e.target.value.replace(replaceOption, "").slice(0, 4)
  }
  onChangeHeight = (e: ChangeEvent<HTMLInputElement>) => {
    const replaceOption = /[^0-9]/gi
    this.height = e.target.value.replace(replaceOption, "").slice(0, 3)
  }
  onChangeCategoryId = (e: ChangeEvent<HTMLSelectElement>) => {
    this.categoryId = e.target.value
  }
  onChangeHashtagId = (newHashtagValue: Array<string>) => {
    this.hashTags = newHashtagValue
  }
  onChangeImageShowOnly = (index: number) => {
    const newImages = [...this.images]
    newImages[index].showOnly = !this.images[index].showOnly
    this.images = newImages
  }
  onChangeInstagramAddress = (e: ChangeEvent<HTMLInputElement>) => {
    this.instagramAddress = e.target.value
  }
  onChangeInstagramImageLink = (e: ChangeEvent<HTMLInputElement>) => {
    this.instagramImageLink = e.target.value
  }
  onSubmitInstagramImage = () => {
    if (this.cropModalImageFile !== undefined) {
      this.modelInstagramImagesUploadModal = false

      const newInstagramImage: ModelInstagramImage = {
        url: this.cropModalImageSrc,
        file: this.cropModalImageFile,
        link: this.instagramImageLink,
      }
      this.instagram = this.instagram.concat(newInstagramImage)
      this.cropModalImageFile = undefined
      this.instagramImageLink = ""
      this.cropModalImageSrc = ""
    }
  }
  onUploadVideo = (e: ChangeEvent<HTMLInputElement> | any): void => {
    if (e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].type === "video/mp4") {
          const newFile = new File(
            [e.target.files[i]],
            `${new Date().getTime()}_${e.target.files[i].name}`,
            {
              type: e.target.files[i].type,
            }
          )
          const newVideo = {
            file: newFile,
            url: e.target.files[i].name,
          }
          this.videos = [...this.videos, newVideo]
        }
      }
    }
  }
  onDeleteComCardImage = () => {
    this.comCardImageFile = undefined
    this.comCardImageUrl = ""
  }
  onDeleteVideo = (index: number) => {
    this.videos = [...this.videos].filter((video, idx) => idx !== index)
  }
  onDeleteImage = (index: number) => {
    this.images = [...this.images].filter((image, idx) => idx !== index)
  }
  onDeleteInstagramImage = (index: number) => {
    this.instagram = [...this.instagram].filter((image, idx) => idx !== index)
  }

  onDragEndModelRegisterVideo = (result: any) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const newVideos = Array.from(this.videos)
    const [removed] = newVideos.splice(source.index, 1)
    newVideos.splice(destination.index, 0, removed)

    this.videos = newVideos
  }

  onDragEndModelRegistImage = (result: any) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const newImages = Array.from(this.images)
    const [removed] = newImages.splice(source.index, 1)
    newImages.splice(destination.index, 0, removed)

    this.images = newImages
  }

  onDragEndModelRegistInstagramImage = (result: any) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const newInstagramImages = Array.from(this.instagram)
    const [removed] = newInstagramImages.splice(source.index, 1)
    newInstagramImages.splice(destination.index, 0, removed)

    this.instagram = newInstagramImages
  }

  onSubmitModelRegist = async () => {
    if (this.mainImageFile === undefined) {
      this.onOpenNoticeModal("필수값 입력", "대표 이미지를 등록해주세요.")
    } else if (this.name === "") {
      this.onOpenNoticeModal("필수값 입력", "이름을 입력해주세요.")
    } else if (this.eLastName === "") {
      this.onOpenNoticeModal("필수값 입력", "성(영문)을 입력해주세요.")
    } else if (this.eFirstName === "") {
      this.onOpenNoticeModal("필수값 입력", "이름(영문)을 입력해주세요.")
    } else if (this.birth === "") {
      this.onOpenNoticeModal("필수값 입력", "Birth를 입력해주세요.")
    } else if (this.height === "") {
      this.onOpenNoticeModal("필수값 입력", "Height (cm)를 입력해주세요.")
    } else if (this.categoryId === "") {
      this.onOpenNoticeModal("필수값 입력", "카테고리를 선택해주세요.")
    } else {
      const dto: CreateModelDTO = {
        name: this.name,
        eLastName: this.eLastName,
        eFirstName: this.eFirstName,
        birth: this.birth,
        height: Number(this.height),
        categoryId: this.categoryId,
        hashTags: this.hashTags,
        mainImageFile: this.mainImageFile,
        comCardImageFile: this.comCardImageFile,
        videos: this.videos.map((video) => video.file),
        images: this.images,
        instagramAddress: this.instagramAddress,
        instagram: this.instagram.map((instaImage) => instaImage.file),
      }
      this.loading = true
      await ModelService.craeteModel(dto)
      await this.onPageInitModelRegister()
      this.onOpenNoticeModal("모델 등록", "모델등록이 완료되었습니다.")
    }
  }
  // 모델 상세 //////////////////////////////////////////////////////////////////////////////////////////
  loadModel: Model | undefined = undefined

  onPageInitModelDetail = async (
    modelId: string | undefined,
    onBackPage: () => void
  ) => {
    if (modelId === undefined) {
      this.onOpenNoticeModal("유효하지 않은 접근", "유효하지 않은 접근입니다.")
      onBackPage()
    } else {
      const loadModel: Model | undefined = await ModelService.getModelById(
        modelId
      )
      if (loadModel !== undefined) {
        this.loadModel = loadModel
        this.name = loadModel.name
        this.eFirstName = loadModel.eFirstName
        this.eLastName = loadModel.eLastName
        this.birth = loadModel.birth
        this.height = loadModel.height.toString()
        this.categoryId = loadModel.categoryId
        this.hashTags = loadModel.hashTags
        this.mainImageFile = new File([], "existFile")
        this.comCardImageFile =
          loadModel.comCardImageUrl === ""
            ? undefined
            : new File([], "existFile")
        this.mainImageUrl = loadModel.mainImageUrl
        this.comCardImageUrl = loadModel.comCardImageUrl
        this.videos = loadModel.videos.map((videoUrl) => ({
          file: new File([], "existFile"),
          url: videoUrl,
        }))
        this.images = loadModel.images.map((image) => ({
          file: new File([], "existFile"),
          url: image.url,
          showOnly: image.showOnly,
        }))
        this.instagramAddress = loadModel.instagramAddress
        this.instagram = loadModel.instagram.map((instagramImageUrl) => ({
          file: new File([], "existFile"),
          url: instagramImageUrl,
          link: "",
        }))

        this.categoryList = await CategoryService.getAllCategory()
        this.hashTagList = await HashTagService.getAllHashTag()
      } else {
        this.onOpenNoticeModal(
          "유효하지 않은 접근",
          "유효하지 않은 접근입니다."
        )
        onBackPage()
      }
    }
  }

  onSubmitUpdateModel = async (onBackPage: () => void) => {
    if (this.mainImageFile === undefined) {
      this.onOpenNoticeModal("필수값 입력", "대표 이미지를 등록해주세요.")
    } else if (this.name === "") {
      this.onOpenNoticeModal("필수값 입력", "이름을 입력해주세요.")
    } else if (this.eLastName === "") {
      this.onOpenNoticeModal("필수값 입력", "성(영문)을 입력해주세요.")
    } else if (this.eFirstName === "") {
      this.onOpenNoticeModal("필수값 입력", "이름(영문)을 입력해주세요.")
    } else if (this.birth === "") {
      this.onOpenNoticeModal("필수값 입력", "Birth를 입력해주세요.")
    } else if (this.height === "") {
      this.onOpenNoticeModal("필수값 입력", "Height (cm)를 입력해주세요.")
    } else if (this.categoryId === "") {
      this.onOpenNoticeModal("필수값 입력", "카테고리를 선택해주세요.")
    } else {
      if (this.loadModel !== undefined) {
        const dto: UpdateModelDTO = {
          id: this.loadModel.id,
          name: this.name,
          eLastName: this.eLastName,
          eFirstName: this.eFirstName,
          birth: this.birth,
          height: Number(this.height),
          categoryId: this.categoryId,
          hashTags: this.hashTags,
          mainImageFile: this.mainImageFile,
          mainImageUrl: this.mainImageUrl,
          comCardImageFile: this.comCardImageFile,
          comCardImageUrl: this.comCardImageUrl,
          videos: this.videos,
          images: this.images,
          instagramAddress: this.instagramAddress,
          instagram: this.instagram,
          oldCategoryId: this.loadModel.categoryId,
        }
        this.loading = true
        await ModelService.updateModel(dto)
        this.onOpenNoticeModal("모델 수정", "모델수정이 완료되었습니다.")
        await this.onPageInitModelDetail(this.loadModel.id, onBackPage)
      }
    }
  }

  // 카테고리 관리 //////////////////////////////////////////////////////////////////////////////////////////
  categoryManageList: Array<AdminCategory> = []
  categoryCheckAll: boolean = false
  onChangeAdminCategoryCheckAll = () => {
    this.categoryCheckAll = !this.categoryCheckAll
    const newCheckedCategoryList = this.categoryManageList.map(
      (adminCategory) => {
        return { ...adminCategory, checkYn: this.categoryCheckAll }
      }
    )
    this.categoryManageList = newCheckedCategoryList
  }
  onChangeAdminCategoryCheck = (index: number) => {
    const newCheckedCategoryList = [...this.categoryManageList]
    newCheckedCategoryList[index].checkYn =
      !newCheckedCategoryList[index].checkYn
    this.categoryManageList = newCheckedCategoryList
  }
  onChangeShowYn = async (category: AdminCategory, index: number) => {
    const newShowYn = !this.categoryManageList[index].showYn
    this.categoryManageList[index].showYn = newShowYn
    const dto = { name: category.name, showYn: newShowYn }
    await CategoryService.updateCategory(category.id, dto)
  }
  onPageInitCategoryManage = async () => {
    const loadModelList = await ModelService.getAllModels()
    const loadCategoryList = await CategoryService.getAllCategory()
    this.categoryManageList = loadCategoryList.map((category) => {
      const modelCount = loadModelList.filter(
        (model) => model.categoryId === category.id
      ).length
      return {
        ...category,
        checkYn: false,
        modelCount: modelCount,
      }
    })
    this.categoryCheckAll = false
  }

  onOpenCategoryCreateModal = () => {
    this.craeteModal = true
    this.createValue = ""
  }
  onCloseCategoryCreateModal = () => {
    this.craeteModal = false
    this.createValue = ""
  }
  onOpenCategoryUpdateModal = (thisValue: string, index: number) => {
    this.updateModal = true
    this.updateValue = thisValue
    this.resourceIndex = index
  }
  onCloseCategoryUpdateModal = () => {
    this.updateModal = false
    this.updateValue = ""
    this.resourceIndex = -1
  }
  onOpenCategoryDeleteModal = (deleteMessage: string) => {
    if (this.categoryManageList.every((category) => !category.checkYn)) {
      this.noticeModal = true
      this.noticeTitle = "삭제하기"
      this.noticeMessage =
        "삭제할 카테고리가 선택되지 않았습니다.\n 삭제할 카테고리를 선택해주세요."
    } else {
      this.deleteModal = true
      this.deleteMessage =
        "해당 카테고리를 삭제하시겠습니까?\n 삭제 후 복구할 수 없습니다.\n 삭제 전 해당 카테고리에 있는 모델이 없는지 확인하시기 바랍니다."
    }
  }
  onCloseCategoryDeleteModal = () => {
    this.deleteModal = false
    this.deleteMessage = ""
  }
  onSubmitCreateCategory = async () => {
    if (
      this.categoryManageList.some(
        (category) => category.name === this.createValue
      )
    ) {
      this.craeteModal = false
      this.noticeCloseAction = () => {
        this.craeteModal = true
      }
      this.onOpenNoticeModal(
        "중복입력",
        "해당입력값과 중복되는 데이터가 존재합니다.\n확인하시고 다시 입력해주세요."
      )
    } else {
      const dto = {
        name: this.createValue,
        index: this.categoryManageList.length,
        showYn: false,
      }
      await CategoryService.createCategory(dto)
      await this.onPageInitCategoryManage()
      this.craeteModal = false
    }
  }
  onSubmitUpdateCategory = async () => {
    if (
      this.categoryManageList.some(
        (category) => category.name === this.updateValue
      )
    ) {
      this.updateModal = false
      this.noticeCloseAction = () => {
        this.updateModal = true
      }
      this.onOpenNoticeModal(
        "중복입력",
        "해당입력값과 중복되는 데이터가 존재합니다.\n확인하시고 다시 입력해주세요."
      )
    } else {
      const dto = {
        name: this.updateValue,
        showYn: this.categoryManageList[this.resourceIndex].showYn,
      }
      CategoryService.updateCategory(
        this.categoryManageList[this.resourceIndex].id,
        dto
      ).then((res) => {
        this.onPageInitCategoryManage()
        this.updateModal = false
        this.resourceIndex = -1
      })
    }
  }
  onSubmitDeleteCategory = async () => {
    const docIdList = [...this.categoryManageList]
      .filter((category) => category.checkYn)
      .map((category) => category.id)

    CategoryService.deleteCategoryList(docIdList).then((res) => {
      this.onPageInitCategoryManage()
      this.deleteModal = false
    })
    await this.onPageInitCategoryManage()
  }

  // 해시태그 관리 //////////////////////////////////////////////////////////////////////////////////////////
  hashTagManageList: Array<AdminHashTag> = []
  hashTagCheckAll: boolean = false
  hashTagActiveAll: boolean = false

  onChangeAdminHashTagCheckAll = () => {
    this.hashTagCheckAll = !this.hashTagCheckAll
    const newCheckedHashTagList = this.hashTagManageList.map((adminHashTag) => {
      return { ...adminHashTag, checkYn: this.hashTagCheckAll }
    })
    this.hashTagManageList = newCheckedHashTagList
  }
  onChangeAdminHashTagCheck = (index: number) => {
    const newCheckedHashTagList = [...this.hashTagManageList]
    newCheckedHashTagList[index].checkYn = !newCheckedHashTagList[index].checkYn
    this.hashTagManageList = newCheckedHashTagList
  }
  onChangeActive = async (hashTag: AdminHashTag, index: number) => {
    const result = !this.hashTagManageList[index].active
    this.hashTagManageList[index].active = result
    const dto = { tagName: hashTag.tagName, active: result }
    HashTagService.updateHashTag(hashTag.id, dto)
  }
  // TODO
  onChangeActiveAll = async () => {
    this.hashTagActiveAll = !this.hashTagActiveAll
    await GlobalService.updateGlobal({
      hashtagState: this.hashTagActiveAll,
      updatedAt: "",
    })
    await this.onPageInitHashTagManage()
  }
  onPageInitHashTagManage = async () => {
    const loadModelList = await ModelService.getAllModels()
    const loadHashTagList = await HashTagService.getAllHashTag()
    const globalData = await GlobalService.getGlobal()

    if (globalData) {
      this.hashTagActiveAll = globalData.hashtagState
    }

    this.hashTagManageList = loadHashTagList.map((hashTag) => {
      const modelCount = loadModelList.filter((model) =>
        model.hashTags.includes(hashTag.id)
      ).length
      return {
        ...hashTag,
        checkYn: false,
        modelCount: modelCount,
      }
    })
  }

  onOpenHashTagCreateModal = () => {
    this.craeteModal = true
    this.createValue = ""
  }
  onCloseHashTagCreateModal = () => {
    this.craeteModal = false
    this.createValue = ""
  }
  onOpenHashTagUpdateModal = (thisValue: string, index: number) => {
    this.updateModal = true
    this.updateValue = thisValue
    this.resourceIndex = index
  }
  onCloseHashTagUpdateModal = () => {
    this.updateModal = false
    this.updateValue = ""
    this.resourceIndex = -1
  }
  onOpenHashTagDeleteModal = (deleteMessage: string) => {
    if (this.hashTagManageList.every((hashTag) => !hashTag.checkYn)) {
      this.noticeModal = true
      this.noticeTitle = "삭제하기"
      this.noticeMessage =
        "삭제할 해시태그가 선택되지 않았습니다.\n 삭제할 해시태그를 선택해주세요."
    } else {
      this.deleteModal = true
      this.deleteMessage =
        "해당 해시태그를 삭제하시겠습니까?\n 삭제 후 복구할 수 없습니다.\n 삭제 전 해당 해시태그에 있는 모델이 없는지 확인하시기 바랍니다."
    }
  }
  onCloseHashTagDeleteModal = () => {
    this.deleteModal = false
    this.deleteMessage = ""
  }
  onSubmitCreateHashTag = async () => {
    if (
      this.hashTagManageList.some((has) => has.tagName === this.createValue)
    ) {
      this.craeteModal = false
      this.noticeCloseAction = () => {
        this.craeteModal = true
      }
      this.onOpenNoticeModal(
        "중복입력",
        "해당입력값과 중복되는 데이터가 존재합니다.\n확인하시고 다시 입력해주세요."
      )
    } else {
      const dto = {
        tagName: this.createValue,
        active: true,
      }
      HashTagService.createHashTag(dto).then((res) => {
        this.onPageInitHashTagManage()
        this.craeteModal = false
      })
    }
  }
  onSubmitUpdateHashTag = async () => {
    if (
      this.hashTagManageList.some((has) => has.tagName === this.updateValue)
    ) {
      this.updateModal = false
      this.noticeCloseAction = () => {
        this.updateModal = true
      }
      this.onOpenNoticeModal(
        "중복입력",
        "해당입력값과 중복되는 데이터가 존재합니다.\n확인하시고 다시 입력해주세요."
      )
    } else {
      const dto = {
        tagName: this.updateValue,
        active: this.hashTagManageList[this.resourceIndex].active,
      }
      HashTagService.updateHashTag(
        this.hashTagManageList[this.resourceIndex].id,
        dto
      ).then((res) => {
        this.onPageInitHashTagManage()
        this.updateModal = false
        this.resourceIndex = -1
      })
    }
  }
  onSubmitDeleteHashTag = async () => {
    const docIdList = [...this.hashTagManageList]
      .filter((category) => category.checkYn)
      .map((category) => category.id)

    HashTagService.deleteHashTagList(docIdList).then((res) => {
      this.onPageInitHashTagManage()
      this.deleteModal = false
    })
  }

  // 레이아웃 관리 //////////////////////////////////////////////////////////////////////////////////////////
  currentCategoryId: String = ""
  adminLayout: Layout | undefined = undefined
  layoutCateogoryList: Array<Category> = []
  categoryModelList: Array<{
    categoryId: string
    categoryModels: Array<Model>
  }> = []

  onPageInitLayoutManage = async () => {
    this.adminLayout = await LayoutService.getLayout()
    if (
      this.adminLayout !== undefined &&
      this.adminLayout.categoryGroups !== undefined
    ) {
      this.adminLayout.categoryGroups = toJS(this.adminLayout?.categoryGroups)
    }

    this.layoutCateogoryList = await CategoryService.getAllCategory()
    if (this.adminLayout !== undefined) {
      this.onClickCategory(this.adminLayout.categoryGroups[0].categoryId)
    }
  }

  onClickCategory = (categoryId: string) => {
    this.currentCategoryId = categoryId
  }

  onDragEndModelCategoryModel = (result: any) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (this.adminLayout === undefined) {
      return
    } else {
      const newAdminLayout: Layout = { ...this.adminLayout }
      if (newAdminLayout === undefined) {
        return
      } else {
        const targetGroups = newAdminLayout.categoryGroups
        if (targetGroups === undefined) {
          return
        } else {
          const targetGroup = targetGroups.find(
            (group) => group.categoryId === this.currentCategoryId
          )
          const targetGroupIndex = targetGroups.findIndex(
            (group) => group.categoryId === this.currentCategoryId
          )
          if (targetGroup === undefined) {
            return
          } else {
            const targetModels = targetGroup.models
            if (targetModels === undefined) {
              return
            } else {
              const newModels = [...targetModels].filter(
                (model, idx) => idx !== source.index
              )
              const removed = targetModels[source.index]
              const resultarray = [...newModels]
              const newArray: Array<any> = []
              resultarray.forEach((result, idx) => {
                if (idx === destination.index) {
                  newArray.push(removed)
                }
                newArray.push(result)

                if (idx === resultarray.length - 1) {
                  if (resultarray.length === destination.index) {
                    newArray.push(removed)
                  }
                }
              })
              newAdminLayout.categoryGroups[targetGroupIndex].models = newArray
              this.adminLayout = newAdminLayout
            }
          }
        }
      }
    }
  }

  onSubmitLayout = async () => {
    if (this.adminLayout !== undefined) {
      await LayoutService.updateLayout(this.adminLayout)
    }
  }
  // 업데이트 관리 //////////////////////////////////////////////////////////////////////////////////////////
  updatedList: Array<AdminUpdated> = []
  updatedImageModal: boolean = false
  updatedUpdateModal: boolean = false
  updatedImageCropModal: boolean = false
  updatedUpdateImageCropModal: boolean = false
  updatedTitle: string = ""
  updatedModel: Model | undefined = undefined
  updatedModelList: Array<Model> = []
  updatedCategoryList: Array<Category> = []
  updatedModelNameSearch: string = ""
  updatedDeleteModal: boolean = false
  deleteUpdated: AdminUpdated | undefined = undefined
  updatedUpdate: AdminUpdated | undefined = undefined

  onPageInitUpdated = async () => {
    this.updatedList = await UpdatedService.getAllUpdated()
    this.updatedImageModal = false
    this.updatedImageCropModal = false
    this.updatedTitle = ""
    this.updatedModel = undefined
    this.updatedModelList = []
    this.updatedCategoryList = []
    this.updatedModelNameSearch = ""
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
    this.updatedUpdate = undefined
    this.updatedUpdateModal = false
  }

  onOpenUpdatedImageModal = async () => {
    this.updatedImageModal = true
    this.updatedTitle = ""
    this.updatedModel = undefined
    this.updatedModelList = await ModelService.getAllModels()
    this.updatedCategoryList = await CategoryService.getAllCategory()
    this.updatedModelNameSearch = ""
  }

  onCloseUpdatedImageModal = async () => {
    this.updatedImageModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onOpenUpdatedUpdateImageModal = async (updatedUpdate: AdminUpdated) => {
    this.updatedUpdate = updatedUpdate
    this.updatedUpdateModal = true
    this.updatedTitle = updatedUpdate.title

    this.updatedModelList = await ModelService.getAllModels()
    this.updatedCategoryList = await CategoryService.getAllCategory()

    this.updatedModel = updatedUpdate.model
    this.updatedModelNameSearch = ""
  }

  onCloseUpdatedUpdateImageModal = async () => {
    this.updatedUpdate = undefined
    this.updatedUpdateModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onOpenUpdatedCropModal = async () => {
    this.updatedImageCropModal = true
    this.updatedImageModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onCloseUpdatedCropModal = async () => {
    this.updatedImageCropModal = false
    this.updatedImageModal = true
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onOpenUpdatedUpdateCropModal = async () => {
    this.updatedUpdateImageCropModal = true
    this.updatedUpdateModal = false
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onCloseUpdatedUpdateCropModal = async () => {
    this.updatedUpdateImageCropModal = false
    this.updatedUpdateModal = true
    this.cropModalImageFile = undefined
    this.cropModalImageSrc = ""
  }

  onCropSubmitUpdated = async (cropImageDataUrl: string) => {
    if (this.cropModalImageFile !== undefined) {
      this.updatedImageCropModal = false
      this.updatedImageModal = true
      this.cropModalImageFile = await UtilService.dataURLtoFile(
        cropImageDataUrl,
        this.cropModalImageFile.name,
        this.cropModalImageFile.type
      )
      this.cropModalImageSrc = cropImageDataUrl
    }
  }

  onCropSubmitUpdatedUpdate = async (cropImageDataUrl: string) => {
    if (this.cropModalImageFile !== undefined) {
      this.updatedUpdateImageCropModal = false
      this.updatedUpdateModal = true
      this.cropModalImageFile = await UtilService.dataURLtoFile(
        cropImageDataUrl,
        this.cropModalImageFile.name,
        this.cropModalImageFile.type
      )
      this.cropModalImageSrc = cropImageDataUrl
    }
  }

  onSubmitUpdatedModal = async () => {
    if (this.cropModalImageFile !== undefined) {
      await UpdatedService.createUpdated(
        this.cropModalImageFile,
        this.updatedList.length,
        this.updatedTitle,
        this.updatedModel
      )
      this.onPageInitUpdated()
    }
  }

  onSubmitUpdatedUpdateModal = async () => {
    console.log("update")
    if (this.updatedUpdate) {
      await UpdatedService.updateUpdated(
        this.updatedUpdate,
        this.cropModalImageFile,
        this.updatedTitle,
        this.updatedModel
      )
      this.onPageInitUpdated()
    }
  }

  onDeleteUpdated = async () => {
    if (this.deleteUpdated !== undefined) {
      await UpdatedService.deleteUpdated(this.deleteUpdated, this.updatedList)
      this.updatedDeleteModal = false
      this.deleteMessage = ""
      this.deleteUpdated = undefined
      this.onPageInitUpdated()
    }
  }

  onOpenUpdatedDeleteModal = (deleteUpdated: AdminUpdated) => {
    this.updatedDeleteModal = true
    this.deleteMessage =
      "해당 콘텐츠를 삭제하시겠습니까\n삭제 후 복구할 수 없습니다."
    this.deleteUpdated = deleteUpdated
  }

  onCloseUpdatedDeleteModal = () => {
    this.updatedDeleteModal = false
    this.deleteMessage = ""
    this.deleteUpdated = undefined
  }

  onDragEndUpdated = (result: any) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const newUpdatedList = Array.from(this.updatedList)
    const [removed] = newUpdatedList.splice(source.index, 1)
    newUpdatedList.splice(destination.index, 0, removed)

    this.updatedList = newUpdatedList
  }

  onSubmitIndexUpdated = async () => {
    await UpdatedService.updateAllIndex(this.updatedList)
    this.onPageInitUpdated()
    this.onOpenNoticeModal("업데이트 저장", "업데이트 내용이 저장되었습니다.")
  }

  onChangeUpdatedTitle = (e: ChangeEvent<HTMLInputElement>) =>
    (this.updatedTitle = e.target.value)

  onChangeUpdatedModel = (model: Model) => {
    if (this.updatedModel === undefined) {
      this.updatedModel = model
    } else {
      if (this.updatedModel.id === model.id) {
        this.updatedModel = undefined
      } else {
        this.updatedModel = model
      }
    }
  }
  onChangeUpdatedModelNameSearch = (e: ChangeEvent<HTMLInputElement>) =>
    (this.updatedModelNameSearch = e.target.value)

  // 개인 정보 처리 방침 관리 //////////////////////////////////////////////////////////////////////////////////////////
  agreement: Agreement = {
    id: "",
    content: "",
    createdAt: "",
  }
  lastCreated: string = ""
  histories: AgreementHistory[] = []
  selectedHistoryIndex: number = -1
  agreementHistoryModal: boolean = false

  selectHistoryItem = (index: number) => (this.selectedHistoryIndex = index)

  openAgreementHistoryModal = async () => {
    await this.getAgreementHistories
    this.agreementHistoryModal = true
  }

  getAgreementHistoryFromModal = async () => {
    runInAction(() => {
      this.agreement = this.histories[this.selectedHistoryIndex]
      this.agreementHistoryModal = false
    })
  }

  closeAgreementHistoryModal = () => (this.agreementHistoryModal = false)

  getAgreementHistories = async () =>
    (this.histories = await AgreementService.getAgreementHistory())

  getAgreementData = async () => {
    this.agreement = await AgreementService.getAgreement()
    this.lastCreated = this.agreement.createdAt
  }

  saveAgreementData = async () => {
    await AgreementService.updateAgreement(this.agreement)
    this.onOpenNoticeModal("알림", "저장되었습니다.")
    this.getAgreementData()
    this.getAgreementHistories()
  }
  onChangeAgreementContent = (e: ChangeEvent<HTMLTextAreaElement>) =>
    (this.agreement.content = e.target.value)

  // 회사 정보 관리 //////////////////////////////////////////////////////////////////////////////////////////
  companyInfo: CompanyInfo = {
    id: "",
    kAddress: "",
    kTel: "",
    kEmail: "",
    cTel: "",
    cEmail: "",
    cAddress: "",
    updatedAt: "",
  }

  initializeCompanyInfo = () =>
    runInAction(() => {
      this.companyInfo = {
        id: "",
        kAddress: "",
        kTel: "",
        kEmail: "",
        cTel: "",
        cEmail: "",
        cAddress: "",
        updatedAt: "",
      }
    })

  getCompanyInfoData = async () =>
    (this.companyInfo = await CompanyInfoService.getCompanyInfo())
  onChangeCompanyInfoId = (e: ChangeEvent<HTMLInputElement>) =>
    (this.companyInfo.id = e.target.value)
  onChangeCompanyInfoKAddress = (e: ChangeEvent<HTMLInputElement>) =>
    (this.companyInfo.kAddress = e.target.value)
  onChangeCompanyInfoKTel = (e: ChangeEvent<HTMLInputElement>) =>
    (this.companyInfo.kTel = e.target.value)
  onChangeCompanyInfoKEmail = (e: ChangeEvent<HTMLInputElement>) =>
    (this.companyInfo.kEmail = e.target.value)
  onChangeCompanyInfoCTel = (e: ChangeEvent<HTMLInputElement>) =>
    (this.companyInfo.cTel = e.target.value)
  onChangeCompanyInfoCEmail = (e: ChangeEvent<HTMLInputElement>) =>
    (this.companyInfo.cEmail = e.target.value)
  onChangeCompanyInfoCAddress = (e: ChangeEvent<HTMLInputElement>) =>
    (this.companyInfo.cAddress = e.target.value)

  onSaveCompanyInfo = async () => {
    await CompanyInfoService.updateCompanyInfo(this.companyInfo)
    this.companyInfo = await CompanyInfoService.getCompanyInfo()
    this.onOpenNoticeModal("알림", "저장되었습니다.")
  }
}
