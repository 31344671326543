import styled from "styled-components";
import React from "react";


interface MenuButtonProps {
    label: string
    onClick?: () => void
}

const S = {
    Container: styled.div`
      max-width: 600px;
      width: 90vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid white;
      padding-bottom: 12px;
      padding-right: 8px;
      cursor: pointer;
    `,
    Text: styled.div`
      font-family: Jura;
      font-weight: 300;
      color: white;
      font-size: 16px;
    `
}


const MenuButton: React.FC<MenuButtonProps> = ({label, onClick}) => {
    return (
        <S.Container onClick={onClick}>
            <S.Text>
                {label}
            </S.Text>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.4763 6.16664L6.00634 1.69664L7.18467 0.518311L13.6663 6.99998L7.18467 13.4816L6.00634 12.3033L10.4763 7.83331H0.333008V6.16664H10.4763Z"
                    fill="white"/>
            </svg>
        </S.Container>
    )
}

export default MenuButton