import styled from "styled-components"
import NavigationMenu from "../menus/NavigationMenu"

const S = {
  Container: styled.div`
    padding-top: 40px;
    min-width: 240px;
    min-height: calc(100% - 40px);
    background-color: white;
  `,
}

const AdminDetailMenus = [
  { name: "카테고리 관리", to: "/admin/detail/category" },
  { name: "해시태그 관리", to: "/admin/detail/hashtag" },
  { name: "레이아웃 관리", to: "/admin/detail/layout" },
]

const AdminModelMenus = [{ name: "모델관리", to: "/admin/model" }]

const AdminManageMenus = [
  { name: "업데이트관리", to: "/admin/manage/update" },
  { name: "개인 정보 처리 방침 관리", to: "/admin/manage/privacy" },
  { name: "회사 정보 관리", to: "/admin/manage/company" },
]

const AdminNavigation = () => {
  return (
    <S.Container>
      <NavigationMenu title={"모델 관리"} detailMenus={AdminModelMenus} />
      <NavigationMenu title={"상세 관리"} detailMenus={AdminDetailMenus} />
      <NavigationMenu title={"운영 관리"} detailMenus={AdminManageMenus} />
    </S.Container>
  )
}

export default AdminNavigation
