const ArrowRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_558_10823)">
        <path
          d="M13.4768 9.16689L9.00683 4.69689L10.1852 3.51855L16.6668 10.0002L10.1852 16.4819L9.00683 15.3036L13.4768 10.8336H3.3335V9.16689H13.4768Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_558_10823">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowRight
