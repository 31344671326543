import {
  collection,
  deleteDoc,
  getDocs,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  writeBatch,
  getDoc,
  query,
  orderBy,
} from "firebase/firestore/lite"
import { db } from "../firebase"
import { HashTag } from "../models/interfaces/HashTag"
import moment from "moment"
import "moment/locale/ko"
import { UpdateHashTagDTO } from "../models/interfaces/dto/UpdateHashTagDTO"
import { CreateHashTagDTO } from "../models/interfaces/dto/CreateHashTagDTO"
import { ModelService } from "./ModelService"

export class HashTagService {
  //모든 해시태그 불러오기
  static getAllHashTag = async () => {
    const ref = collection(db, "hashtag")
    const q = query(ref,
        orderBy("createdAt", "desc"))
    const hashTags = await getDocs(q)
    let hashTagList: HashTag[] = []
    hashTags.forEach((hashTag) => {
      hashTagList.push({
        id: hashTag.id,
        active: hashTag.data()["active"],
        tagName: hashTag.data()["tagName"],
        createdAt: moment(hashTag.data()["createdAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
        updatedAt: moment(hashTag.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      })
    })
    return hashTagList
  }

  //해시테그 생성
  static createHashTag = async (dto: CreateHashTagDTO) => {
    const ref = collection(db, "hashtag")
    await addDoc(ref, {
      active: true,
      tagName: dto.tagName,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    })
  }

  //해시태그 업데이트
  static updateHashTag = async (docId: string, dto: UpdateHashTagDTO) => {
    const ref = doc(db, `hashtag/${docId}`)
    await updateDoc(ref, {
      active: dto.active,
      tagName: dto.tagName,
      updatedAt: serverTimestamp(),
    })
  }

  //해시태그 노출 전체 업데이트
  static updateAllHashTag = async (
    docIdList: Array<string>,
    active: boolean
  ) => {
    const batch = writeBatch(db)
    for (let i = 0; i < docIdList.length; i++) {
      const ref = doc(db, `hashtag/${docIdList[i]}`)
      batch.update(ref, { active: active })
    }
    await batch.commit()
  }

  //해시태그 삭제
  static deleteHashTag = async (docId: string) => {
    const ref = doc(db, `hashtag/${docId}`)
    await deleteDoc(ref)
  }

  //해시태그 삭제 다중
  static deleteHashTagList = async (docIdList: Array<string>) => {
    const batch = writeBatch(db)
    const modelList = await ModelService.getAllModels()

    for (let i = 0; i < modelList.length; i++) {
      const modelRef = doc(db, `model/${modelList[i].id}`)
      const model = await getDoc(modelRef)
      if (model.exists()) {
        const newHashTags = model
          .data()
          ["hashTags"].filter((hashTag: string) => !docIdList.includes(hashTag))
        await updateDoc(modelRef, {
          hashTags: newHashTags,
          updatedAt: serverTimestamp(),
        })
      }
    }

    docIdList.forEach((docId) => {
      const ref = doc(db, `hashtag/${docId}`)
      batch.delete(ref)
    })

    await batch.commit()
  }
}
