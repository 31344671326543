import React from 'react'
import styled from "styled-components";
import Spacer from "../Spacer";
import Search from "../icons/Search";

interface SearchInputProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>
    value: string
}

const S = {
    Container: styled.div`
      width: 100%;
      border-bottom: 2px solid white;
      display: flex;
      flex-direction: row;
      padding-top: 12px;
      padding-bottom: 12px;
      color: white;
      align-items: center;
    `,
    Input: styled.input`
      flex: 1;
      background-color: transparent;
      color: white;
      font-size: 20px;
      outline: none;
      border: none;
      
      &::placeholder{
        color: white;
        font-weight: 100;
        font-family: Jura;
      }
    `,

}


const SearchInput: React.FC<SearchInputProps> = ({onChange, value}) => {
    return (

        <S.Container>
            <Search/>
            <Spacer width={12}/>
            <S.Input onChange={onChange} placeholder={"Search"} value={value}/>
        </S.Container>
    )
}
export default SearchInput