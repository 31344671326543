import React from "react"
import styled from "styled-components"

interface AdminTableCheckboxProps {
  checked: boolean
  onClick?: () => void
}

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
const S = {
  CheckBoxContainer: styled.div`
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  `,
  StyledCheckBox: styled.div<{ checked: boolean }>`
    display: inline-block;
    width: 20px;
    height: 20px;
    border: ${(props) =>
      props.checked ? "solid 0.1rem #226BEF" : "solid 0.1rem #dddddd"};
    background: ${(props) => (props.checked ? "#226BEF" : "white")};
    border-radius: 4px;
    transition: all 150ms;

    ${Icon} {
      visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    }
  `,
  HiddenCheckBox: styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `,
}

const AdminTableCheckbox: React.FC<AdminTableCheckboxProps> = ({
  checked,
  onClick,
}) => {
  return (
    <S.CheckBoxContainer onClick={onClick}>
      <S.HiddenCheckBox
        type={"checkbox"}
        checked={checked}
        onChange={() => {}}
      />
      <S.StyledCheckBox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="19 7 10 17 5 12" />
        </Icon>
      </S.StyledCheckBox>
    </S.CheckBoxContainer>
  )
}

export default AdminTableCheckbox
