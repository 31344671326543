import { observer } from "mobx-react"
import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import uuid from "react-uuid"
import styled from "styled-components"
import AdminAddButton from "../../../components/buttons/AdminAddButton"
import AdminDeleteButton from "../../../components/buttons/AdminDeleteButton"
import AdminSearchInput from "../../../components/inputs/AdminSearchInput"
import AdminTableCheckbox from "../../../components/inputs/AdminTableCheckbox"
import AdminResourceDeleteModal from "../../../components/modals/AdminResourceDeleteModal"
import { StoreContext } from "../../../store/store.context"

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 36px;
    width: 100%;
    background-color: #f7f9fb;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    width: 100%;
    height: 48px;
    padding-top: 36px;
  `,
  ControllBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
  `,
  ButtonBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  `,
  Title: styled.text`
    display: flex;
    width: auto;
    height: 36px;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f4a;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
  `,
  TableHedaer: styled.thead`
    padding: 0px;
    height: 20px;
    border-radius: 8px;
    flex: none;
  `,
  TableBody: styled.tbody``,
  TableHeaderRow: styled.tr`
    th:first-child {
      width: 73px;
    }
    th:nth-child(2) {
      width: 108px;
    }
    th:last-child {
      width: 95px;
      border: none;
    }
  `,
  TableBodyRow: styled.tr`
    background-color: white;

    td:first-child {
      border-radius: 8px 0 0 8px;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  `,
  TableHeaderCol: styled.th`
    box-sizing: border-box;
    align-items: center;
    padding: 0px 20px;
    width: auto;
    height: 20px;
    border-right: 1px solid #e4e6ea;
    font-size: 12px;
    vertical-align: middle;
  `,
  TableBodyCol: styled.td`
    box-sizing: border-box;
    align-items: center;
    padding: 8px;
    width: auto;
    height: 142px;
    align-items: center;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
  `,

  RowImage: styled.img`
    height: 100%;
  `,
  Link: styled.div`
    font-size: 10px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #5a5e6a;
    cursor: pointer;
  `,
}

const AdminModel = () => {
  const { adminStore } = useContext(StoreContext)
  const {
    adminModelList,
    adminModelCheckAll,
    adminDeleteModelModal,
    deleteMessage,
    onCloseDeleteModelModal,
    onChangeAdminModelCheckAll,
    onChangeAdminModelCheck,
    onOpenDeleteModelModal,
    onSubmitDeleteModels,
  } = adminStore

  const navigate = useNavigate()
  const onChangeSearchInput = (e: React.ChangeEvent<any>) => {
    setSearchKeyword(e.target.value)
  }

  const onFilterModel = (model: any) => {
    if (searchKeyword !== "") {
      if (model.name.indexOf(searchKeyword) !== -1) {
        return true
      } else return false
    } else {
      return true
    }
  }

  useEffect(() => {
    adminStore.getAllAdminModelList()
  }, [])

  const onClickRegister = () => {
    navigate("register")
  }

  const onClickDetail = (id: string) => {
    navigate(`${id}`)
  }

  const [searchKeyword, setSearchKeyword] = React.useState("")
  return (
    <>
      {/* 모델 삭제모달 */}
      <AdminResourceDeleteModal
        isOpen={adminDeleteModelModal}
        resourceName="카테고리"
        deleteMessage={deleteMessage}
        onRequestClose={onCloseDeleteModelModal}
        onSubmit={onSubmitDeleteModels}
      />
      <S.Container>
        <S.TitleBox>
          <S.Title>모델 관리</S.Title>
          <S.ControllBox>
            <AdminSearchInput
              value={searchKeyword}
              onChange={onChangeSearchInput}
            />
            <S.ButtonBox>
              <AdminAddButton onClick={onClickRegister} />
              <AdminDeleteButton onClick={onOpenDeleteModelModal} />
            </S.ButtonBox>
          </S.ControllBox>
        </S.TitleBox>
        <S.Table>
          <S.TableHedaer>
            <S.TableHeaderRow>
              <S.TableHeaderCol>
                <AdminTableCheckbox
                  checked={adminModelCheckAll}
                  onClick={() => onChangeAdminModelCheckAll()}
                />
              </S.TableHeaderCol>
              <S.TableHeaderCol>대표 이미지</S.TableHeaderCol>
              <S.TableHeaderCol>이름</S.TableHeaderCol>
              <S.TableHeaderCol>birth</S.TableHeaderCol>
              <S.TableHeaderCol>Height</S.TableHeaderCol>
              <S.TableHeaderCol>카테고리</S.TableHeaderCol>
              <S.TableHeaderCol>해시태그</S.TableHeaderCol>
              <S.TableHeaderCol>상세보기</S.TableHeaderCol>
            </S.TableHeaderRow>
          </S.TableHedaer>
          <S.TableBody>
            {adminModelList.map((model, idx) =>
              onFilterModel(model) ? (
                <S.TableBodyRow key={uuid()}>
                  <S.TableBodyCol>
                    <AdminTableCheckbox
                      checked={model.checked}
                      onClick={() => onChangeAdminModelCheck(idx)}
                    />
                  </S.TableBodyCol>
                  <S.TableBodyCol>
                    <S.RowImage src={model.mainImageUrl} />
                  </S.TableBodyCol>
                  <S.TableBodyCol>{model.name}</S.TableBodyCol>
                  <S.TableBodyCol>{model.birth}</S.TableBodyCol>
                  <S.TableBodyCol>{model.height}</S.TableBodyCol>
                  <S.TableBodyCol>{model.categoryName}</S.TableBodyCol>
                  <S.TableBodyCol>
                    {model.hashTag.map((hash) => `#${hash}`).join(" ")}
                  </S.TableBodyCol>
                  <S.TableBodyCol>
                    <S.Link onClick={(e) => onClickDetail(model.id)}>
                      상세보기
                    </S.Link>
                  </S.TableBodyCol>
                </S.TableBodyRow>
              ) : (
                <></>
              )
            )}
          </S.TableBody>
        </S.Table>
      </S.Container>
    </>
  )
}
export default observer(AdminModel)
