import styled from "styled-components";
import React from "react";

interface MinusButtonProps{
    onClick?:() => void
}

const S = {
    Svg: styled.svg`
    cursor: pointer;
    `
}

const MinusButton:React.FC<MinusButtonProps> =({onClick}) => {
    return (
        <S.Svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.79609 11.2H20.1961C20.4083 11.2 20.6118 11.2843 20.7618 11.4343C20.9118 11.5843 20.9961 11.7878 20.9961 12C20.9961 12.2122 20.9118 12.4157 20.7618 12.5657C20.6118 12.7157 20.4083 12.8 20.1961 12.8H3.79609C3.58392 12.8 3.38044 12.7157 3.23041 12.5657C3.08038 12.4157 2.99609 12.2122 2.99609 12C2.99609 11.7878 3.08038 11.5843 3.23041 11.4343C3.38044 11.2843 3.58392 11.2 3.79609 11.2V11.2Z" fill="white"/>
        </S.Svg>

    )
}

export default MinusButton