import React from "react"
import styled from "styled-components"

interface AdminComCardDeleteButtonProps {
  onClick?: () => void
  width?: number
  height?: number
}

const S = {
  Container: styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;

    width: 22px;
    height: 22px;

    background: #ffffff;
    /* Gray Scale/Gray3 */

    border: 1px solid #e4e6ea;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

    :hover {
      background: #e4e6ea;
    }

    :active {
      background: #b4b6ba;
    }
  `,
}

const AdminComCardDeleteButton: React.FC<AdminComCardDeleteButtonProps> = ({
  onClick,
  width = 12,
  height = 12,
}) => {
  return (
    <S.Container onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.1554 21.8498H7.84437C7.14337 21.8519 6.47016 21.5759 5.97242 21.0823C5.47468 20.5887 5.19307 19.9178 5.18936 19.2168V7.31081L5.10136 7.27081C4.81997 7.1449 4.58077 6.94065 4.41232 6.68246C4.24386 6.42428 4.15327 6.12308 4.15137 5.8148V4.78381C4.15428 4.35759 4.32597 3.94988 4.62886 3.64999C4.93175 3.35011 5.34113 3.18247 5.76736 3.18381H9.36736L9.38937 3.06081C9.43075 2.80946 9.55941 2.58074 9.75272 2.41486C9.94604 2.24897 10.1916 2.15654 10.4464 2.15381H13.5634C13.8182 2.1565 14.0639 2.24889 14.2574 2.41476C14.4509 2.58063 14.5798 2.80937 14.6214 3.06081L14.6434 3.18381H18.2434C18.6697 3.18247 19.0792 3.35007 19.3822 3.64993C19.6853 3.94979 19.8572 4.3575 19.8604 4.78381V5.8148C19.8583 6.12305 19.7677 6.42421 19.5993 6.68237C19.4308 6.94053 19.1917 7.1448 18.9104 7.27081L18.8224 7.31081V19.2168C18.8204 19.5649 18.7498 19.9092 18.6147 20.2299C18.4795 20.5507 18.2825 20.8417 18.0348 21.0863C17.7871 21.3308 17.4936 21.5241 17.1711 21.6552C16.8486 21.7862 16.5034 21.8523 16.1554 21.8498ZM6.34437 7.4178V19.2178C6.34727 19.6137 6.50658 19.9924 6.78757 20.2714C7.06857 20.5503 7.44844 20.7068 7.84437 20.7068H16.1554C16.5513 20.7068 16.9312 20.5503 17.2122 20.2714C17.4931 19.9924 17.6525 19.6137 17.6554 19.2178V7.4178H6.34437ZM5.76537 4.32481C5.64337 4.32481 5.52636 4.37327 5.44009 4.45953C5.35383 4.5458 5.30537 4.66281 5.30537 4.78481V5.81581C5.3059 5.93746 5.35459 6.05395 5.4408 6.13979C5.52701 6.22562 5.64371 6.2738 5.76537 6.2738H18.2324C18.2928 6.27407 18.3526 6.26244 18.4086 6.23956C18.4645 6.21669 18.5153 6.18302 18.5582 6.14049C18.6011 6.09796 18.6352 6.04739 18.6586 5.99168C18.6819 5.93597 18.6941 5.87622 18.6944 5.81581V4.78481C18.6942 4.72427 18.6822 4.66434 18.6589 4.60846C18.6356 4.55258 18.6015 4.50184 18.5586 4.45912C18.5157 4.41641 18.4648 4.38256 18.4089 4.35951C18.3529 4.33647 18.2929 4.32468 18.2324 4.32481H5.76537Z"
            fill="red"
          />
          <path
            d="M9.93433 17.7318C9.78244 17.7315 9.63685 17.671 9.52945 17.5636C9.42205 17.4562 9.36159 17.3106 9.36133 17.1588V10.9587C9.36133 10.8067 9.42168 10.6608 9.52911 10.5532C9.63654 10.4456 9.78227 10.385 9.93433 10.3848C10.0864 10.385 10.2321 10.4456 10.3396 10.5532C10.447 10.6608 10.5073 10.8067 10.5073 10.9587V17.1588C10.5071 17.3106 10.4466 17.4562 10.3392 17.5636C10.2318 17.671 10.0862 17.7315 9.93433 17.7318Z"
            fill="red"
          />
          <path
            d="M14.0662 17.7318C13.9143 17.7315 13.7687 17.671 13.6613 17.5636C13.5539 17.4562 13.4934 17.3106 13.4932 17.1588V10.9587C13.4932 10.8067 13.5535 10.6608 13.6609 10.5532C13.7683 10.4456 13.9141 10.385 14.0662 10.3848C14.2182 10.385 14.364 10.4456 14.4714 10.5532C14.5788 10.6608 14.6392 10.8067 14.6392 10.9587V17.1588C14.6389 17.3106 14.5784 17.4562 14.471 17.5636C14.3636 17.671 14.218 17.7315 14.0662 17.7318Z"
            fill="red"
          />
        </svg>
      </svg>
    </S.Container>
  )
}

export default AdminComCardDeleteButton
