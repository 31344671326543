import CustomCursor from "custom-cursor-react";
import "custom-cursor-react/dist/index.css";
import styled from "styled-components";
import React, { Fragment, useContext, useEffect } from "react";
import Spacer from "../../components/Spacer";
import AddButton from "../../components/buttons/AddButton";
import HashTag from "../../components/tags/HashTag";
import uuid from "react-uuid";
import MinusButton from "../../components/buttons/MinusButton";
import { observer } from "mobx-react";
import { StoreContext } from "../../store/store.context";
import CloseButton from "../../components/buttons/CloseButton";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ImageModal from "../../components/modals/ImageModal";
import Desktop from "../../components/responsive/desktop";
import Mobile from "../../components/responsive/mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { autorun } from "mobx";
import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import InstaButton from "../../components/buttons/InstaButton";

const S = {
  Modal: styled.div`
    display: flex;
    flex-direction: column;
    background: black;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    overflow-x: hidden;

    @media only screen and (max-width: 1024px) {
      position: unset;
      height: auto;
    }
  `,
  ButtonContainer: styled.div`
    max-width: 95vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  `,
  ModalContainer: styled.div`
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  ModalImage: styled.img`
    flex: 1;
  `,
  Container: styled.div`
    width: 90vw;
    max-width: 100vw;
    min-height: 500px;
    align-items: center;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      max-height: unset;
    }
  `,

  InfoContainer: styled.div`
    min-width: 300px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      min-width: 90vw;
    }
  `,
  ModelLastEName: styled.div`
    font-family: "Exo", sans-serif;
    font-size: 84px;
    font-weight: 600;
    color: white;
  `,
  ModelFirstEName: styled.div`
    font-family: "Exo", sans-serif;
    font-size: 48px;
    color: white;
    font-weight: 400;
  `,
  KNameContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  ModelKName: styled.div`
    font-family: "Jura", sans-serif;
    font-size: 32px;
    color: white;
  `,
  TagContainer: styled.div`
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    max-width: 1156px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  `,
  ModelProfileContainer: styled.div`
    flex: 1;
    display: flex;
    height: 200px;
    flex-direction: row;

    @media only screen and (max-width: 1024px) {
      height: 40px;
    }
  `,
  ModelProfile: styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  ModelProfileText: styled.div`
    font-size: 16px;
    font-family: "Jura", sans-serif;
    font-weight: 600;
    color: white;
  `,
  MenuContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Menu: styled.div<{ selected: boolean }>`
    font-family: "Jura", sans-serif;
    font-weight: 500;
    cursor: pointer;
    font-size: 18px;
    color: ${(props) => (props.selected ? "white" : "#BEC1C7")};

    &:hover {
      color: white;
    }
  `,
  Divider: styled.div`
    width: 100%;
    background-color: white;
    height: 1px;
  `,
  PDF: styled.a`
    font-family: "Roboto Light", sans-serif;
    font-weight: 400;
    color: white;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  `,
  PortfolioContainer: styled.div`
    width: 100%;
    height: 80vh;

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 1024px) {
      max-width: 90vw;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 10px;
      height: auto;
    }
  `,
  PortfolioAreaContainer: styled.div`
    width: 60%;
    position: absolute;
    display: flex;
    flex-direction: row;
    z-index: 9998;
    cursor: none;
    overflow: hidden;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 400px;
    }
  `,
  PortfolioArea: styled.div`
    flex: 1;
    width: 100%;
    height: 80vh;
    max-width: 80vh;
    z-index: 9999;

    @media only screen and (max-width: 1024px) {
      flex: 1;
      height: 400px;
      max-width: unset;
    }

    &:last-child {
    }
  `,
  PortfolioImage: styled.img`
    width: 100%;
    position: relative;
    object-fit: contain;
    animation: FadeIn 0.5s ease-out;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
      position: inherit;
      position: unset;
      aspect-ratio: 1/1.3;
      height: 100%;
      object-fit: fill;
    }

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @media only screen and (max-width: 1024px) {
      animation: none;
    }
  `,
  OverviewContainer: styled.div`
    display: grid;
    max-width: 50vw;
    min-height: 80vh;
    overflow-y: scroll;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 0.5fr;
    grid-gap: 20px;
    max-height: 80vh;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  OverviewImage: styled.img`
    width: 100%;
    background-size: contain;
    aspect-ratio: 1/1.3;

    animation: FadeIn 0.5s ease-out;

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @media only screen and (max-width: 1024px) {
      animation: none;
    }
  `,
  ImageContainer: styled.div`
    flex: 2;
    height: 720px;
    @media only screen and (max-width: 1024px) {
      flex: none;
    }
  `,
  VideoContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      flex: none;
      min-width: 90vw;
      min-height: 400px;
    }
  `,
  Video: styled.video`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      max-height: 400px;
      height: 400px;
      object-fit: contain;
    }
  `,
  Right: styled(BsChevronRight)`
    font-size: 24px;
    cursor: pointer;
    color: white;
  `,
  Left: styled(BsChevronLeft)`
    font-size: 24px;
    cursor: pointer;
    color: white;
  `,
  ScrollContainer: styled.div`
    max-width: 60vw;
    min-height: 80vh;
    max-height: inherit;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  InstagramContainer: styled.div`
    align-items: center;
    width: 80vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    height: 80vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      overflow-y: unset;
      grid-template-rows: 1fr;
      height: unset;
    }

    @media only screen and (max-width: 1024px) {
      width: 90vw;
      height: unset;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      margin-bottom: 100px;
      overflow-y: unset;
    }
  `,
  InstagramImage: styled.img`
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;

    @media only screen and (max-width: 1024px) {
    }
  `,
  PDFButton: styled.a`
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    border-radius: 4px;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    font-family: Jura;
    font-weight: 400;
    color: white;
  `,
  MobileTitle: styled.div`
    display: flex;
    color: white;
    font-size: 18px;
    font-weight: 700;
    font-family: Jura;
    margin-top: 40px;
    margin-bottom: 40px;
  `,
};
const styles = StyleSheet.create({
  page: {
    display: "flex",
    backgroundColor: "black",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 300,
    padding: 10,
    marginTop: 80,
  },
  logo: {
    width: 60,
  },
  compCard: {
    width: 880,
    height: 560,
    objectFit: "contain",
  },
});

const Index: React.FC = () => {
  const { globalStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    hashTags,
    selectedModelDetailModalMenu,
    setModelDetailModalMenu,
    modelPortfolioCurrentImageUrl,
    modelPortfolioCurrentIndex,
    modelVideoIndex,
    modalImageUrl,
    modelImageModalIsOpen,
    openImageModal,
    closeImageModal,
    showPreviousVideo,
    showNextVideo,
    showNextImage,
    showPreviousImage,
    setModalImageUrl,
    selectedModel,
    removeFavoriteModel,
    addFavoriteModel,
    favoriteIds,
    getModelData,
    showAllHashtag,
    getHomeData,
  } = globalStore;

  useEffect(() => {
    autorun(async () => {
      setModelDetailModalMenu("PORTFOLIO");
      await getModelData(location.state);
      if (selectedModel === null) {
        await getHomeData();
      }

      window.scrollTo(0, 0);
    });
  }, [getHomeData]);

  const document = (
    <Document>
      {selectedModel?.comCardImageUrl && (
        <Page
          size={"A4"}
          style={styles.page}
          orientation={"landscape"}
          key={uuid()}
        >
          <View style={styles.container}>
            <Image
              src={{
                uri: selectedModel?.comCardImageUrl,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={styles.compCard}
            />
          </View>
        </Page>
      )}
    </Document>
  );

  return (
    <>
      {selectedModel && (
        <S.Modal>
          <S.ButtonContainer>
            <CloseButton onClick={() => navigate(-1)} />
          </S.ButtonContainer>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Desktop>
              <CustomCursor
                targets={[".left"]}
                customClass="cursor-left"
                fill="transparent"
                dimensions={10}
                smoothness={{
                  movement: 1,
                  scale: 1,
                  opacity: 1,
                }}
                opacity={0}
                targetOpacity={0.6}
              />
              <CustomCursor
                targets={[".right"]}
                customClass="cursor-right"
                smoothness={{
                  movement: 1,
                  scale: 1,
                  opacity: 1,
                }}
                fill="transparent"
                opacity={0}
                targetOpacity={0.6}
              />
              <ImageModal
                imgUrl={modalImageUrl}
                isOpen={modelImageModalIsOpen}
                onRequestClose={closeImageModal}
              />
              {selectedModel && (
                <S.Container>
                  <S.InfoContainer>
                    <S.ModelLastEName>
                      {selectedModel.eLastName}
                    </S.ModelLastEName>
                    <S.ModelFirstEName>
                      {selectedModel.eFirstName}
                    </S.ModelFirstEName>
                    <Spacer height={32} />
                    <S.KNameContainer>
                      {favoriteIds.includes(selectedModel.id) ? (
                        <MinusButton
                          onClick={() => removeFavoriteModel(selectedModel.id)}
                        />
                      ) : (
                        <AddButton
                          width={24}
                          height={24}
                          onClick={() => addFavoriteModel(selectedModel)}
                        />
                      )}
                      <Spacer width={40} />
                      <S.ModelKName>{selectedModel.name}</S.ModelKName>
                    </S.KNameContainer>
                    <Spacer height={12} />
                    {/* <S.TagContainer>
                      {showAllHashtag &&
                        selectedModel.hashTags.map((tagId, i) => {
                          const tag = hashTags.find(
                            (hashTag) => (hashTag.id = tagId)
                          );
                          if (tag) {
                            return (
                              <HashTag key={uuid()} text={`#${tag.tagName}`} />
                            );
                          } else {
                            return <Fragment key={uuid()} />;
                          }
                        })}
                    </S.TagContainer> */}
                    <Spacer height={20} />
                    <S.ModelProfileContainer>
                      <S.ModelProfile>
                        <S.ModelProfileText>Birth</S.ModelProfileText>
                        <S.ModelProfileText>
                          {selectedModel?.birth}
                        </S.ModelProfileText>
                      </S.ModelProfile>
                      <Spacer width={40} />
                      <S.ModelProfile>
                        <S.ModelProfileText>Height</S.ModelProfileText>
                        <S.ModelProfileText>
                          {selectedModel?.height} cm
                        </S.ModelProfileText>
                      </S.ModelProfile>
                    </S.ModelProfileContainer>
                    <S.MenuContainer>
                      <S.Menu
                        selected={selectedModelDetailModalMenu === "PORTFOLIO"}
                        onClick={() => setModelDetailModalMenu("PORTFOLIO")}
                      >
                        PORTFOLIO
                      </S.Menu>
                      <Spacer height={20} />
                      <S.Menu
                        selected={selectedModelDetailModalMenu === "OVERVIEW"}
                        onClick={() => setModelDetailModalMenu("OVERVIEW")}
                      >
                        OVERVIEW
                      </S.Menu>
                      <Spacer height={20} />
                      <S.Menu
                        selected={selectedModelDetailModalMenu === "VIDEO"}
                        onClick={() => setModelDetailModalMenu("VIDEO")}
                      >
                        VIDEO
                      </S.Menu>
                      <Spacer height={20} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <S.Menu
                          selected={
                            selectedModelDetailModalMenu === "INSTAGRAM"
                          }
                          onClick={() => setModelDetailModalMenu("INSTAGRAM")}
                        >
                          INSTAGRAM
                        </S.Menu>
                        <Spacer width={12} />
                        {selectedModel.instagramAddress !== "" && (
                          <InstaButton
                            url={`https://www.instagram.com/${selectedModel.instagramAddress}`}
                          />
                        )}
                      </div>
                    </S.MenuContainer>
                    <Spacer height={20} />
                    <S.Divider />
                    <Spacer height={20} />
                    {selectedModel && (
                      <PDFDownloadLink document={document} fileName={"models"}>
                        <S.PDF>PDF DOWNLOAD</S.PDF>
                      </PDFDownloadLink>
                    )}
                  </S.InfoContainer>
                  <Spacer width={60} />
                  <S.ImageContainer>
                    {selectedModelDetailModalMenu === "PORTFOLIO" && (
                      <S.PortfolioContainer>
                        {modelPortfolioCurrentImageUrl.map((url, i) => {
                          if (i === modelPortfolioCurrentIndex) {
                            if (url.includes("mp4")) {
                              return (
                                <S.Video
                                  style={{ marginLeft: 40 }}
                                  key={uuid()}
                                  playsInline
                                  loop
                                  autoPlay
                                  muted
                                >
                                  <source src={url} type={"video/mp4"} />
                                </S.Video>
                              );
                            } else {
                              return (
                                <S.PortfolioImage key={uuid()} src={url} />
                              );
                            }
                          } else {
                            return <Fragment key={uuid()}></Fragment>;
                          }
                        })}

                        <S.PortfolioAreaContainer>
                          <S.PortfolioArea
                            className={"left"}
                            onClick={showPreviousImage}
                          ></S.PortfolioArea>
                          <S.PortfolioArea
                            className={"right"}
                            onClick={showNextImage}
                          ></S.PortfolioArea>
                        </S.PortfolioAreaContainer>
                      </S.PortfolioContainer>
                    )}
                    {selectedModelDetailModalMenu === "OVERVIEW" && (
                      <S.OverviewContainer>
                        {selectedModel.images.map((image) => (
                          <div key={uuid()}>
                            <S.OverviewImage
                              key={uuid()}
                              loading={"lazy"}
                              onClick={() => {
                                setModalImageUrl(image.url);
                                openImageModal();
                              }}
                              src={image.url}
                            />
                          </div>
                        ))}
                      </S.OverviewContainer>
                    )}
                    {selectedModelDetailModalMenu === "VIDEO" && (
                      <S.PortfolioContainer>
                        {selectedModel.videos.map((url, i) => {
                          if (modelVideoIndex === i) {
                            return (
                              <S.Video
                                style={{ marginLeft: 40 }}
                                key={uuid()}
                                playsInline
                                loop
                                autoPlay
                                muted
                              >
                                <source src={url} type={"video/mp4"} />
                              </S.Video>
                            );
                          }
                          return <Fragment key={uuid()} />;
                        })}

                        <S.PortfolioAreaContainer>
                          <S.PortfolioArea
                            onClick={showPreviousVideo}
                          ></S.PortfolioArea>
                          <S.PortfolioArea
                            onClick={showNextVideo}
                          ></S.PortfolioArea>
                        </S.PortfolioAreaContainer>
                      </S.PortfolioContainer>
                    )}
                    {selectedModelDetailModalMenu === "INSTAGRAM" && (
                      <S.ScrollContainer>
                        <S.InstagramContainer>
                          {selectedModel.instagram.map((image) => (
                            <S.InstagramImage
                              onClick={() => {
                                if (selectedModel?.instagramAddress !== "") {
                                  window.open(
                                    `https://www.instagram.com/${selectedModel?.instagramAddress}`,
                                    "_blank"
                                  );
                                }
                              }}
                              key={uuid()}
                              src={image}
                            />
                          ))}
                        </S.InstagramContainer>
                      </S.ScrollContainer>
                    )}
                  </S.ImageContainer>
                </S.Container>
              )}
            </Desktop>
            <Mobile>
              <ImageModal
                imgUrl={modalImageUrl}
                isOpen={modelImageModalIsOpen}
                onRequestClose={closeImageModal}
              />

              <S.Container>
                <S.InfoContainer>
                  <S.ModelLastEName>
                    {selectedModel!.eLastName}
                  </S.ModelLastEName>
                  <S.ModelFirstEName>
                    {selectedModel!.eFirstName}
                  </S.ModelFirstEName>
                  <Spacer height={32} />
                  <S.KNameContainer>
                    {favoriteIds.includes(selectedModel!.id) ? (
                      <MinusButton
                        onClick={() => removeFavoriteModel(selectedModel!.id)}
                      />
                    ) : (
                      <AddButton
                        width={24}
                        height={24}
                        onClick={() => addFavoriteModel(selectedModel!)}
                      />
                    )}
                    <Spacer width={40} />
                    <S.ModelKName>{selectedModel!.name}</S.ModelKName>
                  </S.KNameContainer>
                  {/* <S.TagContainer>
                    {selectedModel!.hashTags.map((tagId, i) => {
                      const tag = hashTags.find(
                        (hashTag) => (hashTag.id = tagId)
                      );
                      if (tag) {
                        return (
                          <HashTag key={uuid()} text={`#${tag.tagName}`} />
                        );
                      } else {
                        return <Fragment key={uuid()} />;
                      }
                    })}
                  </S.TagContainer> */}
                  <Spacer height={20} />
                  <S.ModelProfileContainer>
                    <S.ModelProfile>
                      <S.ModelProfileText>Birth</S.ModelProfileText>
                      <S.ModelProfileText>
                        {selectedModel!.birth}
                      </S.ModelProfileText>
                    </S.ModelProfile>
                    <Spacer width={40} />
                    <S.ModelProfile>
                      <S.ModelProfileText>Height</S.ModelProfileText>
                      <S.ModelProfileText>
                        {selectedModel!.height} cm
                      </S.ModelProfileText>
                    </S.ModelProfile>
                  </S.ModelProfileContainer>
                  <Spacer height={20} />
                </S.InfoContainer>
                <S.MobileTitle>VIDEO</S.MobileTitle>
                <S.VideoContainer>
                  <S.Video
                    key={uuid()}
                    playsInline={true}
                    muted
                    autoPlay
                    onEnded={showNextVideo}
                    src={selectedModel!.videos[modelVideoIndex]}
                    typeof={"video/mp4"}
                  />
                  <S.PortfolioAreaContainer>
                    <S.PortfolioArea
                      className={"left"}
                      onClick={showPreviousVideo}
                    ></S.PortfolioArea>
                    <S.PortfolioArea
                      className={"right"}
                      onClick={showNextVideo}
                    ></S.PortfolioArea>
                  </S.PortfolioAreaContainer>
                </S.VideoContainer>

                <S.MobileTitle>IMAGE</S.MobileTitle>
                <S.PortfolioContainer>
                  {selectedModel!.images.map((image, i) => {
                    return (
                      <S.PortfolioImage
                        onClick={() => {
                          setModalImageUrl(image.url);
                          openImageModal();
                        }}
                        key={uuid()}
                        src={image.url}
                        alt={image.url}
                      />
                    );
                  })}
                </S.PortfolioContainer>
                {/*{selectedModelDetailModalMenu === "INSTAGRAM" && (*/}
                {/*    <S.InstagramContainer>*/}
                {/*        {selectedModel.instagram.map((image) => (*/}
                {/*            <S.InstagramImage key={uuid()} src={image} alt={"123"}/>*/}
                {/*        ))}*/}
                {/*    </S.InstagramContainer>*/}
                {/*)}*/}
                <S.MobileTitle
                  style={{ display: "flex", alignItems: "center" }}
                >
                  INSTAGRAM
                  <Spacer width={12} />
                  {selectedModel!.instagramAddress !== "" && (
                    <InstaButton
                      url={`https://www.instagram.com/${
                        selectedModel!.instagramAddress
                      }`}
                    />
                  )}
                </S.MobileTitle>
                <S.InstagramContainer>
                  {selectedModel!.instagram.map((image) => (
                    <S.InstagramImage key={uuid()} src={image} />
                  ))}
                </S.InstagramContainer>
              </S.Container>
            </Mobile>
          </div>
        </S.Modal>
      )}
    </>
  );
};

export default observer(Index);
