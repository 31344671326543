import styled from "styled-components";
import {Link} from "react-router-dom";


const S = {
    Button: styled.button`
      color: ${({theme}) => theme.colors.white};
      padding: 12px;
      border-radius: 4px;
      font-size: 16px;
      border: ${({theme}) => `1px solid ${theme.colors.white}`};
      background-color: ${({theme}) => theme.colors.black};
      cursor: pointer;
    `
}




const HomeButton =() => {
    return (
        <Link to={'/'}>
            <S.Button>홈으로 이동</S.Button>
        </Link>
    )
}

export default HomeButton