import React, {useContext, useEffect} from "react"
import styled from "styled-components"
import AdminHistoryButton from "../../../../components/buttons/AdminHistoryButton"
import Spacer from "../../../../components/Spacer"
import {StoreContext} from "../../../../store/store.context";
import {autorun} from "mobx";
import {observer} from "mobx-react";
import moment from "moment/moment";
import AdminAgreementHistoryModal from "../../../../components/modals/AdminAgreementHistoryModal";

const S = {
    Container: styled.div`
      height: inherit;
      padding: 0px 36px;
      width: 100%;
      background-color: #f7f9fb;
      overflow-y: auto;
    `,
    TitleBox: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 48px;
      padding-top: 36px;
    `,
    ControllBox: styled.div`
      display: flex;
      justify-content: space-between;
      gap: 10px;
    `,
    Title: styled.text`
      display: flex;
      width: auto;
      height: 36px;
      font-family: "Pretendard Variable";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      color: #3b3f4a;
    `,
    ButtonBox: styled.div`
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
    `,
    LastUpdateDiv: styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;

      width: 372px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #7f828c;
    `,
    TextAreaTitle: styled.div`
      display: flex;
      gap: 8px;

      height: 21px;
      flex: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #5a5e6a;
    `,
    TextArea: styled.textarea`
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      padding: 12px;
      gap: 16px;
      width: 1156px;
      height: 617px;
      background: #ffffff;
      border: 1px solid #e4e6ea;
      border-radius: 8px;
      align-self: stretch;
      flex-grow: 1;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #9da0a8;
      }
    `,
    SaveButton: styled.button`
      height: 48px;
      padding: 12px 133px;
      background: #00baff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      line-height: 150%;
      text-align: center;
      color: #ffffff;

      :hover {
        background: #0094ca;
      }
    `,
}

const AdminManagePrivacy = () => {
    const {adminStore} = useContext(StoreContext)
    const {
        saveAgreementData,
        agreement,
        getAgreementData,
        onChangeAgreementContent,
        agreementHistoryModal,
        getAgreementHistories,
        openAgreementHistoryModal,
        closeAgreementHistoryModal,
        histories
    } = adminStore

    useEffect(() => {
        autorun(async () => {
            await getAgreementData()
            await getAgreementHistories()
        })
    }, [])


    return (
        <>
            <AdminAgreementHistoryModal
                isOpen={agreementHistoryModal}
                onRequestClose={closeAgreementHistoryModal}
                histories={histories}
            />
            <S.Container>
                <S.TitleBox>
                    <S.Title>개인 정보 처리 방침 관리</S.Title>
                    <S.ControllBox>
                        <S.ButtonBox>
                            <AdminHistoryButton onClick={openAgreementHistoryModal}/>
                        </S.ButtonBox>
                    </S.ControllBox>
                </S.TitleBox>

                <Spacer height={12}/>

                <S.LastUpdateDiv>마지막 수정일 : {moment(agreement.createdAt).format('yyyy.MM.DD')}</S.LastUpdateDiv>

                <Spacer height={27.5}/>

                <S.TextAreaTitle>개인 정보 처리 방침</S.TextAreaTitle>

                <Spacer height={8}/>

                <S.TextArea placeholder="내용을 입력하세요." value={agreement.content} onChange={onChangeAgreementContent}/>

                <Spacer height={32}/>

                <S.SaveButton onClick={saveAgreementData}>저장하기</S.SaveButton>

                <Spacer height={107}/>
            </S.Container>
        </>
    )
}

export default observer(AdminManagePrivacy)
