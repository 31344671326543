import styled from "styled-components"
import InstaButton from "../buttons/InstaButton"
import Spacer from "../Spacer"
import AgreementModal from "../modals/AgreementModal"
import { useContext } from "react"
import { StoreContext } from "../../store/store.context"
import { observer } from "mobx-react"
import AgreementHistoryModal from "../modals/AgreementHistoryModal";

const S = {
  Background: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
  `,
  Container: styled.div`
    width: 90vw;
    max-width: 1156px;
    padding: 5vw;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @media only screen and (max-width: 414px) {
      flex-direction: column;
      padding-bottom: 5vh;
    }
  `,

  LinkContainer: styled.div`
    display: flex;
    flex-direction: row;
  `,

  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
  `,
  Info: styled.div`
    font-family: Jura;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 18px */
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;

    /* Gray Scale/Gray5 */

    color: #bec1c7;
  `,
  UnderLine: styled.span`
    text-decoration: underline;
    font-family: Jura;
    cursor: pointer;
  `,
  
}

const Footer = () => {
  const { globalStore } = useContext(StoreContext)
  const { agreementModalIsOpen, closeAgreementModal, openAgreementModal, agreementHistoryModalIsOpen, closeAgreementHistoryModal, histories } =
    globalStore
  const privacyUrl = "http://www.readymodel.com/privacy-policy"

  return (
    <>
      <AgreementModal
        isOpen={agreementModalIsOpen}
        onRequestClose={closeAgreementModal}
      />
      <AgreementHistoryModal
          histories={histories}
          isOpen={agreementHistoryModalIsOpen}
          onRequestClose={closeAgreementHistoryModal}
      />
      <S.Background>
        <S.Container>
          <S.LinkContainer>
            <InstaButton url={"https://www.instagram.com/readyxmodel"}/>
          </S.LinkContainer>
          <Spacer height={20} />

          <S.InfoContainer>
            <S.Info>READY ENTERTAINMENT</S.Info>
            <Spacer height={16} />
            <S.Info>
              사업자번호: 211-87-56819 | 대표: 구기운 <br/>
              Tel : 02-3445-3222 | Fax: 02-3445-3226 | Email:ready@readyent.com <br/>
              주소: 서울시 강남구 언주로 130길 13 다은빌딩 (논현동 238-16 다은빌딩) <br/>
              License Number. 2006-3220083-11-5-00007
            </S.Info>
            <Spacer height={16} />
            <S.Info>
              {/* <S.UnderLine onClick={openAgreementModal}>
                개인정보처리방침
              </S.UnderLine> */}
              <S.UnderLine onClick={()=>{window.open(privacyUrl)}}>
                개인정보처리방침
              </S.UnderLine>
              <Spacer width={12}  height={4} />Copyright© 2023 Ready Entertainment All Rights Reserved.
            </S.Info>
          </S.InfoContainer>
        </S.Container>
      </S.Background>
    </>
  )
}

export default observer(Footer)
