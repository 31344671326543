import styled from "styled-components";
import {IoMdClose} from "react-icons/io";
import React, {useContext, useEffect} from "react";
import Spacer from "../Spacer";
import {autorun} from "mobx";
import {StoreContext} from "../../store/store.context";


interface AgreementModalProps {
    isOpen: boolean,
    imgUrl?: string,
    onRequestClose: () => void
}


const S = {
    Modal: styled.div<{isOpen: boolean}>`
      animation: ${props => {
        if(props.isOpen){
            return `FadeIn 0.5s ease-out`
        }
    }};
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(25px);
      background: rgba(0, 0, 0, 0.5);
      z-index: 100;
      
      @keyframes FadeIn{
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      
    `,
    ModalContainer: styled.div`
      width: 60vw;
      padding: 20px;
      height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: #3B3F4A;
    `,
    ModalTitle: styled.div`
        display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-family: Jura, sans-serif;
      font-size: 36px;
      color: white;
      padding-top: 40px;
      padding-bottom: 40px;
      @media only screen and (max-width: 764px) {
        font-size: 24px;
      }
    `,
    ModalDivider: styled.div`
      background-color: white;
      height: 2px;
      width: 100%;
    `,
    ModalCloseButton: styled(IoMdClose)`
      position: absolute;
      top: calc(10vh + 40px);
      right: 20vw;
      color: white;
      font-weight: bold;
      font-size: 28px;
      cursor: pointer;
    `,
    ModalHistoryButton: styled.div`
        position: absolute;
      top: calc(10vh + 40px);
      right: calc(20vw + 60px);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding-top: 8px;
      text-decoration: underline;
      cursor: pointer;
    `,
    ModalUpdateDate: styled.div`
        width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      color: #E4E6EA;
      font-weight: 300;
    `,
    ModalContent: styled.div`
        display: flex;
      width: 100%;
      overflow-y: scroll;
      line-height: 170%;
      color: white;
      font-family: Jura, sans-serif;
      flex: 1;
    `
}



const AgreementModal:React.FC<AgreementModalProps> = ({isOpen, onRequestClose}) => {
    const {globalStore} = useContext(StoreContext)
    const {getAgreement, agreement,
        openAgreementHistoryModal
    } = globalStore

    useEffect(() => {
        autorun(async () => {
            await getAgreement()
        })
    },[])


    return (
        <>
            {isOpen && <S.Modal isOpen={isOpen}>
                <S.ModalContainer>
                    <S.ModalTitle>
                        개인 정보 처리 방침
                    </S.ModalTitle>
                    <S.ModalDivider/>
                    <Spacer height={20}/>
                    <S.ModalUpdateDate>마지막 수정일 : {agreement?.createdAt}</S.ModalUpdateDate>
                    <Spacer height={20}/>
                    <S.ModalContent>
                        {agreement?.content}
                    </S.ModalContent>
                    <S.ModalHistoryButton onClick={openAgreementHistoryModal} >변경내역</S.ModalHistoryButton>
                    <S.ModalCloseButton onClick={onRequestClose}/>
                </S.ModalContainer>
            </S.Modal>}
        </>
    )
}

export default AgreementModal