import styled from "styled-components";
import Spacer from "../Spacer";
import React from "react";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, View} from "@react-pdf/renderer";
import uuid from "react-uuid";
import {Model} from "../../models/interfaces/Model";

interface AdditionalMenuProps {
    models: Model[]
    sendEmailOnClick?: () => void
}

const S = {
    Container: styled.div`
      width: calc(100% - 12px);
      display: flex;
      padding: 12px 8px;
      flex-direction: row;
      border-bottom: 1px solid white;
      
    `,
    Menu: styled.div`
      cursor: pointer;
      font-family: Jura, sans-serif;
      font-size: 16px;
      font-weight: 300;
      color: white;
    `,
    Divider: styled.div`
        height: 12px;
      background-color: white;
      width: 1px;
      color: white;
    `
}

const styles = StyleSheet.create({

    page: {
        display: "flex",
        backgroundColor: 'black',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    image: {
        width: 300,
        padding: 10,
        marginTop: 80,
    },
    logo: {
        width: 60
    },
    compCard: {
        width: 880,
        height: 560,
        objectFit: "contain"
    }
});

const AdditionalMenu:React.FC<AdditionalMenuProps> = ({models,
                                                      sendEmailOnClick}) => {



    const document = <Document>
        {
            models?.map((model) => {
                return <Page size="A4" style={styles.page} key={uuid()} orientation={"landscape"}>
                    <View style={styles.container}>
                        <Image src={{
                            uri: model?.comCardImageUrl,
                            method: 'GET',
                            headers: {"Cache-Control": "no-cache"},
                            body: ''
                        }} style={styles.compCard}/>
                    </View>
                </Page>
            })
        }
    </Document>


    return (<S.Container>
        <PDFDownloadLink document={document} fileName={'models'} style={{textDecoration: "none"}}>
            <S.Menu>
                PDF DOWNLOAD
            </S.Menu>
        </PDFDownloadLink>
        <Spacer width={20}/>
        <S.Divider/>
        <Spacer width={20}/>
        <S.Menu onClick={sendEmailOnClick}>CONTACT</S.Menu>
    </S.Container>)
}

export default AdditionalMenu