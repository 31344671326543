import React from "react"
import styled from "styled-components"
import Spacer from "../Spacer"
import Search from "../icons/Search"

interface AdminSearchInputProps {
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  width?: string
}

const S = {
  Container: styled.div<{ width?: string }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: ${(props) => (props.width ? props.width : "324px")};
    height: 48px;
    left: 924px;
    top: 82px;
    color: black;
    border-radius: 8px;
  `,
  TextContainer: styled.div<{ width?: string }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    width: ${(props) => (props.width ? props.width : "324px")};
    height: 48px;
    background: #ffffff;
    border: 1px solid #e4e6ea;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 1;
    :focus-within {
      border: 1px solid #4e88f2;
    }
    :hover {
      border: 1px solid #4e88f2;
    }
  `,
  Input: styled.input`
    flex: 1;
    background-color: transparent;
    font-size: 20px;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    &::placeholder {
      color: #9da0a8;
      font-weight: 100;
      font-family: Montserrat;
    }
  `,
}

const AdminSearchInput: React.FC<AdminSearchInputProps> = ({
  value,
  onChange,
  width,
}) => {
  return (
    <S.Container width={width}>
      <S.TextContainer width={width}>
        <Search />
        <S.Input value={value} onChange={onChange} placeholder={"모델명"} />
      </S.TextContainer>
    </S.Container>
  )
}

export default AdminSearchInput
