const Search = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.618 17.1251C16.5463 17.1253 16.4754 17.1111 16.4093 17.0835C16.3432 17.0559 16.2833 17.0153 16.233 16.9643L12.4305 13.1309C12.4116 13.1093 12.3956 13.0852 12.383 13.0593L12.3655 13.0284L12.3605 13.0193L12.2863 12.8934L12.173 12.9868C11.1514 13.8282 9.86981 14.2894 8.54637 14.2918C7.03304 14.29 5.58215 13.688 4.51215 12.6178C3.44214 11.5477 2.84041 10.0968 2.83887 8.58343C2.84041 7.07003 3.44216 5.61903 4.51215 4.54874C5.58213 3.47844 7.03296 2.87625 8.54637 2.87427C10.0601 2.87581 11.5113 3.47781 12.5816 4.54815C13.6519 5.61849 14.2539 7.06974 14.2555 8.58343C14.2579 9.85972 13.8275 11.0991 13.0347 12.0993L12.9438 12.2151L13.093 12.2984C13.1321 12.3169 13.1684 12.3411 13.2005 12.3701L17.0038 16.2034C17.054 16.254 17.0936 16.3139 17.1205 16.3799C17.1474 16.4458 17.161 16.5164 17.1605 16.5876C17.1605 16.6588 17.1464 16.7293 17.119 16.7949C17.0915 16.8606 17.0512 16.9201 17.0004 16.9701C16.95 17.0198 16.8903 17.0591 16.8247 17.0857C16.759 17.1123 16.6888 17.1257 16.618 17.1251ZM8.54637 3.95843C7.32021 3.95998 6.14465 4.44775 5.27763 5.31477C4.41061 6.18179 3.92287 7.35728 3.92133 8.58343C3.92287 9.80951 4.41069 10.9849 5.27773 11.8518C6.14478 12.7187 7.32029 13.2063 8.54637 13.2076C9.77252 13.2063 10.948 12.7187 11.8152 11.8519C12.6824 10.985 13.1704 9.80959 13.1721 8.58343C13.1706 7.35714 12.6827 6.18152 11.8155 5.31448C10.9483 4.44743 9.77266 3.95976 8.54637 3.95843Z"
        fill="#0A0A0A"
      />
    </svg>
  )
}

export default Search
