import React from "react"
import styled from "styled-components"
import BackArrow from "../icons/BackArrow"

interface BackArrowButtonProps {
  onClick?: () => void
  width?: number
  height?: number
}

const S = {
  Container: styled.div`
    cursor: pointer;
  `,
}

const BackArrowButton: React.FC<BackArrowButtonProps> = ({
  onClick,
  width = 24,
  height = 24,
}) => {
  return (
    <S.Container onClick={onClick}>
      <BackArrow />
    </S.Container>
  )
}

export default BackArrowButton
