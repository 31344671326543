import DesktopHeader from "../../components/header/Header";
import Background from "../../components/Background";
import FooterDesktop from "../../components/footer/Footer";
import Spacer from "../../components/Spacer";
import Title from "../../components/texts/Title";
import { useContext, useEffect } from "react";
import { StoreContext } from "../../store/store.context";
import AboutImage from "../../images/about.png";
import styled from "styled-components";
import MenuButton from "../../components/buttons/MenuButton";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import ArrowRight from "../../components/icons/ArrowRight";
import { history } from "../../history";
import category from "../admin/detail/category";

const S = {
  Image: styled.img`
    aspect-ratio: 1156/401;
    width: 100%;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 40px;
    justify-content: center;
    @media only screen and (max-width: 764px) {
      flex-direction: column;
    }
  `,
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    flex-direction: column;
    max-width: 1156px;
  `,
  Info: styled.div`
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media only screen and (max-width: 764px) {
      margin-bottom: 80px;
      align-items: flex-start;
    }
  `,
  InfoBigTitle: styled.div`
    width: 100%;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    color: white;
    font-size: 84px;
    @media only screen and (max-width: 764px) {
      width: 90vw;
    }
  `,
  InfoDesc: styled.div`
    font-family: Jura, sans-serif;
    font-size: 16px;
    width: 100%;
    font-weight: 400;
    line-height: 170%;
    color: #e4e6ea;
    text-align: left;
    @media only screen and (max-width: 764px) {
      width: 90vw;
    }
  `,
  ButtonContainer: styled.div`
    padding-top: 10vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
  `,
  ClientInput: styled.input`
    border: none;
    border-bottom: 1px solid white;
    width: 100%;
    background-color: transparent;
    outline: none;
    padding-bottom: 20px;
    margin-bottom: 20px;
    color: white;
    font-size: 14px;
    @media only screen and (max-width: 764px) {
      width: 90vw;
    }

    &::placeholder {
      color: white;
      font-family: Jura, sans-serif;
      font-weight: 200;
      font-size: 14px;
    }
  `,
  ClientTextArea: styled.textarea`
    resize: none;
    border: none;
    border-bottom: 1px solid white;
    width: 100%;
    background-color: transparent;
    outline: none;
    padding-bottom: 20px;
    margin-bottom: 20px;
    color: white;
    font-size: 14px;
    @media only screen and (max-width: 764px) {
      width: 90vw;
    }

    &::placeholder {
      color: white;
      font-family: Jura, sans-serif;
      font-weight: 200;
      font-size: 14px;
    }
  `,
  SendButton: styled.div`
    font-family: Jura, sans-serif;
    font-size: 14px;
    width: 118px;
    height: 44px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
  ModelContact: styled.div`
    font-family: Jura, sans-serif;
    font-size: 32px;
    display: flex;
    justify-content: flex-start;
    color: white;
  `,
};

const Contact = () => {
  const navigate = useNavigate();
  const { globalStore } = useContext(StoreContext);
  const {
    selectedCategory,
    changeMenu,
    onChangeName,
    onChangePhoneNumber,
    onChangeCompany,
    categories,
    name,
    phoneNumber,
    company,
    sendEmail,
    memo,
    onChangeMemo,
  } = globalStore;

  useEffect(() => {
    const listenBackEvent = () => {
      // 뒤로가기 할 때 수행할 동작을 적는다
      const before = localStorage.getItem("before");
      switch (before) {
        case "SELECTED":
          changeMenu("SELECTED");
          break;
        case "UPDATED":
          changeMenu("UPDATED");
          break;
        default:
          const category = categories.find(
            (category) => category.name === before
          );
          changeMenu(category!.name);
          break;
      }
    };

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === "POP") {
        listenBackEvent();
      }
    });

    return unlistenHistoryEvent;
  }, []);

  useEffect(() => {
    changeMenu("CONTACT");
  }, []);

  return (
    <>
      <DesktopHeader />
      <Background>
        <S.Container>
          <Spacer height={60} />
          <Title>{selectedCategory}</Title>
          <Spacer height={120} />
          <S.InfoContainer>
            <S.Info>
              <S.InfoBigTitle>CLIENT</S.InfoBigTitle>
              <Spacer height={40} />
              <S.ClientInput
                placeholder={"Name"}
                onChange={onChangeName}
                value={name}
              />
              <S.ClientInput
                placeholder={"Phone number"}
                onChange={onChangePhoneNumber}
                value={phoneNumber}
              />
              <S.ClientInput
                placeholder={"Company"}
                onChange={onChangeCompany}
                value={company}
              />
              <S.ClientTextArea
                rows={5}
                placeholder={"Memo"}
                onChange={onChangeMemo}
                value={memo}
              />
              <S.SendButton
                onClick={async () => {
                  await sendEmail();
                }}
              >
                <div>SEND</div>
                <Spacer width={8} />
                <ArrowRight />
              </S.SendButton>
            </S.Info>
            <S.Info>
              <S.InfoBigTitle>MODEL</S.InfoBigTitle>
              <Spacer height={40} />
              <S.ModelContact>CONTACT</S.ModelContact>
              <Spacer height={12} />
              <S.InfoDesc>프로필 등록 및 오디션 지원</S.InfoDesc>
              <Spacer height={8} />
              <S.InfoDesc>ready@readyent.com</S.InfoDesc>
            </S.Info>
          </S.InfoContainer>
          <Spacer height={20} />
          <S.ButtonContainer>
            <MenuButton
              onClick={() => {
                navigate("/about");
                changeMenu("ABOUT US");
              }}
              label={"ABOUT US"}
            />
          </S.ButtonContainer>
        </S.Container>
      </Background>

      <FooterDesktop />
    </>
  );
};

export default observer(Contact);
