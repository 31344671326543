import {
  collection,
  doc,
  getDocs,
  query,
  where,
  getDoc,
  deleteDoc,
  serverTimestamp,
  setDoc,
  writeBatch,
  updateDoc,
  arrayUnion,
  orderBy,
} from "firebase/firestore/lite"
import { db } from "../firebase"
import { Model } from "../models/interfaces/Model"
import moment from "moment"
import { AdminModel } from "../models/interfaces/AdminModel"
import { HashTag } from "../models/interfaces/HashTag"
import { Category } from "../models/interfaces/Category"
import { CreateModelDTO } from "../models/interfaces/dto/CreateModelDTO"
import { S3Service } from "./S3Service"
import { UpdateModelDTO } from "../models/interfaces/dto/UpdateModelDTO"

export class ModelService {
  //모든 모델 가져오기
  static getAllModels = async () => {
    const ref = collection(db, "model")
    const models = await getDocs(ref)
    let modelList: Model[] = []
    models.forEach((model) => {
      modelList.push({
        id: model.id,
        height: model.data()["height"],
        birth: model.data()["birth"],
        categoryId: model.data()["categoryId"],
        eFirstName: model.data()["eFirstName"],
        eLastName: model.data()["eLastName"],
        hashTags: model.data()["hashTags"],
        images: model.data()["images"],
        instagram: model.data()["instagram"],
        instagramAddress: model.data()["instagramAddress"],
        mainImageUrl: model.data()["mainImageUrl"],
        comCardImageUrl: model.data()["comCardImageUrl"],
        name: model.data()["name"],
        videos: model.data()["videos"],
        createdAt: moment(model.data()["createdAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
        updatedAt: moment(model.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      })
    })
    return modelList
  }

  //카테고리별 모델 가져오기
  static getModelsByCategory = async (categoryId: string) => {
    const modelsRef = query(
      collection(db, "model"),
      where("categoryId", "==", categoryId)
    )
    const models = await getDocs(modelsRef)
    let modelList: Model[] = []
    models.forEach((model) => {
      modelList.push({
        id: model.id,
        height: model.data()["height"],
        birth: model.data()["birth"],
        categoryId: model.data()["categoryId"],
        eFirstName: model.data()["eFirstName"],
        eLastName: model.data()["eLastName"],
        hashTags: model.data()["hashTags"],
        images: model.data()["images"],
        instagram: model.data()["instagram"],
        instagramAddress: model.data()["instagramAddress"],
        mainImageUrl: model.data()["mainImageUrl"],
        comCardImageUrl: model.data()["comCardImageUrl"],
        name: model.data()["name"],
        videos: model.data()["videos"],
        createdAt: moment(model.data()["createdAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
        updatedAt: moment(model.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      })
    })
    return modelList
  }

  //해시태그별 모델 가져오기
  static getModelsByHashTag = async (hashTagId: string, categoryId: string) => {
    const modelsRef = query(
      collection(db, "model"),
      where("hashTags", "array-contains-any", [hashTagId]),
      where("categoryId", "==", categoryId)
    )
    const models = await getDocs(modelsRef)
    let modelList: Model[] = []
    models.forEach((model) => {
      modelList.push({
        id: model.id,
        height: model.data()["height"],
        birth: model.data()["birth"],
        categoryId: model.data()["categoryId"],
        eFirstName: model.data()["eFirstName"],
        eLastName: model.data()["eLastName"],
        hashTags: model.data()["hashTags"],
        images: model.data()["images"],
        instagram: model.data()["instagram"],
        instagramAddress: model.data()["instagramAddress"],
        mainImageUrl: model.data()["mainImageUrl"],
        comCardImageUrl: model.data()["comCardImageUrl"],
        name: model.data()["name"],
        videos: model.data()["videos"],
        createdAt: moment(model.data()["createdAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
        updatedAt: moment(model.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      })
    })
    console.log(modelList)
    return modelList
  }

  static getModelById = async (id: string) => {
    const modelRef = doc(db, `model/${id}`)
    const model = await getDoc(modelRef)

    if (model.exists()) {
      const modelData: Model = {
        id: model.id,
        height: model.data()["height"],
        birth: model.data()["birth"],
        categoryId: model.data()["categoryId"],
        eFirstName: model.data()["eFirstName"],
        eLastName: model.data()["eLastName"],
        hashTags: model.data()["hashTags"],
        images: model.data()["images"],
        instagram: model.data()["instagram"],
        instagramAddress: model.data()["instagramAddress"],
        mainImageUrl: model.data()["mainImageUrl"],
        comCardImageUrl: model.data()["comCardImageUrl"],
        name: model.data()["name"],
        videos: model.data()["videos"],
        createdAt: moment(model.data()["createdAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
        updatedAt: moment(model.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      }
      return modelData
    }
  }

  //더미 모델 생성하기
  static createDummyModel = async () => {
    const ref = query(
      collection(db, "model"),
      where("eFirstName", "==", "eFirstName")
    )

    const data = await getDocs(ref)
    for (let i = 0; i < data.docs.length; i++) {
      const ref = doc(db, `model/${data.docs[i].id}`)
      await deleteDoc(ref)
    }
  }

  //빈 도큐먼트 생성하기
  // static createEmptyDocument = async () => {
  //   const ref = collection(db, "model")
  //   const newDoc = doc(ref)
  //   await setDoc()
  // }

  //모델 등록하기
  static craeteModel = async (dto: CreateModelDTO) => {
    const ref = collection(db, "model")
    const layoutRef = doc(db, "layout/G6FOrGtMAT57GCbWseWQ")
    const layout = await getDoc(layoutRef)
    const newDoc = doc(ref)
    const newDocId = newDoc.id

    const mainImagePrefix = "model/" + newDocId + "/mainImage/"
    const comCardImagePrefix = "model/" + newDocId + "/comCardImage/"
    const videoPrefix = "model/" + newDocId + "/videos/"
    const imagePrefix = "model/" + newDocId + "/images/"
    const instagramPrefix = "model/" + newDocId + "/instagram/"

    const newVideos: Array<string> = []
    const newImages: Array<{ url: string; showOnly: boolean }> = []
    const newInstagram: Array<string> = []

    if (layout.exists()) {
      const categoryGroups = layout.data()["categoryGroups"]
      const targetCategoryGroup = categoryGroups.find(
        (categoryGroup: any) => categoryGroup.categoryId === dto.categoryId
      )
      targetCategoryGroup.modelIds.push(newDocId)
      await updateDoc(layoutRef, {
        categoryGroups: categoryGroups,
        updatedAt: serverTimestamp(),
      })
    }

    for (let i = 0; i < dto.videos.length; i++) {
      const videoUrl = await S3Service.uploadFile(dto.videos[i], videoPrefix)
      newVideos.push(videoUrl)
    }

    for (let i = 0; i < dto.images.length; i++) {
      const imageUrl = await S3Service.uploadFile(
        dto.images[i].file,
        imagePrefix
      )
      const newImage = { url: imageUrl, showOnly: dto.images[i].showOnly }
      newImages.push(newImage)
    }

    for (let i = 0; i < dto.instagram.length; i++) {
      const instaUrl = await S3Service.uploadFile(
        dto.instagram[i],
        instagramPrefix
      )
      newInstagram.push(instaUrl)
    }

    const newMainImageUrl = await S3Service.uploadFile(
      dto.mainImageFile,
      mainImagePrefix
    )

    let newComCardImageUrl = ""
    if (dto.comCardImageFile) {
      newComCardImageUrl = await S3Service.uploadFile(
        dto.comCardImageFile,
        comCardImagePrefix
      )
    }

    const result = await setDoc(newDoc, {
      name: dto.name,
      eLastName: dto.eLastName,
      eFirstName: dto.eFirstName,
      birth: dto.birth,
      height: dto.height,
      categoryId: dto.categoryId,
      hashTags: dto.hashTags,
      instagramAddress: dto.instagramAddress,
      mainImageUrl: newMainImageUrl,
      comCardImageUrl: newComCardImageUrl,
      videos: newVideos,
      images: newImages,
      instagram: newInstagram,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    })
    return result
  }

  // 모델 수정하기
  static updateModel = async (dto: UpdateModelDTO) => {
    const ref = doc(db, `model/${dto.id}`)
    const layoutRef = doc(db, "layout/G6FOrGtMAT57GCbWseWQ")
    const layout = await getDoc(layoutRef)

    // 레이아웃 수정
    if (layout.exists()) {
      if (dto.categoryId !== dto.oldCategoryId) {
        const categoryGroups = layout.data()["categoryGroups"]
        const oldCategoryGroup = categoryGroups.find(
          (categoryGroup: any) => categoryGroup.categoryId === dto.oldCategoryId
        )
        if (oldCategoryGroup) {
          const newOldModelIds = oldCategoryGroup.modelIds.filter(
            (modelId: any) => dto.id !== modelId
          )
          oldCategoryGroup.modelIds = newOldModelIds

          const newCategoryGorup = categoryGroups.find(
            (categoryGroup: any) => categoryGroup.categoryId === dto.categoryId
          )
          newCategoryGorup.modelIds.push(dto.id)

          await updateDoc(layoutRef, {
            categoryGroups: categoryGroups,
            updatedAt: serverTimestamp(),
          })
        } else {
          const newCategoryGorup = categoryGroups.find(
            (categoryGroup: any) => categoryGroup.categoryId === dto.categoryId
          )
          newCategoryGorup.modelIds.push(dto.id)

          await updateDoc(layoutRef, {
            categoryGroups: categoryGroups,
            updatedAt: serverTimestamp(),
          })
        }
      }
    }

    const mainImagePrefix = "model/" + dto.id + "/mainImage/"
    const comCardImagePrefix = "model/" + dto.id + "/comCardImage/"
    const videoPrefix = "model/" + dto.id + "/videos/"
    const imagePrefix = "model/" + dto.id + "/images/"
    const instagramPrefix = "model/" + dto.id + "/instagram/"

    // deleteVideo
    const oldModel = await this.getModelById(dto.id)
    const newVideoUrlList = dto.videos.map((video) => video.url)
    oldModel?.videos.forEach(async (oldUrl) => {
      if (!newVideoUrlList.includes(oldUrl)) {
        await S3Service.deleteFile(oldUrl.split("amazonaws.com/")[1])
      }
    })

    // deleteImage
    const newImageUrlList = dto.images.map((image) => image.url)
    oldModel?.images.forEach(async (oldImage) => {
      if (!newImageUrlList.includes(oldImage.url)) {
        await S3Service.deleteFile(oldImage.url.split("amazonaws.com/")[1])
      }
    })

    // deleteInstagramImage
    const newInstagamImageUrlList = dto.instagram.map(
      (instagramImage) => instagramImage.url
    )
    oldModel?.instagram.forEach(async (oldUrl) => {
      if (!newInstagamImageUrlList.includes(oldUrl)) {
        await S3Service.deleteFile(oldUrl.split("amazonaws.com/")[1])
      }
    })

    const batch = writeBatch(db)
    batch.update(ref, { videos: [] })
    batch.update(ref, { images: [] })
    batch.update(ref, { instagram: [] })

    // createVideo
    for (let i = 0; i < dto.videos.length; i++) {
      if (dto.videos[i].file.name !== "existFile") {
        const videoUrl = await S3Service.uploadFile(
          dto.videos[i].file,
          videoPrefix
        )
        batch.update(ref, { videos: arrayUnion(videoUrl) })
      } else {
        batch.update(ref, { videos: arrayUnion(dto.videos[i].url) })
      }
    }

    // createImage

    for (let i = 0; i < dto.images.length; i++) {
      if (dto.images[i].file.name !== "existFile") {
        const imageUrl = await S3Service.uploadFile(
          dto.images[i].file,
          imagePrefix
        )
        batch.update(ref, {
          images: arrayUnion({
            url: imageUrl,
            showOnly: dto.images[i].showOnly,
          }),
        })
      } else {
        batch.update(ref, {
          images: arrayUnion({
            url: dto.images[i].url,
            showOnly: dto.images[i].showOnly,
          }),
        })
      }
    }

    // createInstagramImage
    for (let i = 0; i < dto.instagram.length; i++) {
      if (dto.instagram[i].file.name !== "existFile") {
        const instagramImageUrl = await S3Service.uploadFile(
          dto.instagram[i].file,
          instagramPrefix
        )
        batch.update(ref, { instagram: arrayUnion(instagramImageUrl) })
      } else {
        batch.update(ref, { instagram: arrayUnion(dto.instagram[i].url) })
      }
    }

    let newMainImageUrl = dto.mainImageUrl
    if (oldModel !== undefined) {
      if (oldModel.mainImageUrl !== newMainImageUrl) {
        newMainImageUrl = await S3Service.uploadFile(
          dto.mainImageFile,
          mainImagePrefix
        )
        await S3Service.deleteFile(
          oldModel.mainImageUrl.split("amazonaws.com/")[1]
        )
      }
    }

    let newComCardImageUrl = dto.comCardImageUrl
    console.log(newComCardImageUrl)
    if (oldModel !== undefined) {
      if (oldModel.comCardImageUrl === "") {
        if (newComCardImageUrl !== "") {
          if (dto.comCardImageFile) {
            newComCardImageUrl = await S3Service.uploadFile(
              dto.comCardImageFile,
              comCardImagePrefix
            )
          }
        }
      } else {
        if (newComCardImageUrl !== "") {
          if (oldModel.comCardImageUrl !== newComCardImageUrl) {
            if (dto.comCardImageFile) {
              newComCardImageUrl = await S3Service.uploadFile(
                dto.comCardImageFile,
                comCardImagePrefix
              )
            }
            await S3Service.deleteFile(
              oldModel.comCardImageUrl.split("amazonaws.com/")[1]
            )
          }
        } else {
          await S3Service.deleteFile(
            oldModel.comCardImageUrl.split("amazonaws.com/")[1]
          )
        }
      }
    }

    batch.update(ref, {
      name: dto.name,
      eLastName: dto.eLastName,
      eFirstName: dto.eFirstName,
      birth: dto.birth,
      height: dto.height,
      categoryId: dto.categoryId,
      hashTags: dto.hashTags,
      instagramAddress: dto.instagramAddress,
      mainImageUrl: newMainImageUrl,
      comCardImageUrl: newComCardImageUrl,
      updatedAt: serverTimestamp(),
    })

    await batch.commit()
  }

  //모델 삭제하기
  static deleteModels = async (deleteModels: Array<AdminModel>) => {
    const batch = writeBatch(db)
    const layoutRef = doc(db, "layout/G6FOrGtMAT57GCbWseWQ")
    const layout = await getDoc(layoutRef)

    deleteModels.forEach(async (model) => {
      const targetMainImageUrl = model.mainImageUrl.split("amazonaws.com/")[1]
      await S3Service.deleteFile(targetMainImageUrl)
      const comCardImageUrl = model.comCardImageUrl
      if (comCardImageUrl !== "") {
        const targetComCardImageUrl = comCardImageUrl.split("amazonaws.com/")[1]
        await S3Service.deleteFile(targetComCardImageUrl)
      }
      model.videos.forEach(async (videoUrl) => {
        const targetUrl = videoUrl.split("amazonaws.com/")[1]
        await S3Service.deleteFile(targetUrl)
      })
      model.images.forEach(async (image) => {
        console.log(image)
        const targetUrl = image.url.split("amazonaws.com/")[1]
        await S3Service.deleteFile(targetUrl)
      })
      model.instagram.forEach(async (instagramUrl) => {
        const targetUrl = instagramUrl.split("amazonaws.com/")[1]
        await S3Service.deleteFile(targetUrl)
      })
      // 레이아웃에서 제거
      if (layout.exists()) {
        const categoryGroups = layout.data()["categoryGroups"]
        const oldCategoryGroup = categoryGroups.find(
          (categoryGroup: any) => categoryGroup.categoryId === model.categoryId
        )
        const newOldModelIds = oldCategoryGroup.modelIds.filter(
          (modelId: any) => modelId !== model.id
        )

        oldCategoryGroup.modelIds = newOldModelIds
        await updateDoc(layoutRef, {
          categoryGroups: categoryGroups,
          updatedAt: serverTimestamp(),
        })
      }
    })

    deleteModels.forEach((model) => {
      const ref = doc(db, `model/${model.id}`)
      batch.delete(ref)
    })

    // 업데이트 삭제처리
    const updateRef = collection(db, "updated")
    const updatedData = await getDocs(updateRef)
    let index = 0
    for (let i = 0; i < updatedData.docs.length; i++) {
      const updateId = updatedData.docs[i].id
      const updateModelId = updatedData.docs[i].data()["modelId"]
      const targetRef = doc(db, `updated/${updateId}`)
      if (deleteModels.map((m) => m.id).includes(updateModelId)) {
        const deleteUrl = updatedData.docs[i]
          .data()
          ["imageUrl"].split("amazonaws.com/")[1]
        await S3Service.deleteFile(deleteUrl)
        await deleteDoc(targetRef)
      } else {
        await updateDoc(targetRef, {
          index: index,
          updatedAt: serverTimestamp(),
        })
        index++
      }
    }

    await batch.commit()
  }

  //Admin 모델 리스트 불러오기
  static adminGetAllModel = async () => {
    const modelRef = collection(db, "model")
    const modelQ = query(modelRef, orderBy("createdAt", "desc"))
    const categoryRef = collection(db, "category")
    const hashTagRef = collection(db, "hashtag")

    const models = await getDocs(modelQ)
    const categories = await getDocs(categoryRef)
    const hashTags = await getDocs(hashTagRef)

    let modelList: AdminModel[] = []
    let categoryList: Category[] = []
    let hashTagList: HashTag[] = []

    categories.forEach((category) => {
      categoryList.push({
        id: category.id,
        showYn: category.data()["showYn"],
        index: category.data()["index"],
        name: category.data()["name"],
        createdAt: category.data()["createdAt"],
        updatedAt: category.data()["updatedAt"],
      })
    })

    hashTags.forEach((hashTag) => {
      hashTagList.push({
        id: hashTag.id,
        active: hashTag.data()["active"],
        tagName: hashTag.data()["tagName"],
        createdAt: hashTag.data()["createdAt"],
        updatedAt: hashTag.data()["updatedAt"],
      })
    })

    models.forEach((model) => {
      const category = categoryList.find(
        (category) => model.data()["categoryId"] === category.id
      )
      modelList.push({
        checked: false,
        id: model.id,
        height: model.data()["height"],
        birth: model.data()["birth"],
        categoryName: category !== undefined ? category.name : "",
        categoryId: category !== undefined ? category.id : "",
        eFirstName: model.data()["eFirstName"],
        eLastName: model.data()["eLastName"],
        hashTag: hashTagList
          .filter((hashTag) => model.data()["hashTags"].includes(hashTag.id))
          .map((hashTag2) => `${hashTag2.tagName}`),
        images: model.data()["images"],
        instagram: model.data()["instagram"],
        instagramAddress: model.data()["instagramAddress"],
        mainImageUrl: model.data()["mainImageUrl"],
        comCardImageUrl: model.data()["comCardImageUrl"],
        name: model.data()["name"],
        videos: model.data()["videos"],
        createdAt: moment(model.data()["createdAt"].toDate()).format("yyyy."),
        updatedAt: moment(model.data()["updatedAt"].toDate()).format("yyyy."),
      })
    })
    return modelList
  }
}
