import { DefaultTheme } from "styled-components";

const colors = {
    background: "#000000",
    white: "#FFFFFF",
    black: "#000000"
};

const fontSize = {
    title: 20,
    subTitle: 16,
    text: 14,
};

export type ColorsTypes = typeof colors;
export type FontSizeTypes = typeof fontSize;

const theme: DefaultTheme = {
    colors,
    fontSize,
};

export default theme;