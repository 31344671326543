import React from "react";
import styled from "styled-components";

interface TitleProps{
    children?: React.ReactNode
}

const S = {
    Title: styled.div`
      font-family: Jura, sans-serif;
      font-weight: 500;
      font-size: 36px;
      letter-spacing: 1px;
      display: inline-block;
      transform-origin: 0 0;
      color: #FFFFFF;
    `
}

const Title:React.FC<TitleProps> = ({children}) => {
    return (
        <S.Title>
            {children}
        </S.Title>
    )
}

export default Title