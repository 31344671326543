import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  orderBy,
  doc,
  updateDoc,
  writeBatch,
  setDoc,
  getDoc,
} from "firebase/firestore/lite"
import { db } from "../firebase"
import moment from "moment/moment"
import { Category } from "../models/interfaces/Category"
import { UpdateCategoryDTO } from "../models/interfaces/dto/UpdateCategoryDTO"
import { CreateCategoryDTO } from "../models/interfaces/dto/CreateCategoryDTO"

export class CategoryService {
  //모든 카테고리 가져오기
  static getAllCategory = async () => {
    const ref = collection(db, "category")
    const q = query(ref, orderBy("index", "asc"))
    const categories = await getDocs(q)
    let categoryList: Category[] = []
    categories.forEach((category) => {
      categoryList.push({
        id: category.id,
        index: category.data()["index"],
        name: category.data()["name"],
        showYn: category.data()["showYn"],
        createdAt: moment(category.data()["createdAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
        updatedAt: moment(category.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      })
    })
    return categoryList
  }

  //더미 카테고리 생성
  static createDummyCategory = async () => {
    const ref = collection(db, "category")
    for (let i = 0; i < 4; i++) {
      await addDoc(ref, {
        index: i,
        name: `카테고리${i}`,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })
    }
  }

  //카테고리 생성
  static createCategory = async (dto: CreateCategoryDTO) => {
    const ref = collection(db, "category")
    const newCategoryDoc = doc(ref)

    const layoutRef = doc(db, "layout/G6FOrGtMAT57GCbWseWQ")
    const layout = await getDoc(layoutRef)

    if (layout.exists()) {
      const categoryGroups = layout.data()["categoryGroups"]
      categoryGroups.push({
        categoryId: newCategoryDoc.id,
        modelIds: [],
      })
      await updateDoc(layoutRef, {
        categoryGroups: categoryGroups,
        updatedAt: serverTimestamp(),
      })
    }

    const result = await setDoc(newCategoryDoc, {
      index: dto.index,
      name: dto.name,
      showYn: false,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    })
    return result
  }

  //카테고리 업데이트
  static updateCategory = async (docId: string, dto: UpdateCategoryDTO) => {
    const ref = doc(db, `category/${docId}`)
    await updateDoc(ref, {
      name: dto.name,
      showYn: dto.showYn,
      updatedAt: serverTimestamp(),
    })
  }
  //카테고리 삭제
  static deleteCategory = () => {}

  //카테고리 삭제 다중
  static deleteCategoryList = async (docIdList: Array<string>) => {
    const batch = writeBatch(db)
    const layoutRef = doc(db, "layout/G6FOrGtMAT57GCbWseWQ")
    const layout = await getDoc(layoutRef)

    for (let i = 0; i < docIdList.length; i++) {
      if (layout.exists()) {
        let categoryGroups = layout.data()["categoryGroups"]
        categoryGroups = categoryGroups.filter(
          (category: any) => category.categoryId !== docIdList[i]
        )
        await updateDoc(layoutRef, {
          categoryGroups: categoryGroups,
          updatedAt: serverTimestamp(),
        })
      }
      const ref = doc(db, `category/${docIdList[i]}`)
      batch.delete(ref)
    }

    await batch.commit()
  }
}
