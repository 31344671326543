import styled from "styled-components";
import React from "react";

interface BackgroundInterface {
  children?: React.ReactNode;
}

const S = {
  Background: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 180px;

    justify-content: flex-start;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    max-width: 100vw;
    min-height: 100vh;
    font-size: 20px;
  `,
};

const Background: React.FC<BackgroundInterface> = ({ children }) => {
  return <S.Background>{children}</S.Background>;
};

export default Background;
