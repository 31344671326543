import React from "react";


interface SpacerProps {
    width?: number
    height?: number
}


const Spacer:React.FC<SpacerProps> = ({width, height}) => {
    return (
        <div style={{width, height}}/>
    )
}

export default Spacer