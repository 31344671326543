import React from "react";
import styled from "styled-components";

interface LogoInterface {
    width?:string
    height?:string
    onClick?: () => void
}

const S = {
    Logo: styled.svg`
        cursor: pointer;
    `
}

const Logo:React.FC<LogoInterface> = ({width, height, onClick}) => {
    return (
        <S.Logo onClick={onClick} width={width} height={height} viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8266 1.17821L25.2738 31.1557C25.4239 31.5854 25.8743 31.8313 26.3144 31.7252L37.5977 29.0305C38.1283 28.9037 38.4182 28.3316 38.2086 27.8269L27.7407 2.84779C27.6268 2.57859 27.3886 2.38186 27.1013 2.32233L15.8387 0.0185603C15.1657 -0.11863 14.5988 0.528492 14.8266 1.17562V1.17821Z" fill="white"/>
            <path d="M3.48606 35.8069L17.4562 33.252C17.6322 33.221 17.7953 33.1356 17.9221 33.0087L22.3045 28.6497C22.5478 28.4064 22.6281 28.0466 22.5116 27.723L19.2682 18.7253C19.0456 18.1067 18.2483 17.9384 17.7953 18.4173L2.68619 34.3289C2.09601 34.9501 2.6448 35.9622 3.48606 35.8095V35.8069Z" fill="white"/>
            <path d="M45.0035 10.7715L33.3164 9.17953C32.6382 9.08634 32.1179 9.76453 32.3794 10.3961L35.2293 17.2583C35.4364 17.7552 36.0369 17.9546 36.5003 17.6776L45.3374 12.4048C46.057 11.9751 45.8318 10.8802 45.0035 10.7689V10.7715Z" fill="white"/>
            <path d="M11.0039 38.0723H18.2466L17.835 40.0059H13.2844L13.0799 40.9507H17.351L16.9679 42.7549H12.6968L12.5001 43.6893H17.1827L16.7711 45.6333H9.39648L11.0039 38.0723Z" fill="white"/>
            <path d="M23.5479 44.6341H20.5375L19.9111 45.6333H17.1543L22.0052 38.0723H24.8939L26.4833 45.6333H23.7265L23.5479 44.6341ZM21.7437 42.7264H23.2114L22.9034 40.9403H22.8749L21.7437 42.7264Z" fill="white"/>
            <path d="M28.4636 38.0723H32.688C35.5302 38.0723 36.1463 39.9334 35.7347 41.8385C35.2403 44.1656 33.5371 45.6333 30.5655 45.6333H26.8535L28.461 38.0723H28.4636ZM29.8924 43.7282H30.8165C32.4421 43.7282 32.9572 42.5141 33.1074 41.8127C33.2109 41.3364 33.3403 39.98 31.4248 39.98H30.6871L29.8924 43.7282Z" fill="white"/>
            <path d="M9.26499 40.5313C9.49796 39.4286 8.98543 38.0723 6.41505 38.0723H1.836L0.228516 45.6333H2.92057L3.46156 42.7238H6.99228C7.20713 42.7238 7.41939 42.6798 7.61353 42.5866C7.62388 42.5814 7.63682 42.5763 7.64459 42.5711C8.48068 42.131 9.06309 41.4762 9.26499 40.5288V40.5313ZM6.59108 40.6142C6.43318 41.3519 5.7032 41.3053 4.94476 41.3053H3.83173L4.132 39.9127H5.40293C6.13289 39.9127 6.73086 39.9593 6.59108 40.6142Z" fill="white"/>
            <path d="M7.52055 43.3375L8.31521 45.2634C8.38769 45.4394 8.25827 45.6361 8.06673 45.6361H6.08651C5.9778 45.6361 5.87944 45.5714 5.83803 45.4705L4.89062 43.1719H7.27207C7.38079 43.1719 7.47914 43.2366 7.52055 43.3375Z" fill="white"/>
            <path d="M37.9694 43.3375L37.1747 45.2634C37.1022 45.4394 37.2317 45.6361 37.4232 45.6361H39.4034C39.5122 45.6361 39.6105 45.5714 39.6519 45.4705L40.5993 43.1719H38.2179C38.1092 43.1719 38.0108 43.2366 37.9694 43.3375Z" fill="white"/>
            <path d="M39.8503 40.8006H39.8322L38.9624 38.0723H35.9805L37.9969 42.6073C38.028 42.6798 38.0979 42.7238 38.1755 42.7238H40.6605C40.7175 42.7238 40.7718 42.6979 40.808 42.6565L44.9082 38.0723H41.9366L39.8529 40.8006H39.8503Z" fill="white"/>
            <path d="M16.8446 50.0003H16.5806L16.7385 49.4075C16.7773 49.2677 16.6841 49.2263 16.534 49.2263H16.2725L16.0655 50.0003H15.7988L16.2907 48.165H16.93C17.1863 48.165 17.2924 48.2349 17.2251 48.4938L17.1371 48.8199C17.0983 48.9701 16.9714 49.0969 16.7773 49.1073C16.9688 49.115 17.0439 49.2496 17.0025 49.4049L16.8446 49.9977V50.0003ZM16.9378 48.5973C16.9766 48.4575 16.9429 48.3902 16.7928 48.3902H16.4977L16.3346 49.0037H16.6116C16.7566 49.0037 16.8446 48.9468 16.8834 48.7966L16.9378 48.5973Z" fill="white"/>
            <path d="M18.1087 50.0003L18.6005 48.165H19.5272L19.4651 48.3928H18.8024L18.6523 48.9494H19.2942L19.2347 49.172H18.5927L18.4323 49.7725H19.113L19.0509 50.0003H18.1035H18.1087Z" fill="white"/>
            <path d="M20.5923 49.6143L20.4111 49.9974H20.1445L21.0194 48.1621H21.4362L21.3301 49.9974H21.0608L21.0842 49.6143H20.5923ZM21.1696 48.3821L20.6933 49.3916H21.1023L21.167 48.3821H21.1696Z" fill="white"/>
            <path d="M23.6578 49.6224C23.5879 49.8838 23.43 50.0003 23.1737 50.0003H22.5137L23.0055 48.165H23.6655C23.9218 48.165 24.0176 48.2815 23.9477 48.543L23.6578 49.6224ZM23.6681 48.5973C23.707 48.4549 23.6733 48.3928 23.5232 48.3928H23.21L22.8398 49.7725H23.153C23.3032 49.7725 23.3705 49.7104 23.4093 49.5654L23.6681 48.5947V48.5973Z" fill="white"/>
            <path d="M25.1917 48.165H25.4557L25.5333 49.0141L26.0484 48.165H26.3254L25.5825 49.3092L25.3987 50.0003H25.127L25.3133 49.3066L25.1891 48.165H25.1917Z" fill="white"/>
            <path d="M29.3013 48.165L29.4307 48.7811L29.8863 48.165H30.1632L29.4954 49.0607L29.6999 50.0003H29.4203L29.2805 49.3402L28.7939 50.0003H28.5195L29.2184 49.0607L29.0243 48.165H29.3039H29.3013Z" fill="white"/>
        </S.Logo>

    )
}

export default Logo