import { initializeApp } from 'firebase/app'
import { getFunctions } from 'firebase/functions'
import { getFirestore } from 'firebase/firestore/lite'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyC6kwm3Z6uUZ-i8LpeKd_BlisZJE_4uKUs",
    authDomain: "readymodel-prod.firebaseapp.com",
    projectId: "readymodel-prod",
    storageBucket: "readymodel-prod.appspot.com",
    messagingSenderId: "764222643475",
    appId: "1:764222643475:web:f08fc14288cec65959cee6",
    measurementId: "G-4HBH3CTXZR"
};



// firebaseConfig 정보로 firebase 시작
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);
// firebase의 firestore 인스턴스를 변수에 저장
const db = getFirestore(app)
const functions = getFunctions(app);

export {db, functions}