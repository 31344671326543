import Background from "../../components/Background";
import DesktopHeader from "../../components/header/Header";
import Spacer from "../../components/Spacer";
import React, { Fragment, useContext, useEffect } from "react";
import { StoreContext } from "../../store/store.context";
import Title from "../../components/texts/Title";
import { observer } from "mobx-react";
import SearchInput from "../../components/inputs/SearchInput";
import HashTag from "../../components/tags/HashTag";
import styled from "styled-components";
import ModelCard from "../../components/cards/ModelCard";
import uuid from "react-uuid";
import ImageModal from "../../components/modals/ImageModal";
import FooterDesktop from "../../components/footer/Footer";
import MenuButton from "../../components/buttons/MenuButton";
import { useNavigate } from "react-router-dom";
import AddtionalMenu from "../../components/menus/AddtionalMenu";
import UpdatedModelCard from "../../components/cards/UpdatedModelCard";
import Desktop from "../../components/responsive/desktop";
import Mobile from "../../components/responsive/mobile";
import { autorun } from "mobx";
import { InfinitySpin } from "react-loader-spinner";
import { history } from "../../history";

const S = {
  TagContainer: styled.div`
    display: flex;
    white-space: nowrap;
    width: 100%;
    max-width: 1156px;
    height: 24px;
    max-height: 24px;
    overflow-x: scroll;
    overflow-y: unset;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1156px;
    width: 100%;
    @media only screen and (max-width: 764px) {
      width: calc(100% - 40px);
    }
  `,
  ModelGrid: styled.div`
    padding-top: 52px;
    width: 100%;
    max-width: 1156px;
    display: grid;
    grid-template-columns: repeat(4, 0.5fr);
    grid-gap: 20px;

    @media only screen and (min-width: 765px) and (max-width: 1064px) {
      grid-template-columns: repeat(3, 0.5fr);
      max-width: 90vw;
    }
    @media only screen and (max-width: 764px) {
      grid-template-columns: repeat(2, 0.5fr);
      max-width: 90vw;
    }
  `,
  UpdatedModelFlexBox: styled.div`
    max-width: 1156px;
    padding-top: 52px;
    width: 90vw;
    display: flex;
    justify-content: space-between;
    gap: 40px;
  `,
  ButtonContainer: styled.div`
    padding-top: 10vw;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Column: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  ContentContainer: styled.div`
    max-width: 1156px;
    width: 100%;
    @media only screen and (max-width: 764px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  `,
  SpinnerContainer: styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
  `,
};

const Home = () => {
  const navigate = useNavigate();

  const { globalStore } = useContext(StoreContext);
  const {
    isLoading,
    selectedCategory,
    modelImageModalIsOpen,
    openImageModal,
    closeImageModal,
    changeMenu,
    getModelsDataByAllTag,
    getModelsDataByHashTag,
    setModalImageUrl,
    setModalModel,
    onChangeSearchInput,
    setSearchInput,
    addFavoriteModel,
    removeFavoriteModel,
    setSelectedModel,
    setModelPortfolioCurrentImageUrl,
    getHomeData,
    favoriteIds,
    favorites,
    searchInput,
    models,
    updatedModels,
    hashTags,
    modalImageUrl,
    categories,
    showAllHashtag,
    getModelsDataByCategory,
  } = globalStore;

  useEffect(() => {
    localStorage.removeItem("before");

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === "POP") {
        const before = localStorage.getItem("before");
        switch (before) {
          case "SELECTED":
            changeMenu("SELECTED");
            break;
          case "UPDATED":
            changeMenu("UPDATED");
            break;
          default:
            if (before) {
              changeMenu(before);
              const categoryId = categories.find(category => category.name === before)!.id;
              getModelsDataByCategory(categoryId)
            }
            break;
        }
      }
    });

    return unlistenHistoryEvent;
  }, []);

  useEffect(() => {
    autorun(async () => {
      await getHomeData();
    });
  }, [getHomeData]);

  const buildSectionByCategory = (selectedCategory: string) => {
    switch (selectedCategory) {
      case "SELECTED":
        return (
          <S.ContentContainer>
            <AddtionalMenu
              sendEmailOnClick={() => {
                changeMenu("CONTACT");
                navigate("/contact");
              }}
              models={favorites}
            />
            <S.ModelGrid>
              {favorites.map((model) => (
                <ModelCard
                  key={uuid()}
                  modelData={model}
                  addOnClick={() => {
                    addFavoriteModel(model);
                  }}
                  minusOnClick={() => removeFavoriteModel(model.id)}
                  imageOnClick={() => {
                    setSelectedModel(model);
                    navigate("/model", {
                      state: model.id,
                    });
                    setModelPortfolioCurrentImageUrl(
                      model.images.map((image) => image.url)
                    );
                  }}
                  added={favoriteIds.includes(model.id)}
                />
              ))}
            </S.ModelGrid>
          </S.ContentContainer>
        );
      case "UPDATED":
        return (
          <S.ContentContainer key={uuid()}>
            <S.UpdatedModelFlexBox>
              <Desktop>
                {Array.from(Array(3).keys()).map((colNum, colIndex) => {
                  return (
                    <S.Column key={uuid()}>
                      {updatedModels.map((updatedModel, i) => {
                        if (i % 3 === colNum) {
                          return (
                            <UpdatedModelCard
                              key={uuid()}
                              updatedModel={updatedModel}
                              imageOnClick={() => {
                                setModalImageUrl(updatedModel.imageUrl);
                                setModalModel(updatedModel);
                                openImageModal();
                                // const model = models.find((item) => updatedModel.model!.id === item.id)
                                // if(model){
                                // setSelectedModel(model)
                                // navigate('/model', {
                                // state: model.id
                                // })
                                // setModelPortfolioCurrentImageUrl(
                                // model.images.map((image) => image.url)
                                // )
                                // }
                              }}
                            />
                          );
                        }
                        return <Fragment key={uuid()} />;
                      })}
                    </S.Column>
                  );
                })}
              </Desktop>
              <Mobile>
                {Array.from(Array(2).keys()).map((colNum, colIndex) => {
                  return (
                    <S.Column key={uuid()}>
                      {updatedModels.map((updatedModel, i) => {
                        if (i % 2 === colNum) {
                          return (
                            <UpdatedModelCard
                              key={uuid()}
                              updatedModel={updatedModel}
                              imageOnClick={() => {
                                setModalImageUrl(updatedModel.imageUrl);
                                openImageModal();
                                const model = models.find(
                                  (item) => updatedModel.model?.id === item.id
                                );
                                if (model) {
                                  setModalModel(updatedModel);
                                }
                              }}
                            />
                          );
                        }
                        return <Fragment key={uuid()} />;
                      })}
                    </S.Column>
                  );
                })}
              </Mobile>
            </S.UpdatedModelFlexBox>
          </S.ContentContainer>
        );
      default:
        return (
          <>
            <SearchInput onChange={onChangeSearchInput} value={searchInput} />
            <Spacer height={8} />
            <S.TagContainer>
              {showAllHashtag && (
                <>
                  <HashTag
                    onClick={async () => {
                      const c = categories.find(
                        (category) => category.name === selectedCategory
                      );
                      if (c) {
                        await getModelsDataByAllTag(c.id);
                      }
                    }}
                    text={"전체"}
                  />
                  {hashTags
                    .filter((tag) => tag.active === true)
                    .map((tag, i) => (
                      <HashTag
                        onClick={async () => {
                          await getModelsDataByHashTag(tag.id);
                          setSearchInput("");
                          window.scrollTo(0, 0);
                        }}
                        key={uuid()}
                        text={`#${tag.tagName}`}
                      />
                    ))}
                </>
              )}
            </S.TagContainer>
            <S.ModelGrid>
           

              {/* 여기!! */}
              {models.length !== 0 ? (
                <>
                  {models
                  .filter((model) => {
                    if (model.name.includes(searchInput)) return model;
                    if (
                      (model.eFirstName + model.eLastName)
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    )
                      return model;
                    return false;
                  })
                  .map((model) => {
                    return (
                      <ModelCard
                        key={uuid()}
                        modelData={model}
                        addOnClick={() => addFavoriteModel(model)}
                        minusOnClick={() => removeFavoriteModel(model.id)}
                        imageOnClick={() => {
                          setSelectedModel(model);
                          navigate("/model", {
                            state: model.id,
                          });
                          setModelPortfolioCurrentImageUrl(
                            model.images.map((image) => image.url)
                          );
                        }}
                        added={favoriteIds.includes(model.id)}
                      />
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </S.ModelGrid>
          </>
        );
    }
  };

  return (
    <>
      {isLoading && (
        <Background>
          <S.SpinnerContainer>
            <InfinitySpin width="200" color="#fff" />
          </S.SpinnerContainer>
        </Background>
      )}

      {!isLoading && (
        <>
          <ImageModal
            imgUrl={modalImageUrl}
            isOpen={modelImageModalIsOpen}
            onRequestClose={closeImageModal}
          />
          <DesktopHeader />
          <Background>
            <S.Container>
              <Spacer height={60} />
              <Title>
                {selectedCategory === "SELECTED"
                  ? `${selectedCategory} ( ${favorites.length} )`
                  : selectedCategory}
              </Title>
              <Spacer height={60} />
              {buildSectionByCategory(selectedCategory)}
              <S.ButtonContainer>
                <MenuButton
                  onClick={() => {
                    changeMenu("ABOUT US");
                    navigate("/about");
                    localStorage.setItem("before", selectedCategory);
                  }}
                  label={"ABOUT US"}
                />
                <Spacer height={32} />
                <MenuButton
                  onClick={() => {
                    changeMenu("CONTACT");
                    navigate("/contact");
                    localStorage.setItem("before", selectedCategory);
                  }}
                  label={"CONTACT"}
                />
              </S.ButtonContainer>
              <Spacer height={60} />
            </S.Container>
          </Background>
          <FooterDesktop />
        </>
      )}
    </>
  );
};

export default observer(Home);
