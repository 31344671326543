import {
    doc,
    getDoc,
    serverTimestamp,
    writeBatch,
    collection,
    query,
    orderBy, getDocs
} from "firebase/firestore/lite";
import {db} from "../firebase";
import {Agreement} from "../models/interfaces/Agreement";
import moment from "moment/moment";
import {AgreementHistory} from "../models/interfaces/AgreementHistory";

export class AgreementService {

    static getAgreement = async () => {
        const ref = doc(db, 'agreement/MkurPyXwBLE9lZZYZ5ZL')
        const document = await getDoc(ref)
        const agreement: Agreement = {
            id: document.id,
            content: document.data()!['content'],
            createdAt: moment(document.data()!['createdAt'].toDate()).format('YYYY.MM.DD')
        };


        return agreement
    }

    static updateAgreement = async (agreement: Agreement) => {
        const batch = writeBatch(db)
        const historyRef = collection(db, 'history')
        const newHistoryDocRef = doc(historyRef)
        const ref = doc(db, 'agreement/MkurPyXwBLE9lZZYZ5ZL')

        batch.update(ref, {
            content: agreement.content,
            createdAt: serverTimestamp()
        })
        batch.set(newHistoryDocRef, {
            content: agreement.content,
            createdAt: serverTimestamp()
        })
        await batch.commit();
    }

    static getAgreementHistory = async () => {
        const ref = collection(db, 'history')
        const q = query(ref, orderBy("createdAt", "desc"))
        const docs = await getDocs(q)
        let histories: AgreementHistory[] = []
        docs.forEach((doc) => {
            histories.push({
                id: doc.id,
                content: doc.data()['content'],
                createdAt: moment(doc.data()!['createdAt'].toDate()).format('YYYY.MM.DD')
            })
        })
        return histories

    }
}