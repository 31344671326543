import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import AdminInfoInput from "../../../../components/inputs/AdminInfoInput"
import Spacer from "../../../../components/Spacer"
import { StoreContext } from "../../../../store/store.context"
import { autorun } from "mobx"
import { observer } from "mobx-react"

const S = {
  Container: styled.div`
    height: inherit;
    padding: 0px 36px;
    width: 100%;
    background-color: #f7f9fb;
    overflow-y: auto;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-top: 36px;
  `,
  ControllBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
  `,
  Title: styled.text`
    display: flex;
    width: auto;
    height: 36px;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f4a;
  `,
  ButtonBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  `,
  LastUpdateDiv: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    width: 372px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #7f828c;
  `,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  NationDiv: styled.div`
    display: flex;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
  `,
  InputSubContainer: styled.div`
    gap: 8px;
  `,
  InputLabel: styled.div`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #5a5e6a;
    margin-bottom: 8px;
  `,

  SaveButton: styled.button`
    width: 372px;
    height: 48px;
    padding: 12px 133px;
    background: #00baff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    line-height: 150%;
    text-align: center;
    color: #ffffff;

    :hover {
      background: #0094ca;
    }
  `,
}

const AdminManageCompany = () => {
  const { adminStore } = useContext(StoreContext)
  const {
    companyInfo,
    onChangeCompanyInfoCEmail,
    onChangeCompanyInfoCTel,
    onChangeCompanyInfoKAddress,
    onChangeCompanyInfoKEmail,
    onChangeCompanyInfoKTel,
    onChangeCompanyInfoCAddress,
    getCompanyInfoData,
    onSaveCompanyInfo,
  } = adminStore

  useEffect(() => {
    autorun(async () => {
      await getCompanyInfoData()
    })
  }, [])

  return (
    <S.Container>
      <S.TitleBox>
        <S.Title>회사 정보 관리</S.Title>
      </S.TitleBox>

      <Spacer height={23.5} />

      <S.LastUpdateDiv>마지막 수정일 : {companyInfo.updatedAt}</S.LastUpdateDiv>

      <Spacer height={13.5} />

      <S.InputContainer>
        <S.NationDiv>한국</S.NationDiv>
        <S.InputSubContainer>
          <S.InputLabel>주소</S.InputLabel>
          <AdminInfoInput
            onChange={onChangeCompanyInfoKAddress}
            value={companyInfo.kAddress}
            width={"372px"}
            placeholder="주소를 입력하세요."
          />
        </S.InputSubContainer>
        <S.InputSubContainer>
          <S.InputLabel>전화번호</S.InputLabel>
          <AdminInfoInput
            onChange={onChangeCompanyInfoKTel}
            value={companyInfo.kTel}
            width={"372px"}
            placeholder="전화번호를 입력하세요."
          />
        </S.InputSubContainer>
        <S.InputSubContainer>
          <S.InputLabel>이메일</S.InputLabel>
          <AdminInfoInput
            onChange={onChangeCompanyInfoKEmail}
            value={companyInfo.kEmail}
            width={"372px"}
            placeholder="이메일을 입력하세요."
          />
        </S.InputSubContainer>
      </S.InputContainer>

      <Spacer height={20} />

      <S.InputContainer>
        <S.NationDiv>중국</S.NationDiv>
        <S.InputSubContainer>
          <S.InputLabel>주소</S.InputLabel>
          <AdminInfoInput
            onChange={onChangeCompanyInfoCAddress}
            value={companyInfo.cAddress}
            width={"372px"}
            placeholder="주소를 입력하세요."
          />
        </S.InputSubContainer>
        <S.InputSubContainer>
          <S.InputLabel>전화번호</S.InputLabel>
          <AdminInfoInput
            onChange={onChangeCompanyInfoCTel}
            value={companyInfo.cTel}
            width={"372px"}
            placeholder="전화번호를 입력하세요."
          />
        </S.InputSubContainer>
        <S.InputSubContainer>
          <S.InputLabel>이메일</S.InputLabel>
          <AdminInfoInput
            onChange={onChangeCompanyInfoCEmail}
            value={companyInfo.cEmail}
            width={"372px"}
            placeholder="이메일을 입력하세요."
          />
        </S.InputSubContainer>
      </S.InputContainer>

      <Spacer height={32} />

      <S.SaveButton onClick={onSaveCompanyInfo}>저장하기</S.SaveButton>

      <Spacer height={107} />
    </S.Container>
  )
}

export default observer(AdminManageCompany)
