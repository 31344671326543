import React, { useEffect } from "react"
import styled from "styled-components"

interface AdminSwitchProps {
  isTrue: boolean
  onChange: () => void
}
const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 36px;
  height: 16px;
  /* background: #b3b3b3; */
  border: 1px solid #bec1c7;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
    /* border: 1px solid #bec1c7; */
    background-color: #bec1c7;
  }
`

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    /* background: green; */
    border-color: #226bef;

    &:before {
      transform: translate(21px, -50%);
      background-color: #226bef;
    }
  }
`

const AdminSwitch: React.FC<AdminSwitchProps> = ({ isTrue, onChange }) => {
  const onToggle = () => {
    onChange()
  }

  return (
    <>
      <Label>
        <Input checked={isTrue} type="checkbox" onChange={onToggle} />
        <Switch />
      </Label>
    </>
  )
}

export default AdminSwitch
