
import {
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore/lite"
import { toJS } from "mobx"
import moment from "moment"
import { db } from "../firebase"
import { Layout } from "../models/interfaces/Layout"
import { Model } from "../models/interfaces/Model"
import { ModelService } from "./ModelService"

export class LayoutService {
  // 레이아웃 가져오기
  static getLayout = async () => {
    const ref = doc(db, "layout/G6FOrGtMAT57GCbWseWQ")
    const layout = await getDoc(ref)

    if (layout.exists()) {
      const newGroups = []
      for (let i = 0; i < layout.data()["categoryGroups"].length; i++) {
        const group: { categoryId: string; models: Array<Model> } = {
          categoryId: layout.data()["categoryGroups"][i].categoryId,
          models: [],
        }
        for (
          let j = 0;
          j < layout.data()["categoryGroups"][i].modelIds.length;
          j++
        ) {
          const model = await ModelService.getModelById(
            layout.data()["categoryGroups"][i].modelIds[j]
          )
          if (model !== undefined) {
            group.models.push(toJS(model))
          }
        }
        newGroups.push(group)
      }
      const layoutData: Layout = {
        id: layout.id,
        categoryGroups: newGroups,
        createdAt: moment(layout.data()["createdAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
        updatedAt: moment(layout.data()["updatedAt"].toDate()).format(
          "yyyy.MM.DD"
        ),
      }

      return layoutData
    }
  }

  //레이아웃 생성

  //레이아웃 업데이트
  static updateLayout = async (layoutData: Layout) => {
    const ref = doc(db, "layout/G6FOrGtMAT57GCbWseWQ")
    const newGroups: Array<{
      categoryId: string
      modelIds: Array<string>
    }> = []
    for (let i = 0; i < layoutData.categoryGroups.length; i++) {
      const group: { categoryId: string; modelIds: Array<string> } = {
        categoryId: layoutData.categoryGroups[i].categoryId,
        modelIds: [],
      }
      for (let j = 0; j < layoutData.categoryGroups[i].models.length; j++) {
        group.modelIds.push(layoutData.categoryGroups[i].models[j].id)
      }
      newGroups.push(group)
    }

    await updateDoc(ref, {
      categoryGroups: newGroups,
      updatedAt: serverTimestamp(),
    })
  }

  //레이아웃 삭제
  // static deleteLayout = async () => {}
}
