import styled from "styled-components"
import Spacer from "../../components/Spacer"
import { useContext } from "react"
import { StoreContext } from "../../store/store.context"
import { observer } from "mobx-react"

const S = {
  Background: styled.div`
    background-color: #f9fafc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.div`
    font-size: 24px;
    color: black;
    font-weight: bold;
  `,
  Input: styled.input`
    width: 372px;
    height: 48px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #e4e6ea;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 400;
    color: black;

    &::placeholder {
      color: #9da0a8;
      font-size: 14px;
    }
  `,
  Button: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #00baff;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-weight: 600;
  `,
}

const Admin = () => {
  const { adminStore } = useContext(StoreContext)
  const { id, password, onChangeId, onChangePassword, login, onKeyEnterLogin } =
    adminStore

  return (
    <S.Background>
      <S.Container>
        <S.Title>LOGIN</S.Title>
        <Spacer height={20} />
        <S.Input
          placeholder={"아이디를 입력해주세요"}
          type={"text"}
          onChange={onChangeId}
          onKeyDown={onKeyEnterLogin}
          value={id}
        />
        <Spacer height={20} />
        <S.Input
          placeholder={"비밀번호를 입력해주세요"}
          type={"password"}
          onChange={onChangePassword}
          onKeyDown={onKeyEnterLogin}
          value={password}
        />
        <Spacer height={20} />
        <S.Button onClick={login}>로그인</S.Button>
      </S.Container>
    </S.Background>
  )
}

export default observer(Admin)
