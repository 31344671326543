import S3 from "react-aws-s3"
import imageCompression from "browser-image-compression"

window.Buffer = window.Buffer || require("buffer").Buffer

export class S3Service {
  static config = {
    bucketName: "readymodel",
    region: "ap-northeast-2",
    accessKeyId: "AKIA44KD7TJJK4W4VPX5",
    secretAccessKey: "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",
  }

  static uploadFile = async (file: File, prefix: string) => {
    const options = {
      maxSizeMB: 0.3,
      useWebWorker: true,
    }
    const ReactS3Client = new S3(this.config)
    if (file.name.includes("mp4")) {
      const data = await ReactS3Client.uploadFile(file, `${prefix}${file.name}`)
      return data.location
    } else {
      const compressedFile = await imageCompression(file, options)
      const data = await ReactS3Client.uploadFile(
        compressedFile,
        `${prefix}${file.name}`
      )
      return data.location
    }
  }

  static deleteFile = async (url: string) => {
    const ReactS3Client = new S3(this.config)
    await ReactS3Client.deleteFile(url)
  }
}
