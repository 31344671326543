import React, {useContext} from "react";
import {StoreContext} from "./store/store.context";
import theme from "./theme";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Intro from "./pages/intro";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import AdminOutlet from "./components/outlets/AdminOutlet";
import Admin from "./pages/admin";
import AdminLayout from "./components/layouts/AdminLayout";
import AdminModel from "./pages/admin/model";
import AdminModelDetail from "./pages/admin/model/detail";
import AdminModelRegister from "./pages/admin/model/register";
import AdminDetailCategory from "./pages/admin/detail/category";
import AdminDetailCategoryDetail from "./pages/admin/detail/category/detail";
import AdminDetailCategoryRegister from "./pages/admin/detail/category/register";
import AdminDetailHashTag from "./pages/admin/detail/hashTag";
import AdminDetailHashTagDetail from "./pages/admin/detail/hashTag/detail";
import AdminDetailHashTagRegister from "./pages/admin/detail/hashTag/register";
import AdminDetailLayout from "./pages/admin/detail/layout";
import AdminDetailLayoutDetail from "./pages/admin/detail/layout/detail";
import AdminDetailLayoutRegister from "./pages/admin/detail/layout/register";
import AdminManagePrivacy from "./pages/admin/manage/privacy";
import AdminManageUpdate from "./pages/admin/manage/update";
import AdminManageCompany from "./pages/admin/manage/company";
import NotFoundPage from "./pages/404";
import {ThemeProvider} from "styled-components";
import {observer} from "mobx-react";
import ModelDetail from "./pages/model";

const App = () => {
    const {adminStore} = useContext(StoreContext)
    const {loggedIn} = adminStore

    return <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route index element={<Intro/>}/>
                <Route path={'/home'} element={<Home/>}/>
                <Route path={'/model'} element={<ModelDetail/>}/>
                <Route path={'/about'} element={<About/>}/>
                <Route path={'/contact'} element={<Contact/>}/>
                <Route path={'/admin'} element={<AdminOutlet/>}>
                    <Route index element={!loggedIn?<Admin/>:<Navigate to={'model'}/>}/>
                    <Route path={'model'} element={!loggedIn?<Navigate to={'/admin'}/>:<AdminLayout/>}>
                        <Route index element={<AdminModel/>}/>
                        <Route path={':id'} element={<AdminModelDetail/>}/>
                        <Route path={'register'} element={<AdminModelRegister/>}/>
                    </Route>
                    <Route path={'detail'}  element={!loggedIn?<Navigate to={'/admin'}/>:<AdminLayout/>}>
                        <Route index element={<Navigate to={'category'}/>}/>
                        <Route path={'category'}>
                            <Route index element={<AdminDetailCategory/>}/>
                            <Route path={':id'} element={<AdminDetailCategoryDetail/>}/>
                            <Route path={'register'} element={<AdminDetailCategoryRegister/>}/>
                        </Route>
                        <Route path={'hashtag'}>
                            <Route index element={<AdminDetailHashTag/>}/>
                            <Route path={':id'} element={<AdminDetailHashTagDetail/>}/>
                            <Route path={'register'} element={<AdminDetailHashTagRegister/>}/>
                        </Route>
                        <Route path={'layout'}>
                            <Route index element={<AdminDetailLayout/>}/>
                            <Route path={'id'} element={<AdminDetailLayoutDetail/>}/>
                            <Route path={'register'} element={<AdminDetailLayoutRegister/>}/>
                        </Route>
                    </Route>
                    <Route path={'manage'} element={!loggedIn?<Navigate to={'/admin'}/>:<AdminLayout/>}>
                        <Route index element={<Navigate to={'update'}/>}/>
                        <Route path={'privacy'} element={<AdminManagePrivacy/>}/>
                        <Route path={'update'} element={<AdminManageUpdate/>}/>
                        <Route path={'company'} element={<AdminManageCompany/>}/>
                    </Route>
                </Route>

                <Route path={'*'} element={<NotFoundPage/>}/>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
}

export default observer(App)