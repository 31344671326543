import { observer } from "mobx-react"
import React, { useContext, useEffect } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import uuid from "react-uuid"
import styled from "styled-components"
import DragPointer from "../../../../components/icons/DragPointer"
import UploadFile from "../../../../components/icons/UploadFile"
import AdminImageCropModal from "../../../../components/modals/AdminImageCropModal"
import AdminResourceDeleteModal from "../../../../components/modals/AdminResourceDeleteModal"
import AdminUpdateImageModal from "../../../../components/modals/AdminUpdateImageModal"
import Spacer from "../../../../components/Spacer"
import { UtilService } from "../../../../service/UtilService"
import { StoreContext } from "../../../../store/store.context"

const S = {
  Container: styled.div`
    height: inherit;
    padding: 0px 36px;
    width: 100%;
    background-color: #f7f9fb;
    overflow-y: auto;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    width: 100%;
    height: 48px;
    padding-top: 36px;
  `,
  ControllBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
  `,
  Title: styled.text`
    display: flex;
    width: auto;
    height: 36px;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f4a;
  `,
  TitleSubContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  SaveButton: styled.button`
    height: 48px;
    padding: 12px 60px;
    background: #00baff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    :hover {
      background: #0094ca;
    }
  `,
  ButtonBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  `,
  InputSubContainer: styled.div`
    gap: 8px;
  `,
  InputLabel: styled.div`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #5a5e6a;
    margin-bottom: 8px;
  `,
  FileUploadButton: styled.div`
    display: flex;
    align-items: center;
    padding: 0px;
    width: 372px;
    height: 52px;
    background: #ffffff;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #5a5e6a;
    font-size: 14px;
    padding: 0px 16px;
    font-weight: 400;
    line-height: 21px;
    gap: 13.5px;
    cursor: pointer;
    :hover {
      background: #e4e6ea;
    }
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
  `,
  TableHedaer: styled.thead`
    padding: 0px;
    height: 20px;
    border-radius: 8px;
    flex: none;
  `,
  TableBody: styled.tbody``,
  TableHeaderRow: styled.tr`
    th:first-child {
      width: 78px;
    }
    th:nth-child(2) {
      width: 400px;
    }
    th:nth-child(3) {
      /* width: 294px; */
    }
    th:nth-child(4) {
      width: 78px;
    }

    th:last-child {
      width: 78px;
      border: none;
    }
  `,
  TableBodyRow: styled.tr<{ isDragging?: boolean }>`
    background-color: ${(props) => (props.isDragging ? "#E5F8FF" : "white")};
    width: 100%;
    display: ${(props) => (props.isDragging ? "flex" : "table-row")};
    border-radius: 8px;
    vertical-align: middle;

    td:first-child {
      border-radius: 8px 0 0 8px;
      width: 78px;
      cursor: pointer;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
      align-items: center;
    }
    td:nth-child(2) {
      width: 400px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      vertical-align: middle;
      gap: 8px;
      align-items: center;
      text-align: start;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
    }
    td:nth-child(3) {
      border-radius: 0 8px 8px 0;
      align-items: center;
      text-align: center;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      flex: auto;
      justify-content: center;
    }
    td:nth-child(4) {
      width: 78px;
      align-items: center;
      text-align: center;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
      align-items: center;
      text-align: center;
      width: 78px;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
  `,
  TableHeaderCol: styled.th`
    box-sizing: border-box;
    /* align-items: center; */
    /* width: auto; */
    height: 20px;
    border-right: 1px solid #e4e6ea;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 500;
  `,
  TableBodyCol: styled.td`
    box-sizing: border-box;
    align-items: center;
    /* justify-content: center; */
    padding: 8px;
    /* width: auto; */
    height: 70px;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
  `,
  Link: styled.div`
    font-size: 10px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #5a5e6a;
    cursor: pointer;
  `,
  UrlLink: styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    color: #5a5e6a;
    cursor: pointer;
  `,
  Image: styled.img`
    display: flex;
    height: 36px;
    width: 43px;
  `,
  ImageColumnBox: styled.div`
    display: flex;
    width: inherit;
    align-items: center;
    gap: 8px;
    word-break: break-word;
  `,
}

const AdminManageUpdate = () => {
  const { adminStore } = useContext(StoreContext)
  const {
    updatedList,
    updatedImageModal,
    updatedUpdateModal,
    updatedImageCropModal,
    updatedUpdateImageCropModal,
    updatedDeleteModal,
    deleteMessage,
    onPageInitUpdated,
    onOpenUpdatedImageModal,
    onCloseUpdatedImageModal,
    onCloseUpdatedCropModal,
    onCropSubmitUpdated,
    onSubmitUpdatedModal,
    onDeleteUpdated,
    onDragEndUpdated,
    onSubmitIndexUpdated,
    onOpenUpdatedDeleteModal,
    onCloseUpdatedDeleteModal,
    onOpenUpdatedUpdateImageModal,
    onCloseUpdatedUpdateImageModal,
    onOpenUpdatedUpdateCropModal,
    onSubmitUpdatedUpdateModal,
    onCropSubmitUpdatedUpdate,
    onOpenUpdatedCropModal,
    onCloseUpdatedUpdateCropModal,
  } = adminStore

  useEffect(() => {
    onPageInitUpdated()
  }, [])

  return (
    <>
      {/* 업데이트 등록 모달 */}
      {updatedImageModal && (
        <AdminUpdateImageModal
          isOpen={updatedImageModal}
          title="이미지 등록하기"
          onRequestClose={onCloseUpdatedImageModal}
          onSubmit={onSubmitUpdatedModal}
          submitTitle="등록"
          onOpenCropModal={onOpenUpdatedCropModal}
        />
      )}

      {/* 업데이트 수정 모달 */}
      {updatedUpdateModal && (
        <AdminUpdateImageModal
          isOpen={updatedUpdateModal}
          title="업데이트 수정하기"
          onRequestClose={onCloseUpdatedUpdateImageModal}
          onSubmit={onSubmitUpdatedUpdateModal}
          submitTitle="수정"
          onOpenCropModal={onOpenUpdatedUpdateCropModal}
        />
      )}
      {/* 이미지크롭 모달 */}
      {updatedImageCropModal && (
        <AdminImageCropModal
          isOpen={updatedImageCropModal}
          onRequestClose={onCloseUpdatedCropModal}
          onCropSubmit={onCropSubmitUpdated}
        />
      )}
      {/* 이미지크롭 모달 */}
      {updatedUpdateImageCropModal && (
        <AdminImageCropModal
          isOpen={updatedUpdateImageCropModal}
          onRequestClose={onCloseUpdatedUpdateCropModal}
          onCropSubmit={onCropSubmitUpdatedUpdate}
        />
      )}
      {/* 모델 삭제모달 */}
      <AdminResourceDeleteModal
        isOpen={updatedDeleteModal}
        resourceName="카테고리"
        deleteMessage={deleteMessage}
        onRequestClose={onCloseUpdatedDeleteModal}
        onSubmit={onDeleteUpdated}
      />

      <S.Container>
        <S.TitleBox>
          <S.Title>업데이트 관리</S.Title>
          <S.ControllBox>
            <S.SaveButton onClick={onSubmitIndexUpdated}>저장하기</S.SaveButton>
          </S.ControllBox>
        </S.TitleBox>
        <S.InputSubContainer>
          <S.InputLabel>파일 업로드</S.InputLabel>
          <S.FileUploadButton onClick={onOpenUpdatedImageModal}>
            <UploadFile />
            이미지 파일 업로드하기
          </S.FileUploadButton>
        </S.InputSubContainer>
        <Spacer height={20} />
        <S.Table>
          <S.TableHedaer>
            <S.TableHeaderRow>
              <S.TableHeaderCol>순서변경</S.TableHeaderCol>
              <S.TableHeaderCol>파일명</S.TableHeaderCol>
              <S.TableHeaderCol>제목</S.TableHeaderCol>
              <S.TableHeaderCol>수정</S.TableHeaderCol>
              <S.TableHeaderCol>삭제</S.TableHeaderCol>
            </S.TableHeaderRow>
          </S.TableHedaer>
          <DragDropContext onDragEnd={onDragEndUpdated}>
            <Droppable key={uuid()} droppableId="videoDroppable">
              {(provided1, snapShot1) => (
                <S.TableBody
                  ref={provided1.innerRef}
                  {...provided1.droppableProps}
                >
                  {updatedList.map((updated, index) => (
                    <Draggable
                      key={`updatedDragabble/${index}`}
                      draggableId={`updatedDragabble/${index}`}
                      index={index}
                    >
                      {(provided2, snapShot2) => (
                        <S.TableBodyRow
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          isDragging={snapShot2.isDragging}
                        >
                          <S.TableBodyCol {...provided2.dragHandleProps}>
                            <DragPointer />
                          </S.TableBodyCol>
                          <S.TableBodyCol>
                            <S.ImageColumnBox>
                              <S.Image src={updated.imageUrl} />
                              {UtilService.fileUrlToFileName(updated.imageUrl)}
                            </S.ImageColumnBox>
                          </S.TableBodyCol>
                          <S.TableBodyCol>{updated.title}</S.TableBodyCol>
                          <S.TableBodyCol>
                            <S.Link
                              onClick={() =>
                                onOpenUpdatedUpdateImageModal(updated)
                              }
                            >
                              수정
                            </S.Link>
                          </S.TableBodyCol>
                          <S.TableBodyCol>
                            <S.Link
                              onClick={() => onOpenUpdatedDeleteModal(updated)}
                            >
                              삭제
                            </S.Link>
                          </S.TableBodyCol>
                        </S.TableBodyRow>
                      )}
                    </Draggable>
                  ))}
                  {provided1.placeholder}
                </S.TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </S.Table>
      </S.Container>
    </>
  )
}

export default observer(AdminManageUpdate)
