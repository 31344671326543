import styled from "styled-components";
import Spacer from "../Spacer";
import { Fragment, useContext, useEffect } from "react";
import { StoreContext } from "../../store/store.context";
import { observer } from "mobx-react";
import Logo from "../icons/logo";
import uuid from "react-uuid";
import { useLocation, useNavigate } from "react-router-dom";

const S = {
  Container: styled.div`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    background-color: black;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 10;
  `,
  NavBtn: styled.div<{ selected?: boolean }>`
    display: flex;
    justify-content: center;
    font-weight: ${(props) => (props.selected ? 400 : 100)};
    color: ${({ theme }) => theme.colors.white};
    margin-left: 12px;
    margin-right: 12px;
    font-size: 16px;
    font-family: Jura, sans-serif;
    cursor: pointer;
  `,
  Split: styled.div`
    font-size: 16px;
    color: white;
    font-family: Jura, sans-serif;
    font-weight: 300;
  `,
  NavBtnContainer: styled.div`
    max-width: 90vw;
    width: 90vw;
    display: flex;
    justify-content: center;

    flex-direction: row;
  `,
  NavContainer: styled.div`
    min-width: 80vw;
    justify-content: center;

    overflow-x: scroll;
    display: flex;
    flex-direction: row;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  `,
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { globalStore } = useContext(StoreContext);
  const {
    selectedCategory,
    changeMenu,
    categories,
    favorites,
    getModelsDataByCategory,
  } = globalStore;

  useEffect(() => {}, []);

  return (
    <S.Container>
      <Logo
        onClick={() => {
          navigate("/home");
          location.state = "UPDATED";
          changeMenu("UPDATED");
        }}
        width={"100"}
        height={"48"}
      />
      <Spacer height={20} />
      <S.NavBtnContainer>
        <S.NavContainer>
          {categories
            .filter((category, i) => category.showYn === true)
            .map((category, i) => (
              <Fragment key={uuid()}>
                <S.NavBtn
                  selected={selectedCategory === category.name}
                  onClick={async () => {
                    // 여기!!
                    await getModelsDataByCategory(category.id);
                    localStorage.setItem("before", selectedCategory);
                    changeMenu(category.name);
                    navigate("/home");
                  }}
                >
                  {category.name}
                </S.NavBtn>
                <S.Split>/</S.Split>
              </Fragment>
            ))}
          <S.NavBtn
            selected={selectedCategory === "SELECTED"}
            onClick={() => {
              changeMenu("SELECTED");
              localStorage.setItem("before", selectedCategory);
              navigate("/home");
            }}
          >
            SELECTED {favorites.length > 0 ? `( ${favorites.length} )` : ""}
          </S.NavBtn>
        </S.NavContainer>
      </S.NavBtnContainer>
    </S.Container>
  );
};

export default observer(Header);
