import styled from "styled-components";
import React, {useState} from "react";
import Minus from "../icons/Minus";
import Plus from "../icons/Plus";
import {useLocation, useNavigate} from "react-router-dom";
import uuid from "react-uuid";


interface DetailMenu {
    name: string
    to: string
}

interface NavigationMenuProps {
    title: string,
    detailMenus: DetailMenu[]
}

const S = {
    Container: styled.div`
      display: flex;
      flex-direction: column;
      padding: 20px;
    `,
    TitleContainer: styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;
    `,
    Title: styled.div`
      color: black;
      font-weight: bold;
      font-size: 16px;
    `,
    SubTitleContainer: styled.div<{current: boolean}>`
      cursor: pointer;
      padding: 20px;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-weight: bold;
      color: ${props => props.current?"#00BAFF":"black"};
    `
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({title, detailMenus}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false)

    const onClickIcon = () => {
        setOpen(!open);
    }

    return (
        <S.Container>
            <S.TitleContainer>
                <S.Title>{title}</S.Title>
                {!open && <Plus onClick={onClickIcon}/>}
                {open && <Minus onClick={onClickIcon}/>}
            </S.TitleContainer>
            {open && <>
                {
                    detailMenus.map((menu) =>
                        <S.SubTitleContainer
                            key={uuid()}
                            current={location.pathname.includes(menu.to) }
                            onClick={() => navigate(menu.to)}
                        >
                        {menu.name}
                    </S.SubTitleContainer> )
                }
            </>}
        </S.Container>
    )
}

export default NavigationMenu