import styled from "styled-components";
import React from "react";
import {AdminUpdated} from "../../models/interfaces/AdminUpdated";


interface UpdatedModelCardProps {
    updatedModel: AdminUpdated
    imageOnClick: () => void
}

const S = {
    ModelCard: styled.div`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    ModelImage: styled.img`
      color: white;
      width: inherit;
      height: inherit;
      max-width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      filter: grayscale(100%);

      &:hover {
        filter: grayscale(0%);
      }
    `,
    ModelNameContainer: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 20px;
      font-family: Jura;
      cursor: pointer;
    `,
}

const UpdatedModelCard:React.FC<UpdatedModelCardProps> = ({updatedModel, imageOnClick}) => {
    const {
        model,
        imageUrl,
        title
    } = updatedModel
    return (
        <S.ModelCard>
            <S.ModelImage onClick={imageOnClick} src={imageUrl} loading={"lazy"}/>
            <S.ModelNameContainer >{title}</S.ModelNameContainer>
        </S.ModelCard>
    )
}

export default UpdatedModelCard