import DesktopHeader from "../../components/header/Header";
import Background from "../../components/Background";
import FooterDesktop from "../../components/footer/Footer";
import Spacer from "../../components/Spacer";
import Title from "../../components/texts/Title";
import {useContext, useEffect} from "react";
import {StoreContext} from "../../store/store.context";
import AboutImage from '../../images/about.png'
import styled from "styled-components";
import MenuButton from "../../components/buttons/MenuButton";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {autorun} from "mobx";
import {history} from "../../history";


const S = {
    Image: styled.img`
      aspect-ratio: 1156/401;
      width: 100%;
    `,
    Container: styled.div`
      width: 90vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1156px;
    `,
    InfoContainer: styled.div`
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 40px;
      justify-content: center;
      @media only screen and (max-width: 764px) {
        flex-direction: column;
      }
    `,
    Info: styled.div`
      flex: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      @media only screen and (max-width: 764px) {
        margin-bottom: 80px;
      }
    `,
    InfoTitle: styled.div`
      width: 100%;
      font-family: Jura, sans-serif;
      font-size: 32px;
      color: #BEC1C7;

      @media only screen and (max-width: 764px) {
        width: 90vw;
      }
    `,
    InfoBigTitle: styled.span`
      font-family: 'Anton', sans-serif;
      font-weight: 400;
      color: white;
      font-size: 84px;
    `,
    InfoDesc: styled.div`
      font-family: Jura, sans-serif;
      font-size: 16px;
      width: 100%;
      max-width: 300px;
      font-weight: 400;
      line-height: 170%;
      color: #BEC1C7;
      text-align: left;
      @media only screen and (max-width: 764px) {
        width: 90vw;
      }
    `,
    ButtonContainer: styled.div`
      padding-top: 10vw;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 200px;

    `
}

const About = () => {
    const navigate = useNavigate()
    const {globalStore} = useContext(StoreContext)
    const {
        selectedCategory,
        changeMenu,
        getCompanyInfo,
        companyInfo,
        categories
    } = globalStore

    useEffect(() => {
        const listenBackEvent = () => {
            // 뒤로가기 할 때 수행할 동작을 적는다
            const before = localStorage.getItem("before")
            switch(before){
                case "SELECTED":
                    changeMenu("SELECTED");
                    break;
                case "UPDATED":
                    changeMenu("UPDATED");
                    break;
                default:
                    const category = categories.find((category) => category.name === before)
                    changeMenu(category!.name)
                    break;

            }
        };

        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === "POP") {
                listenBackEvent();
            }
        });

        return unlistenHistoryEvent
    },[])

    useEffect(() => {
        changeMenu("ABOUT US")
        autorun(async () => {
            await getCompanyInfo()
        })
    }, [])

    return (
        <>
            <DesktopHeader/>
            <Background>
                <S.Container>
                    <Spacer height={60}/>
                    <Title>{selectedCategory}</Title>
                    <Spacer height={60}/>
                    <S.Image src={AboutImage}/>
                    <Spacer height={120}/>
                    <S.InfoContainer>
                        <S.Info>
                            <S.InfoTitle><S.InfoBigTitle>SEOUL</S.InfoBigTitle> . KOREA</S.InfoTitle>
                            <Spacer height={40}/>
                            <S.InfoDesc>
                                {companyInfo?.kAddress}
                            </S.InfoDesc>
                            <Spacer height={40}/>
                            <S.InfoDesc>
                                {companyInfo?.kTel}<br/>
                                {companyInfo?.kEmail}
                            </S.InfoDesc>
                        </S.Info>
                        <S.Info>
                            <S.InfoTitle><S.InfoBigTitle>SHANG HAI</S.InfoBigTitle> . CHINA</S.InfoTitle>
                            <Spacer height={40}/>
                            <S.InfoDesc>
                                {companyInfo?.cAddress}
                            </S.InfoDesc>
                            <Spacer height={40}/>
                            <S.InfoDesc>
                                {companyInfo?.cTel}<br/>
                                {companyInfo?.cEmail}
                            </S.InfoDesc>
                        </S.Info>
                    </S.InfoContainer>
                    <Spacer height={20}/>
                    <S.ButtonContainer>
                        <MenuButton onClick={() => {
                            navigate('/contact')
                            changeMenu('CONTACT')
                        }} label={"CONTACT"}/>
                    </S.ButtonContainer>
                </S.Container>
            </Background>
            <FooterDesktop/>
        </>
    )
}

export default observer(About)