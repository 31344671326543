import React from "react"

interface UploadFileProps {
  width?: number
  height?: number
  color?: string
}

const UploadFile: React.FC<UploadFileProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width ? `${width}` : "24"}
      height={height ? `${height}` : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_714_15051)">
        <path
          d="M14 7V0.46C14.9251 0.809335 15.7653 1.35131 16.465 2.05L19.949 5.536C20.6485 6.23488 21.1909 7.07489 21.54 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7ZM22 10.485V19C21.9984 20.3256 21.4711 21.5964 20.5338 22.5338C19.5964 23.4711 18.3256 23.9984 17 24H7C5.67441 23.9984 4.40356 23.4711 3.46622 22.5338C2.52888 21.5964 2.00159 20.3256 2 19V5C2.00159 3.67441 2.52888 2.40356 3.46622 1.46622C4.40356 0.528882 5.67441 0.00158786 7 0L11.515 0C11.678 0 11.839 0.013 12 0.024V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H21.976C21.987 10.161 22 10.322 22 10.485ZM16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H13V14C13 13.7348 12.8946 13.4804 12.7071 13.2929C12.5196 13.1054 12.2652 13 12 13C11.7348 13 11.4804 13.1054 11.2929 13.2929C11.1054 13.4804 11 13.7348 11 14V16H9C8.73478 16 8.48043 16.1054 8.29289 16.2929C8.10536 16.4804 8 16.7348 8 17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H11V20C11 20.2652 11.1054 20.5196 11.2929 20.7071C11.4804 20.8946 11.7348 21 12 21C12.2652 21 12.5196 20.8946 12.7071 20.7071C12.8946 20.5196 13 20.2652 13 20V18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17Z"
          fill={color ? color : "#9DA0A8"}
        />
      </g>
      <defs>
        <clipPath id="clip0_714_15051">
          <rect
            width={width ? `${width}` : "24"}
            height={height ? `${height}` : "24"}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UploadFile
