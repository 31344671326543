import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore/lite"
import moment from "moment"
import { db } from "../firebase"
import { CompanyInfo } from "../models/interfaces/CompanyInfo"

export class CompanyInfoService {
  //회사정보 불러오기
  static getCompanyInfo = async () => {
    const ref = doc(db, "companyInfo/AR5fmfh6yemWXDSnq2yo")
    const snapshot = await getDoc(ref)
    const companyInfo: CompanyInfo = {
      id: snapshot.id,
      cAddress: snapshot.data()!["cAddress"],
      cEmail: snapshot.data()!["cEmail"],
      cTel: snapshot.data()!["cTel"],
      kAddress: snapshot.data()!["kAddress"],
      kEmail: snapshot.data()!["kEmail"],
      kTel: snapshot.data()!["kTel"],
      updatedAt: moment(snapshot.data()!["updatedAt"].toDate()).format(
        "yyyy.MM.DD"
      ),
    }

    return companyInfo
  }

  //회사정보 업데이트
  static updateCompanyInfo = async (companyInfo: CompanyInfo) => {
    const ref = doc(db, "companyInfo/AR5fmfh6yemWXDSnq2yo")
    await updateDoc(ref, {
      cAddress: companyInfo.cAddress,
      cEmail: companyInfo.cEmail,
      cTel: companyInfo.cTel,
      kAddress: companyInfo.kAddress,
      kEmail: companyInfo.kEmail,
      kTel: companyInfo.kTel,
      updatedAt: serverTimestamp(),
    })
  }
}
