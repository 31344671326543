import styled from "styled-components"
import { IoMdClose, IoMdCheckmark } from "react-icons/io"
import React, { useContext } from "react"
import Spacer from "../Spacer"
import { StoreContext } from "../../store/store.context"
import { observer } from "mobx-react"
import "cropperjs/dist/cropper.css"
import UploadFile from "../icons/UploadFile"
import AdminInfoInput from "../inputs/AdminInfoInput"
import AdminSearchInput from "../inputs/AdminSearchInput"
import AdminTableCheckbox from "../inputs/AdminTableCheckbox"
import uuid from "react-uuid"
import { UtilService } from "../../service/UtilService"

interface AdminUpdateImageModalProps {
  isOpen: boolean
  title: string
  submitTitle: string
  onRequestClose: () => void
  onOpenCropModal: () => void
  onSubmit: () => void
}

const S = {
  Modal: styled.div<{ isOpen: boolean }>`
    animation: ${(props) => {
      if (props.isOpen) {
        return `FadeIn 0.1s ease-out`
      }
    }};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(25px); */
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  ModalContainer: styled.div`
    width: 392px;
    padding: 21px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    border-radius: 16px;
  `,
  ModalHeader: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 28px;
  `,
  ModalHeaderBalencer: styled.div`
    display: flex;
    width: 28px;
    height: 28px;
  `,
  ModalTitle: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    color: #000000;
    @media only screen and (max-width: 764px) {
      font-size: 24px;
    }
  `,
  ModalDivider: styled.div`
    background-color: white;
    height: 2px;
    width: 100%;
  `,
  ModalCloseButton: styled(IoMdClose)`
    display: flex;
    color: #0a0a0a;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
  `,
  SelectContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    height: 372px;
    background: #ffffff;
    flex: none;
    flex-grow: 0;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  SelectOptionContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    padding: 17.5px 0px 18.5px 0px;
    border-bottom: 1px solid #f3f3f3;
    cursor: pointer;
  `,
  SelectOptionLabel: styled.div<{ isClick: boolean }>`
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => (props.isClick ? "#00BAFF" : "#5A5E6A")};
  `,
  SelectOptionButton: styled(IoMdCheckmark)<{ isClick: boolean }>`
    display: flex;
    color: ${(props) => (props.isClick ? "#00BAFF" : "#BEC1C7")};
    width: 28px;
    height: 28px;
  `,
  SubmitButton: styled.button`
    display: block;
    height: 48px;
    width: 100%;
    text-align: center;
    padding: 12px 0px;
    background: #00baff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    :hover {
      background: #0094ca;
    }
  `,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  InputSubContainer: styled.div`
    width: 100%;
    gap: 8px;
  `,
  InputLabel: styled.div`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #5a5e6a;
    margin-bottom: 8px;
  `,
  FileUploadButton: styled.div`
    display: flex;
    align-items: center;
    padding: 0px;
    width: 360px;
    height: 52px;
    background: #ffffff;
    border: 1px solid #e4e6ea;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #5a5e6a;
    font-size: 14px;
    padding: 0px 16px;
    font-weight: 400;
    line-height: 21px;
    gap: 13.5px;
    cursor: pointer;
    :hover {
      background: #e4e6ea;
    }
  `,
  HistoryContainer: styled.div`
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  HistoryTitleContainer: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #f9fafc;
    height: 20px;
    font-size: 12px;
  `,
  HistoryItemContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eff1f4;
    padding-top: 12px;
    padding-bottom: 12px;
  `,
  HistoryIndexContainer: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  HistoryNoContainer: styled.div`
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  HistoryDateContainer: styled.div`
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}

const AdminUpdateImageModal: React.FC<AdminUpdateImageModalProps> = ({
  isOpen,
  title,
  submitTitle,
  onRequestClose,
  onOpenCropModal,
  onSubmit,
}) => {
  const { adminStore } = useContext(StoreContext)
  const {
    cropModalImageFile,
    updatedTitle,
    updatedModel,
    updatedModelList,
    updatedCategoryList,
    updatedModelNameSearch,
    updatedUpdate,
    onChangeUpdatedTitle,
    onChangeUpdatedModel,
    onChangeUpdatedModelNameSearch,
  } = adminStore

  const onOpenCropIamgeModal = () => {
    onOpenCropModal()
  }

  const setCategoryName = (categoryId: string) => {
    const category = updatedCategoryList.find((c) => c.id === categoryId)
    if (category === undefined) {
      return ""
    } else {
      return category.name
    }
  }

  const onFilterModelName = () => {
    return updatedModelList.filter(
      (model) => model.name.indexOf(updatedModelNameSearch) !== -1
    )
  }

  return (
    <>
      {isOpen && (
        <S.Modal isOpen={isOpen}>
          <S.ModalContainer>
            <S.ModalHeader>
              <S.ModalHeaderBalencer />
              <S.ModalTitle>{title}</S.ModalTitle>
              <S.ModalCloseButton onClick={onRequestClose} />
            </S.ModalHeader>
            <Spacer height={28} />
            <S.InputSubContainer>
              <S.InputLabel>이미지 등록</S.InputLabel>
              <S.FileUploadButton onClick={onOpenCropIamgeModal}>
                <UploadFile />
                {cropModalImageFile !== undefined
                  ? cropModalImageFile.name
                  : updatedUpdate
                  ? UtilService.fileUrlToFileName(updatedUpdate.imageUrl)
                  : "이미지 업로드하기"}
              </S.FileUploadButton>
            </S.InputSubContainer>

            <Spacer height={40} />

            <S.InputSubContainer>
              <S.InputLabel>제목</S.InputLabel>
              <AdminInfoInput
                value={updatedTitle}
                onChange={onChangeUpdatedTitle}
                width="100%"
                placeholder="제목을 입력해주세요."
              />
            </S.InputSubContainer>

            <Spacer height={40} />

            <S.InputSubContainer>
              <S.InputLabel>모델 선택</S.InputLabel>
              <AdminSearchInput
                value={updatedModelNameSearch}
                onChange={onChangeUpdatedModelNameSearch}
                width="100%"
              />
              <Spacer height={20} />
              <S.HistoryContainer>
                <S.HistoryTitleContainer>
                  <S.HistoryIndexContainer></S.HistoryIndexContainer>
                  <S.HistoryNoContainer>카테고리</S.HistoryNoContainer>
                  <S.HistoryDateContainer>모델명</S.HistoryDateContainer>
                </S.HistoryTitleContainer>
                {onFilterModelName().map((model, i) => (
                  <S.HistoryItemContainer key={uuid()}>
                    <S.HistoryIndexContainer>
                      <AdminTableCheckbox
                        checked={
                          updatedModel ? model.id === updatedModel.id : false
                        }
                        onClick={() => onChangeUpdatedModel(model)}
                      />
                    </S.HistoryIndexContainer>
                    <S.HistoryNoContainer>
                      {setCategoryName(model.categoryId)}
                    </S.HistoryNoContainer>
                    <S.HistoryDateContainer>
                      {model.name}
                    </S.HistoryDateContainer>
                  </S.HistoryItemContainer>
                ))}
              </S.HistoryContainer>
            </S.InputSubContainer>

            <Spacer height={40} />

            <S.SubmitButton onClick={onSubmit}>{submitTitle}</S.SubmitButton>
          </S.ModalContainer>
        </S.Modal>
      )}
    </>
  )
}

export default observer(AdminUpdateImageModal)
