import { useContext } from "react"
import styled from "styled-components"
import { StoreContext } from "../../store/store.context"
import LogoutButton from "../buttons/LogoutButton"

const S = {
  Container: styled.div`
    width: 100vw;
    max-width: 100vw;
    height: 52px;
    max-height: 52px;
    display: flex;
    align-items: center;
    background-color: #3b3f4a;
    justify-content: space-between;
  `,
  Label: styled.div`
    font-size: 16px;
    padding-left: 20px;
    color: white;
    font-weight: bold;
  `,
  ButtonBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding: 0px 10px;
  `,
}

const AdminHeader = () => {
  const { adminStore } = useContext(StoreContext)
  const { logout } = adminStore
  return (
    <S.Container>
      <S.Label>ReadyX Admin</S.Label>
      <S.ButtonBox>
        <LogoutButton onClick={logout} />
      </S.ButtonBox>
    </S.Container>
  )
}

export default AdminHeader
