import styled from "styled-components"
import { IoMdClose, IoMdCheckmark } from "react-icons/io"
import React, { useContext } from "react"
import Spacer from "../Spacer"
import { StoreContext } from "../../store/store.context"
import { observer } from "mobx-react"
import "cropperjs/dist/cropper.css"
import { HashTag } from "../../models/interfaces/HashTag"
import { useEffect } from "react"

interface AdminHashtagSelectModalProps {
  isOpen: boolean
  imgUrl?: string
  hashTagList: Array<HashTag>
  hashTag: Array<string>
  onRequestClose: () => void
}

const S = {
  Modal: styled.div<{ isOpen: boolean }>`
    animation: ${(props) => {
      if (props.isOpen) {
        return `FadeIn 0.1s ease-out`
      }
    }};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(25px); */
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  ModalContainer: styled.div`
    width: 414px;
    padding: 21px 20px;
    height: 532px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    border-radius: 16px;
  `,
  ModalHeader: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 28px;
  `,
  ModalHeaderBalencer: styled.div`
    display: flex;
    width: 28px;
    height: 28px;
  `,
  ModalTitle: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    color: #000000;
    @media only screen and (max-width: 764px) {
      font-size: 24px;
    }
  `,
  ModalDivider: styled.div`
    background-color: white;
    height: 2px;
    width: 100%;
  `,
  ModalCloseButton: styled(IoMdClose)`
    display: flex;
    color: #0a0a0a;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
  `,
  SelectContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    height: 372px;
    background: #ffffff;
    flex: none;
    flex-grow: 0;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  SelectOptionContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    padding: 17.5px 0px 18.5px 0px;
    border-bottom: 1px solid #f3f3f3;
    cursor: pointer;
  `,
  SelectOptionLabel: styled.div<{ isClick: boolean }>`
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => (props.isClick ? "#00BAFF" : "#5A5E6A")};
  `,
  SelectOptionButton: styled(IoMdCheckmark)<{ isClick: boolean }>`
    display: flex;
    color: ${(props) => (props.isClick ? "#00BAFF" : "#BEC1C7")};
    width: 28px;
    height: 28px;
  `,
  SubmitButton: styled.button`
    display: block;
    height: 48px;
    width: 100%;
    text-align: center;
    padding: 12px 0px;
    background: #00baff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    :hover {
      background: #0094ca;
    }
  `,
}

const AdminHashtagSelectModal: React.FC<AdminHashtagSelectModalProps> = ({
  isOpen,
  imgUrl,
  hashTagList,
  hashTag,
  onRequestClose,
}) => {
  const { adminStore } = useContext(StoreContext)
  const { onChangeHashtagId, onCloseHashTagSelectModal } = adminStore
  const [thisHashTag, setThisHashTag] = React.useState([""])

  const onClickTag = (id: string) => {
    if (thisHashTag.includes(id)) {
      setThisHashTag([...thisHashTag].filter((tagId) => tagId !== id))
    } else {
      setThisHashTag([...thisHashTag, id])
    }
  }

  const onSubmit = () => {
    onChangeHashtagId(thisHashTag)
    onCloseHashTagSelectModal()
  }

  useEffect(() => {
    setThisHashTag(hashTag)
  }, [])

  return (
    <>
      {isOpen && (
        <S.Modal isOpen={isOpen}>
          <S.ModalContainer>
            <S.ModalHeader>
              <S.ModalHeaderBalencer />
              <S.ModalTitle>해시태그 선택 (선택 5개)</S.ModalTitle>
              <S.ModalCloseButton onClick={onRequestClose} />
            </S.ModalHeader>
            <Spacer height={28} />

            <S.SelectContainer>
              {hashTagList.map((hashTag) => (
                <S.SelectOptionContainer onClick={() => onClickTag(hashTag.id)}>
                  <S.SelectOptionLabel
                    isClick={thisHashTag.includes(hashTag.id)}
                  >
                    {hashTag.tagName}
                  </S.SelectOptionLabel>
                  <S.SelectOptionButton
                    isClick={thisHashTag.includes(hashTag.id)}
                  />
                </S.SelectOptionContainer>
              ))}
            </S.SelectContainer>

            <Spacer height={40} />

            <S.SubmitButton onClick={onSubmit}>등록</S.SubmitButton>
          </S.ModalContainer>
        </S.Modal>
      )}
    </>
  )
}

export default observer(AdminHashtagSelectModal)
