import {MdClose} from "react-icons/md";
import React from "react";
import styled from "styled-components";
import Spacer from "../Spacer";


interface CloseButtonProps {
    onClick: () => void
}

const S = {
    Container: styled.div`
      display: flex;
      padding: 40px;
      flex-direction: row;
      cursor: pointer;
      @media only screen and (max-width: 764px) {
        top: 20px;
        right: 20px;
      }
    `,
    CloseIcon: styled(MdClose)`
      font-size: 20px;
      color: white;
    `,
    Text: styled.div`
      font-family: Jura;
      font-size: 20px;
      color: white;
    `
}

const CloseButton: React.FC<CloseButtonProps> = ({onClick}) => {
    return (
        <S.Container onClick={onClick}>
            <S.Text>CLOSE</S.Text>
            <Spacer width={20}/>
            <S.CloseIcon/>
        </S.Container>

    )
}

export default CloseButton