import Spacer from "../../components/Spacer";
import Logo from "../../components/icons/logo";
import React, {createRef, Fragment, useContext, useEffect} from "react";
import {StoreContext} from "../../store/store.context";
import {useNavigate, useLocation} from "react-router-dom";
import {observer} from "mobx-react";
import styled from "styled-components";
import Mobile from "../../components/responsive/mobile";
import Desktop from "../../components/responsive/desktop";
import uuid from "react-uuid";
import {autorun} from "mobx";
import OutsideClickHandler from "../../components/OutsideClickHandler";
import Background from "../../components/Background";
import {InfinitySpin} from "react-loader-spinner";


const S = {
    Container: styled.div`
      width: 100vw;
      height: 100vh;
      z-index: 4;
      cursor: pointer;
    `,
    Filter: styled.div`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 51.04%, rgba(0, 0, 0, 0.3) 100%);
    `,
    VideoBackground: styled.video`
      position: fixed;
      width: 100vw;
      height: 100vh;
      padding: 0;
      object-fit: cover;
    `,
    NavBtnContainer: styled.div`
      display: flex;
      float: bottom;
      width: 100px;
      height: 320px;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 60px;
      right: 60px;
      z-index: 101;
    `,
    NavBtn: styled.a<{ selected?: boolean }>`
      flex: 1;
      font-weight: ${(props) => props.selected ? 400 : 100};
      color: ${({theme}) => theme.colors.white};
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: inherit;
      height: 40px;
      font-weight: 400;
      font-family: Jura;
      letter-spacing: 1px;
      cursor: pointer;

      &:hover {
        font-weight: bold;
        font-size: 20px;
      }

    `,
    SpinnerContainer: styled.div`
        width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
    `
}

const Intro = () => {
    const {globalStore} = useContext(StoreContext)
    const {
        isLoading,
        changeMenu,
        categories,
        getIntroData,
        getHomeData,
        selectedCategory,
        getModelsDataByCategory
    } = globalStore
    const navigate = useNavigate();
    const location = useLocation();

    const containerRef = createRef<HTMLDivElement>()
    const onClickOutside = () => {
        navigate('/home')
        changeMenu("UPDATED")

    }

    useEffect(() => {
        autorun(async () => {
            await getHomeData()
        })
    }, [])


    return (
        <>
        {isLoading &&
            <S.SpinnerContainer>
                <InfinitySpin
                    width='200'
                    color="#fff"
                />
            </S.SpinnerContainer>
            }
            {
                !isLoading &&
                <S.Container>
                <Mobile>
                    <S.VideoBackground playsInline loop autoPlay muted
                    >
                        <source src={"https://readymodel.s3.ap-northeast-2.amazonaws.com/mobile.mp4"}
                                type={"video/mp4"}/>
                    </S.VideoBackground>
                </Mobile>
                <Desktop>
                    <S.VideoBackground playsInline loop autoPlay muted
                    >
                        <source src={"https://readymodel.s3.ap-northeast-2.amazonaws.com/background.mp4"}
                                type={"video/mp4"}/>
                    </S.VideoBackground>
                </Desktop>


                <S.NavBtnContainer id={"wrapperId"}>
                    <OutsideClickHandler
                        ref={containerRef}
                        wrapperId={'wrapperId'} listen={true} onClickOutside={onClickOutside}>
                        {
                            categories
                                .filter((category, i) => category.showYn )
                                .map((category, i) =>
                                <Fragment key={uuid()}>
                                    <S.NavBtn
                                        key={uuid()}
                                        selected={selectedCategory === category.name}
                                        onClick={async () => {
                                            await getModelsDataByCategory(category.id);
                                            localStorage.setItem("before", selectedCategory);
                                            changeMenu(category.name)
                                            navigate('/home')
                                        }}>{category.name}
                                    </S.NavBtn>
                                    </Fragment>
                                )
                        }
                        <Spacer height={60}/>
                        <Logo width={"80"} height={"80"} onClick={() => {
                            changeMenu("UPDATED")
                            navigate('/home')
                            location.state = "UPDATED";
                        }}/>
                    </OutsideClickHandler>
                </S.NavBtnContainer>
                <S.Filter/>
            </S.Container>}
</>
    )
}

export default observer(Intro)