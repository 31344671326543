import React from "react"
import styled from "styled-components"
import Spacer from "../Spacer"
import Search from "../icons/Search"

interface AdminSelectBoxProps {
  value?: string
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  options?: Array<any>
  placeholder?: string
  width?: string
}

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 324px;
    height: 48px;
    left: 924px;
    top: 82px;
    color: black;
    border-radius: 8px;
  `,
  TextContainer: styled.div<{ width?: string }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    width: ${(props) => (props.width ? props.width : "372px")};
    height: 48px;
    background: #ffffff;
    border: 1px solid #e4e6ea;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 1;
    :focus-within {
      border: 1px solid #4e88f2;
    }
    :hover {
      border: 1px solid #4e88f2;
    }
  `,
  Input: styled.select`
    flex: 1;
    background-color: transparent;
    font-size: 14px;
    outline: none;
    border: none;

    &::placeholder {
      color: #9da0a8;
      font-weight: 100;
      font-family: Montserrat;
    }
  `,
}

const AdminSelectBox: React.FC<AdminSelectBoxProps> = ({
  value,
  width,
  options,
  onChange,
  placeholder,
}) => {
  return (
    <S.Container>
      <S.TextContainer style={{ width: width }}>
        <S.Input value={value} onChange={onChange} placeholder={placeholder}>
          <option value="">선택</option>
          {options}
        </S.Input>
      </S.TextContainer>
    </S.Container>
  )
}

export default AdminSelectBox
