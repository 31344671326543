import {GlobalStore} from "./GlobalStore";
import React from "react";
import {AdminStore} from "./AdminStore";

interface IStoreContext {
    globalStore: GlobalStore
    adminStore: AdminStore
}

const globalStore = new GlobalStore()
const adminStore = new AdminStore()

export const StoreContext = React.createContext<IStoreContext>({
    globalStore,
    adminStore
})