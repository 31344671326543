import React, {useContext} from "react"
import styled from "styled-components"
import { IoMdClose } from "react-icons/io"
import {StoreContext} from "../../store/store.context";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";

interface ImageModalProps {
  isOpen: boolean
  imgUrl?: string
  onRequestClose: () => void
}

const S = {
  Modal: styled.div<{ isOpen: boolean }>`
    animation: ${(props) => {
      if (props.isOpen) {
        return `FadeIn 0.5s ease-out`
      }
    }};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(25px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999999;

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @media only screen and (max-width: 1024px) {
      animation: none;
      scroll-behavior: unset;
    }
  `,
  ModalContainer: styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ModalImage: styled.img`
    width: 50vw;
    max-width: 50vh;
    cursor: pointer;
  `,
  ModalCloseButton: styled(IoMdClose)`
    position: relative;
    top: -31vh;
    right: -10px;
    color: white;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
  `,
}

const ImageModal: React.FC<ImageModalProps> = ({
  isOpen,
  imgUrl,
  onRequestClose,
}) => {
  const navigate = useNavigate()
  const {globalStore} = useContext(StoreContext)
  const {
    modalModel,
      setSelectedModel,
      setModelPortfolioCurrentImageUrl,
      closeImageModal,
      models
  } = globalStore

  return (
    <>
      {isOpen && (
        <S.Modal isOpen={isOpen}>
          <S.ModalContainer>
            <S.ModalImage onClick={()=> {
              if(modalModel?.model?.id){
                const model = models.find((model) => model.id === modalModel?.model?.id)
                if(model){
                  setSelectedModel(model)
                  closeImageModal()
                  setModelPortfolioCurrentImageUrl(
                      model.images.map((image) => image.url)
                  )
                    navigate('/model', {
                      state: model.id
                    })
                }
              }

            }} src={imgUrl} alt={"ModelImage"} />
            <S.ModalCloseButton onClick={onRequestClose} />
          </S.ModalContainer>
        </S.Modal>
      )}
    </>
  )
}
export default observer(ImageModal)
