const DragPointer = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4961 6.75098H4.49609C4.29735 6.75071 4.10687 6.67158 3.96643 6.53095C3.82599 6.39033 3.74707 6.19972 3.74707 6.00098C3.74707 5.80224 3.82599 5.61162 3.96643 5.471C4.10687 5.33038 4.29735 5.25124 4.49609 5.25098H19.4961C19.6949 5.25124 19.8855 5.33034 20.0261 5.47094C20.1667 5.61153 20.2458 5.80215 20.2461 6.00098C20.2458 6.19981 20.1667 6.39042 20.0261 6.53101C19.8855 6.67161 19.6949 6.75071 19.4961 6.75098Z"
        fill="#0A0A0A"
      />
      <path
        d="M19.4961 12.751H4.49609C4.29735 12.7507 4.10687 12.6716 3.96643 12.531C3.82599 12.3903 3.74707 12.1997 3.74707 12.001C3.74707 11.8022 3.82599 11.6116 3.96643 11.471C4.10687 11.3304 4.29735 11.2512 4.49609 11.251H19.4961C19.6949 11.2512 19.8855 11.3303 20.0261 11.4709C20.1667 11.6115 20.2458 11.8021 20.2461 12.001C20.2458 12.1998 20.1667 12.3904 20.0261 12.531C19.8855 12.6716 19.6949 12.7507 19.4961 12.751Z"
        fill="#0A0A0A"
      />
      <path
        d="M19.4961 18.751H4.49609C4.29735 18.7507 4.10687 18.6716 3.96643 18.531C3.82599 18.3903 3.74707 18.1997 3.74707 18.001C3.74707 17.8022 3.82599 17.6116 3.96643 17.471C4.10687 17.3304 4.29735 17.2512 4.49609 17.251H19.4961C19.6949 17.2512 19.8855 17.3303 20.0261 17.4709C20.1667 17.6115 20.2458 17.8021 20.2461 18.001C20.2458 18.1998 20.1667 18.3904 20.0261 18.531C19.8855 18.6716 19.6949 18.7507 19.4961 18.751Z"
        fill="#0A0A0A"
      />
    </svg>
  )
}

export default DragPointer
