import React from "react"
import styled from "styled-components"

interface AdminAddButtonProps {
  onClick?: () => void
  width?: number
  height?: number
}

const S = {
  Container: styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    width: 44px;
    height: 44px;

    background: #ffffff;
    /* Gray Scale/Gray3 */

    border: 1px solid #e4e6ea;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

    :hover {
      background: #e4e6ea;
    }

    :active {
      background: #b4b6ba;
    }
  `,
}

const AdminAddButton: React.FC<AdminAddButtonProps> = ({
  onClick,
  width = 24,
  height = 24,
}) => {
  return (
    <S.Container onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.002 20.8849C11.7764 20.8846 11.5603 20.7948 11.4009 20.6353C11.2415 20.4757 11.152 20.2594 11.152 20.0339V12.8579H3.97595C3.75043 12.8576 3.53426 12.7678 3.37488 12.6083C3.2155 12.4487 3.12598 12.2324 3.12598 12.0069C3.12598 11.7814 3.2155 11.5651 3.37488 11.4055C3.53426 11.2459 3.75043 11.1561 3.97595 11.1559H11.152V3.98088C11.1522 3.75544 11.2418 3.53929 11.4011 3.37978C11.5604 3.22027 11.7765 3.13041 12.002 3.12988C12.2276 3.13015 12.4439 3.21989 12.6034 3.37943C12.7629 3.53896 12.8526 3.75526 12.8529 3.98088V11.1569H20.0289C20.2545 11.1571 20.4706 11.2469 20.63 11.4065C20.7894 11.5661 20.8789 11.7824 20.8789 12.0079C20.8789 12.2334 20.7894 12.4497 20.63 12.6093C20.4706 12.7688 20.2545 12.8586 20.0289 12.8589H12.8529V20.0299C12.8533 20.1419 12.8316 20.253 12.7891 20.3566C12.7465 20.4603 12.6839 20.5545 12.6049 20.6339C12.5258 20.7133 12.4318 20.7764 12.3284 20.8195C12.2249 20.8625 12.114 20.8848 12.002 20.8849Z"
          fill="#0A0A0A"
        />
      </svg>
    </S.Container>
  )
}

export default AdminAddButton
