import React from "react";
import styled from "styled-components";


const S = {
    Svg: styled.svg`
        cursor: pointer;
    `
}

interface MinusProps {
    onClick: () => void
}


const Minus:React.FC<MinusProps> = ({onClick}) => {
    return (
        <S.Svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 13.0005L17 13.0005C17.5523 13.0005 18 12.5528 18 12.0005C18 11.4482 17.5523 11.0005 17 11.0005L7 11.0005C6.44771 11.0005 6 11.4482 6 12.0005C6 12.5528 6.44771 13.0005 7 13.0005Z" fill="#BEC1C7"/>
        </S.Svg>

    )
}

export default Minus