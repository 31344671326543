import React from "react";
import styled from "styled-components";

interface HashTagProps {
    text: string
    onClick?: () => void
}

const S = {
    Tag: styled.div`
      display: block;
      float: left;
      font-size: 14px;
      color: white;
      padding: 8px;
      margin-right: 12px;
      cursor: pointer;
    `
}

const HashTag: React.FC<HashTagProps> = ({text, onClick}) => {
    return (
        <S.Tag onClick={onClick}>
            {text}
        </S.Tag>
    )
}

export default HashTag