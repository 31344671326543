import React from "react";
import {useMediaQuery} from "react-responsive";

interface DesktopProps{
    children?: any
}


const Desktop:React.FC<DesktopProps> = ({children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024})
    return isDesktop? children: null
}

export default Desktop
