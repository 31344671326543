export class UtilService {
  static dataURLtoFile = async (
    url: string,
    fileName: string,
    mimeType: string
  ) => {
    const res: Response = await fetch(url)
    const blob: Blob = await res.blob()
    return new File([blob], `${new Date().getTime()}_${fileName}`, {
      type: mimeType,
    })
  }

  static fileUrlToFileName = (url: string) => {
    const urlArray = url.split("/")
    return urlArray[urlArray.length - 1]
  }
}
