import React from "react"
import styled from "styled-components"

interface AdminHistoryButtonProps {
  onClick?: () => void
  width?: number
  height?: number
}

const S = {
  Container: styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    width: 44px;
    height: 44px;

    background: #ffffff;
    /* Gray Scale/Gray3 */

    border: 1px solid #e4e6ea;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

    :hover {
      background: #e4e6ea;
    }

    :active {
      background: #b4b6ba;
    }
  `,
}

const AdminHistoryButton: React.FC<AdminHistoryButtonProps> = ({
  onClick,
  width = 24,
  height = 24,
}) => {
  return (
    <S.Container onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.75 21.005H3.75C3.55109 21.005 3.36032 20.926 3.21967 20.7853C3.07902 20.6447 3 20.4539 3 20.255C3 20.0561 3.07902 19.8653 3.21967 19.7247C3.36032 19.584 3.55109 19.505 3.75 19.505H9.75C9.94891 19.505 10.1397 19.584 10.2803 19.7247C10.421 19.8653 10.5 20.0561 10.5 20.255C10.5 20.4539 10.421 20.6447 10.2803 20.7853C10.1397 20.926 9.94891 21.005 9.75 21.005Z"
            fill="#0A0A0A"
          />
          <path
            d="M9.75 18.005H3.75C3.55109 18.005 3.36032 17.926 3.21967 17.7853C3.07902 17.6447 3 17.4539 3 17.255C3 17.0561 3.07902 16.8653 3.21967 16.7247C3.36032 16.584 3.55109 16.505 3.75 16.505H9.75C9.94891 16.505 10.1397 16.584 10.2803 16.7247C10.421 16.8653 10.5 17.0561 10.5 17.255C10.5 17.4539 10.421 17.6447 10.2803 17.7853C10.1397 17.926 9.94891 18.005 9.75 18.005Z"
            fill="#0A0A0A"
          />
          <path
            d="M9.75 15.005H3.75C3.55109 15.005 3.36032 14.926 3.21967 14.7853C3.07902 14.6447 3 14.4539 3 14.255C3 14.0561 3.07902 13.8653 3.21967 13.7247C3.36032 13.584 3.55109 13.505 3.75 13.505H9.75C9.94891 13.505 10.1397 13.584 10.2803 13.7247C10.421 13.8653 10.5 14.0561 10.5 14.255C10.5 14.4539 10.421 14.6447 10.2803 14.7853C10.1397 14.926 9.94891 15.005 9.75 15.005Z"
            fill="#0A0A0A"
          />
          <path
            d="M12.75 20.971C12.65 20.9781 12.5496 20.9651 12.4547 20.9327C12.3598 20.9003 12.2723 20.8493 12.1974 20.7826C12.1226 20.7159 12.0618 20.6349 12.0188 20.5444C11.9757 20.4538 11.9513 20.3556 11.9468 20.2554C11.9423 20.1552 11.9579 20.0552 11.9927 19.9612C12.0276 19.8671 12.0808 19.781 12.1494 19.7079C12.2181 19.6348 12.3006 19.5762 12.3923 19.5355C12.4839 19.4948 12.5827 19.4729 12.683 19.471C14.1024 19.3412 15.4554 18.8097 16.5836 17.9386C17.7118 17.0676 18.5685 15.8931 19.0532 14.5527C19.5379 13.2123 19.6306 11.7616 19.3205 10.3704C19.0104 8.97919 18.3103 7.70517 17.3022 6.69756C16.2941 5.68994 15.0197 4.99045 13.6284 4.68103C12.237 4.3716 10.7863 4.46504 9.44617 4.95041C8.10603 5.43579 6.93194 6.29299 6.06143 7.42161C5.19093 8.55023 4.66005 9.90354 4.53098 11.323C4.52211 11.4211 4.49402 11.5165 4.44829 11.6037C4.40256 11.691 4.34009 11.7684 4.26446 11.8315C4.18882 11.8945 4.1015 11.9421 4.00748 11.9715C3.91346 12.0008 3.81458 12.0114 3.71648 12.0025C3.61838 11.9936 3.52299 11.9655 3.43575 11.9198C3.34851 11.8741 3.27114 11.8116 3.20804 11.736C3.14494 11.6604 3.09736 11.573 3.06801 11.479C3.03866 11.385 3.02811 11.2861 3.03698 11.188C3.19248 9.48479 3.83007 7.86114 4.87508 6.50722C5.92009 5.1533 7.32924 4.12517 8.93751 3.54325C10.5458 2.96133 12.2865 2.84971 13.956 3.22147C15.6254 3.59322 17.1543 4.43296 18.3637 5.64233C19.573 6.85169 20.4128 8.38062 20.7845 10.05C21.1563 11.7194 21.0447 13.4602 20.4627 15.0685C19.8808 16.6767 18.8527 18.0859 17.4988 19.1309C16.1449 20.1759 14.5212 20.8135 12.818 20.969C12.795 20.971 12.772 20.971 12.75 20.971Z"
            fill="#0A0A0A"
          />
          <path
            d="M12 7.505C11.8011 7.505 11.6103 7.58402 11.4697 7.72467C11.329 7.86533 11.25 8.05609 11.25 8.255V12.005C11.2502 12.2039 11.3293 12.3945 11.47 12.535L13.72 14.785C13.7887 14.8587 13.8715 14.9178 13.9635 14.9588C14.0555 14.9998 14.1548 15.0218 14.2555 15.0236C14.3562 15.0254 14.4562 15.0068 14.5496 14.9691C14.643 14.9314 14.7278 14.8753 14.799 14.804C14.8703 14.7328 14.9264 14.648 14.9641 14.5546C15.0018 14.4612 15.0204 14.3612 15.0186 14.2605C15.0168 14.1598 14.9948 14.0605 14.9538 13.9685C14.9128 13.8765 14.8537 13.7937 14.78 13.725L12.75 11.695V8.255C12.75 8.05609 12.671 7.86533 12.5303 7.72467C12.3897 7.58402 12.1989 7.505 12 7.505Z"
            fill="#0A0A0A"
          />
        </svg>
      </svg>
    </S.Container>
  )
}

export default AdminHistoryButton
