import Spacer from "../../components/Spacer";
import Background from "../../components/Background";
import HomeButton from "../../components/buttons/HomeButton";



const NotFoundPage = () => {
    return (
        <Background>
            <div>페이지를 찾을 수 없습니다</div>
            <Spacer height={20}/>
            <HomeButton/>
        </Background>
    )
}

export default NotFoundPage