import styled from "styled-components";
import {IoMdCheckmark, IoMdClose} from "react-icons/io";
import React, {useContext, useEffect} from "react";
import Spacer from "../Spacer";
import uuid from "react-uuid";
import {AgreementHistory} from "../../models/interfaces/AgreementHistory";
import AdminTableCheckbox from "../inputs/AdminTableCheckbox";
import {observer} from "mobx-react";
import {StoreContext} from "../../store/store.context";
import {autorun} from "mobx";

interface AdminAgreementHistoryModalProps {
    isOpen: boolean
    onRequestClose: () => void
    histories: AgreementHistory[]
}

const S = {
    Modal: styled.div<{ isOpen: boolean }>`
      animation: ${(props) => {
        if (props.isOpen) {
            return `FadeIn 0.1s ease-out`
        }
    }};
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      /* backdrop-filter: blur(25px); */
      background: rgba(0, 0, 0, 0.4);
      z-index: 100;

      @keyframes FadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `,
    ModalContainer: styled.div`
      width: 528px;
      padding: 21px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: #ffffff;
      border-radius: 16px;
    `,
    ModalHeader: styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 28px;
    `,
    ModalHeaderBalencer: styled.div`
      display: flex;
      width: 28px;
      height: 28px;
    `,
    ModalTitle: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-family: Montserrat, sans-serif;
      font-size: 20px;
      color: #000000;
      @media only screen and (max-width: 764px) {
        font-size: 24px;
      }
    `,
    ModalDivider: styled.div`
      background-color: white;
      height: 2px;
      width: 100%;
    `,
    ModalCloseButton: styled(IoMdClose)`
      display: flex;
      color: #0a0a0a;
      font-weight: bold;
      font-size: 28px;
      cursor: pointer;
    `,
    SelectContainer: styled.div`
      display: flex;
      flex-direction: column;
      padding: 0px;
      width: 100%;
      height: 372px;
      background: #ffffff;
      flex: none;
      flex-grow: 0;
      overflow: auto;

      ::-webkit-scrollbar {
        display: none;
      }
    `,
    SelectOptionContainer: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 21px;
      padding: 17.5px 0px 18.5px 0px;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
    `,
    SelectOptionLabel: styled.div<{ isClick: boolean }>`
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: ${(props) => (props.isClick ? "#00BAFF" : "#5A5E6A")};
    `,
    SelectOptionButton: styled(IoMdCheckmark)<{ isClick: boolean }>`
      display: flex;
      color: ${(props) => (props.isClick ? "#00BAFF" : "#BEC1C7")};
      width: 28px;
      height: 28px;
    `,
    SubmitButton: styled.button`
      /* display: flex; */
      height: 48px;
      width: 100%;
      padding: 12px 113px;
      background: #00baff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-family: "Pretendard Variable";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;

      :hover {
        background: #0094ca;
      }
    `,
    HistoryContainer: styled.div`
      width: 100%;
      height: 200px;
      overflow-y: scroll;
    `,
    HistoryTitleContainer: styled.div`
      display: flex;
      flex-direction: row;
      width: 100%;
      background-color: #F9FAFC;
      height: 20px;
      font-size: 12px;
    `,
    HistoryItemContainer: styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #EFF1F4;
      padding-top: 12px;
      padding-bottom: 12px;
    `,
    HistoryIndexContainer: styled.div`
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    HistoryNoContainer: styled.div`
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    HistoryDateContainer: styled.div`
      flex: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    InputContainer: styled.div`
      display: flex;
      flex-direction: column;
      gap: 20px;
    `,
    InputSubContainer: styled.div`
      width: 100%;
      gap: 8px;
      display: flex;
      justify-content: center;
    `,
    ButtonContainer: styled.div`
      display: flex;
      gap: 20px;
    `,
    InputLabel: styled.div`
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #5a5e6a;
      margin-bottom: 8px;
    `,
    MessageBox: styled.div`
      font-weight: 400;
      font-size: 14px;
      line-height: 170%;
      width: 100%;
      /* or 24px */

      align-items: center;
      text-align: center;
    `,
}


const AgreementHistoryModal: React.FC<AdminAgreementHistoryModalProps> = ({
                                                                                   isOpen,
                                                                                   onRequestClose,
                                                                                   histories
                                                                               }) => {
    const {globalStore}= useContext(StoreContext)
    const {
        selectedHistoryIndex,
        selectHistoryItem,
        getAgreementHistoryFromModal,
        getAgreementHistories
    } = globalStore

    useEffect(() => {
        autorun(async() => {
            await getAgreementHistories()
        })
    }, [])

    return (
        <>
            {isOpen && (
                <S.Modal isOpen={isOpen}>
                    <S.ModalContainer>
                        <S.ModalHeader>
                            <S.ModalHeaderBalencer/>
                            <S.ModalTitle>변경내역</S.ModalTitle>
                            <S.ModalCloseButton onClick={onRequestClose}/>
                        </S.ModalHeader>

                        <Spacer height={12}/>
                        <S.HistoryContainer>
                            <S.HistoryTitleContainer>
                                <S.HistoryIndexContainer></S.HistoryIndexContainer>
                                <S.HistoryNoContainer>No.</S.HistoryNoContainer>
                                <S.HistoryDateContainer>저장일</S.HistoryDateContainer>
                            </S.HistoryTitleContainer>
                            <Spacer height={20}/>
                            {histories.map((history, i) => <S.HistoryItemContainer key={uuid()}>
                                <S.HistoryIndexContainer>
                                    <AdminTableCheckbox checked={i === selectedHistoryIndex} onClick={() => selectHistoryItem(i)}/>
                                </S.HistoryIndexContainer>
                                <S.HistoryNoContainer>
                                    {i+1}
                                </S.HistoryNoContainer>
                                <S.HistoryDateContainer>
                                    {history.createdAt}
                                </S.HistoryDateContainer>
                            </S.HistoryItemContainer>)}
                        </S.HistoryContainer>
                        <Spacer height={40}/>

                        <S.InputSubContainer>
                            <S.SubmitButton onClick={getAgreementHistoryFromModal}>불러오기</S.SubmitButton>
                        </S.InputSubContainer>
                    </S.ModalContainer>
                </S.Modal>
            )}
        </>
    )
}

export default observer(AgreementHistoryModal)