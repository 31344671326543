import { observer } from "mobx-react"
import { useContext } from "react"
import { useOutlet } from "react-router-dom"
import styled from "styled-components"
import { StoreContext } from "../../store/store.context"
import AdminHeader from "../header/AdminHeader"
import AdminNoticeModal from "../modals/AdminNoticeModal"
import AdminNavigation from "../navigations/AdminNavigation"
import { Oval } from "react-loader-spinner"

const S = {
  Container: styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    min-width: 1440px;
    min-height: 100vh;
    width: 100vw;
    background-color: #f7f9fb;
  `,
  Body: styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
  `,
  LoadingContainer: styled.div<{ loading: boolean }>`
    display: ${(props) => (props.loading ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: ${(props) => (props.loading ? "100%" : "0px")};
    height: ${(props) => (props.loading ? "100%" : "0px")};
    z-index: 9999;
  `,
}

const AdminLayout = () => {
  const outlet = useOutlet()
  const { adminStore } = useContext(StoreContext)
  const {
    noticeModal,
    noticeTitle,
    noticeMessage,
    loading,
    onCloseNoticeModal,
  } = adminStore

  return (
    <S.Container>
      <S.LoadingContainer loading={loading}>
        <Oval
          height={80}
          width={80}
          color="#00baff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#97e3ff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </S.LoadingContainer>
      <AdminHeader />
      <S.Body>
        <AdminNavigation />
        <AdminNoticeModal
          isOpen={noticeModal}
          title={noticeTitle}
          noticeMessage={noticeMessage}
          onRequestClose={onCloseNoticeModal}
        />
        {outlet}
      </S.Body>
    </S.Container>
  )
}

export default observer(AdminLayout)
