import { observer } from "mobx-react"
import React, { useContext } from "react"
import styled from "styled-components"
import AdminAddButton from "../../../../components/buttons/AdminAddButton"
import AdminDeleteButton from "../../../../components/buttons/AdminDeleteButton"
import AdminTableCheckbox from "../../../../components/inputs/AdminTableCheckbox"
import AdminResourceCreateModal from "../../../../components/modals/AdminResourceCreateModal"
import AdminResourceDeleteModal from "../../../../components/modals/AdminResourceDeleteModal"
import AdminResourceUpdateModal from "../../../../components/modals/AdminResourceUpdateModal"
import AdminSwitch from "../../../../components/switch/AdminSwitch"
import { StoreContext } from "../../../../store/store.context"

const S = {
  Container: styled.div`
    height: inherit;
    padding: 0px 36px;
    width: 100%;
    background-color: #f7f9fb;
    overflow-y: auto;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    width: 100%;
    height: 48px;
    padding-top: 36px;
  `,
  ControllBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
  `,
  Title: styled.text`
    display: flex;
    width: auto;
    height: 36px;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f4a;
  `,
  TitleSubContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  ButtonBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
  `,
  TableHedaer: styled.thead`
    padding: 0px;
    height: 20px;
    border-radius: 8px;
    flex: none;
  `,
  TableBody: styled.tbody``,
  TableHeaderRow: styled.tr`
    th:nth-child(1) {
      width: 73px;
    }
    th:nth-child(2) {
      width: 78px;
    }

    th:nth-child(5) {
      width: 100px;
    }
    th:last-child {
      width: 100px;
      border: none;
    }
  `,
  TableBodyRow: styled.tr`
    background-color: white;

    td:first-child {
      border-radius: 8px 0 0 8px;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  `,
  TableHeaderCol: styled.th`
    box-sizing: border-box;
    align-items: center;
    padding: 0px 20px;
    width: auto;
    height: 20px;
    border-right: 1px solid #e4e6ea;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 500;
  `,
  TableBodyCol: styled.td`
    box-sizing: border-box;
    align-items: center;
    /* justify-content: center; */
    padding: 8px;
    width: auto;
    height: 52px;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
  `,
  Link: styled.div`
    font-size: 10px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #5a5e6a;
    cursor: pointer;
  `,
}

const AdminDetailHashTag = () => {
  const { adminStore } = useContext(StoreContext)
  const {
    hashTagManageList,
    hashTagCheckAll,
    craeteModal,
    createValue,
    updateModal,
    updateValue,
    deleteModal,
    deleteMessage,
    hashTagActiveAll,
    onChangeActiveAll,
    onPageInitHashTagManage,
    onChangeAdminHashTagCheckAll,
    onChangeActive,
    onChangeAdminHashTagCheck,
    onOpenHashTagCreateModal,
    onOpenHashTagDeleteModal,
    onCloseHashTagCreateModal,
    onCloseHashTagUpdateModal,
    onCloseHashTagDeleteModal,
    onSubmitCreateHashTag,
    onSubmitUpdateHashTag,
    onSubmitDeleteHashTag,
    onChangeCreateValue,
    onChangeUpdateValue,
    onOpenHashTagUpdateModal,
  } = adminStore


  React.useEffect(() => {
    onPageInitHashTagManage()
  }, [])
  return (
    <>
      {/* 해시태그 추가모달 */}
      <AdminResourceCreateModal
        isOpen={craeteModal}
        value={createValue}
        resourceName="해시태그"
        onRequestClose={onCloseHashTagCreateModal}
        onSubmit={onSubmitCreateHashTag}
        onChange={onChangeCreateValue}
      />
      {/* 해시태그 수정모달 */}
      <AdminResourceUpdateModal
        isOpen={updateModal}
        value={updateValue}
        resourceName="해시태그"
        onRequestClose={onCloseHashTagUpdateModal}
        onSubmit={onSubmitUpdateHashTag}
        onChange={onChangeUpdateValue}
      />
      {/* 해시태그 삭제모달 */}
      <AdminResourceDeleteModal
        isOpen={deleteModal}
        resourceName="해시태그"
        deleteMessage={deleteMessage}
        onRequestClose={onCloseHashTagDeleteModal}
        onSubmit={onSubmitDeleteHashTag}
      />
      <S.Container>
        <S.TitleBox>
          <S.TitleSubContainer>
            <S.Title>해시태그 관리</S.Title>
            <AdminSwitch
              isTrue={hashTagActiveAll}
              onChange={onChangeActiveAll}
            />
          </S.TitleSubContainer>
          <S.ControllBox>
            <AdminAddButton onClick={onOpenHashTagCreateModal} />
            <AdminDeleteButton
              onClick={() => {
                onOpenHashTagDeleteModal(deleteMessage)
              }}
            />
          </S.ControllBox>
        </S.TitleBox>
        <S.Table>
          <S.TableHedaer>
            <S.TableHeaderRow>
              <S.TableHeaderCol>
                <AdminTableCheckbox
                  checked={hashTagCheckAll}
                  onClick={() => onChangeAdminHashTagCheckAll()}
                />
              </S.TableHeaderCol>
              <S.TableHeaderCol>NO.</S.TableHeaderCol>
              <S.TableHeaderCol>해시태그</S.TableHeaderCol>
              <S.TableHeaderCol>해당 모델수</S.TableHeaderCol>
              <S.TableHeaderCol>수정</S.TableHeaderCol>
              <S.TableHeaderCol>노출</S.TableHeaderCol>
            </S.TableHeaderRow>
          </S.TableHedaer>
          <S.TableBody>
            {hashTagManageList.map((hashTag, idx) => (
              <S.TableBodyRow key={`hashTag/${idx}`}>
                <S.TableBodyCol>
                  <AdminTableCheckbox
                    checked={hashTag.checkYn}
                    onClick={() => onChangeAdminHashTagCheck(idx)}
                  />
                </S.TableBodyCol>
                <S.TableBodyCol>{idx + 1}</S.TableBodyCol>
                <S.TableBodyCol>{hashTag.tagName}</S.TableBodyCol>
                <S.TableBodyCol>{hashTag.modelCount}</S.TableBodyCol>
                <S.TableBodyCol>
                  <S.Link
                    onClick={() => {
                      onOpenHashTagUpdateModal(hashTag.tagName, idx)
                    }}
                  >
                    수정
                  </S.Link>
                </S.TableBodyCol>
                <S.TableBodyCol>
                  <AdminSwitch
                    isTrue={hashTag.active}
                    onChange={() => {
                      onChangeActive(hashTag, idx)
                    }}
                  />
                </S.TableBodyCol>
              </S.TableBodyRow>
            ))}
          </S.TableBody>
        </S.Table>
      </S.Container>
    </>
  )
}

export default observer(AdminDetailHashTag)
