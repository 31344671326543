import styled from "styled-components"
import AddButton from "../buttons/AddButton"
import React, { useContext } from "react"
import MinusButton from "../buttons/MinusButton"
import { Model } from "../../models/interfaces/Model"
import uuid from "react-uuid"
import { StoreContext } from "../../store/store.context"
import { observer } from "mobx-react"

interface ModelCardProps {
  imageOnClick: () => void
  added?: boolean
  addOnClick?: () => void
  minusOnClick?: () => void
  nameOnClick?: () => void
  modelData: Model
}

const S = {
  ModelCard: styled.div`
    max-width: 40vw;
    aspect-ratio: 274/420;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  ModelNameContainer: styled.div`
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  ModelEName: styled.div`
    color: white;
    font-size: 16px;
    font-family: Jura, sans-serif;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    flex: 1;
    @media only screen and (max-width: 764px) {
      font-size: 14px;
    }
  `,
  ModelKName: styled.span`
    padding-bottom: 8px;
    font-size: 16px;
    color: white;
    font-family: Jura, sans-serif;
    font-weight: 100;
    margin-right: 1px;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
    align-items: center;
    height: 100%;
    padding-top: 8px;
    flex: 1;
    @media only screen and (max-width: 764px) {
      font-size: 10px;
      display: flex;
      justify-content: flex-end;
      padding: 4px;
    }
  `,
  ModelImage: styled.img`
    color: white;
    width: inherit;
    height: inherit;
    max-width: 100%;
    background-repeat: no-repeat;
    aspect-ratio: 274/359;
    background-size: cover;
    cursor: pointer;
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(0%);
    }
  `,
  ModelTagContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  ModelTag: styled.div`
    color: white;
    font-size: 12px;
    font-weight: 100;
    font-family: Jura;
    margin-right: 12px;
  `,
}

const ModelCard: React.FC<ModelCardProps> = ({
  imageOnClick,
  added,
  modelData,
  addOnClick,
  nameOnClick,
  minusOnClick,
}) => {
  const { globalStore } = useContext(StoreContext)
  const { hashTags, showAllHashtag } = globalStore
  const {
    name,
    eFirstName,
    eLastName,
    mainImageUrl,
  } = modelData
  return (
    <S.ModelCard>
      <S.ModelImage
        onClick={imageOnClick}
        src={mainImageUrl}
        alt={"ModelImage"}
        loading={"lazy"}

      />
      <S.ModelNameContainer>
        <S.ModelEName onClick={nameOnClick}>
          {eLastName + " " + eFirstName}
        </S.ModelEName>
        <S.ModelKName>{name}</S.ModelKName>
        {!added ? (
          <AddButton  onClick={addOnClick} />
        ) : (
          <MinusButton onClick={minusOnClick} />
        )}
      </S.ModelNameContainer>
      {/* <S.ModelTagContainer>
        {showAllHashtag && hashTags
            .filter((hashTag) => hashTag.active === true)
            .map((hashTag) => {
              const tag = modelData.hashTags.find((item) => item === hashTag.id )
              if(tag) return <S.ModelTag key={uuid()}>#{hashTag.tagName}</S.ModelTag>
              return false
        })}
      </S.ModelTagContainer> */}
    </S.ModelCard>
  )
}
export default observer(ModelCard)
