import { observer } from "mobx-react"
import React from "react"
import { useContext } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import styled from "styled-components"
import DragPointer from "../../../../components/icons/DragPointer"
import Spacer from "../../../../components/Spacer"
import { StoreContext } from "../../../../store/store.context"

const S = {
  Container: styled.div`
    height: inherit;
    padding: 0px 36px;
    width: 100%;
    background-color: #f7f9fb;
    overflow-y: auto;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    width: 100%;
    height: 48px;
    padding-top: 36px;
  `,
  ControllBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
  `,
  Title: styled.text`
    display: flex;
    width: auto;
    height: 36px;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f4a;
  `,
  TitleSubContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  SaveButton: styled.button`
    height: 48px;
    padding: 12px 60px;
    background: #00baff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    :hover {
      background: #0094ca;
    }
  `,
  ButtonBox: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  `,
  Navigation: styled.div`
    display: flex;
    gap: 20px;
  `,
  NavigationItem: styled.div<{ isCurrentPage: boolean }>`
    display: flex;
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => {
      if (props.isCurrentPage) {
        return `#3b3f4a`
      } else {
        return `#BEC1C7`
      }
    }};
    cursor: pointer;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
  `,
  TableHeader: styled.thead`
    padding: 0px;
    height: 20px;
    border-radius: 8px;
    flex: none;
  `,
  TableBody: styled.tbody``,
  TableHeaderRow: styled.tr`
    th:first-child {
      width: 78px;
    }
    th:nth-child(2) {
      width: 78px;
    }
    th:nth-child(3) {
      width: 108px;
    }
    th:last-child {
      border: none;
      padding: 0 16px;
      text-align: start;
    }
  `,
  TableBodyRow: styled.tr<{ isDragging?: boolean }>`
    background-color: ${(props) => (props.isDragging ? "#E5F8FF" : "white")};
    width: 100%;
    display: ${(props) => (props.isDragging ? "flex" : "table-row")};
    border-radius: 8px;
    vertical-align: middle;

    td:first-child {
      width: 78px;
      border-radius: 8px 0 0 8px;
      cursor: pointer;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
    td:nth-child(2) {
      width: 78px;
      font-weight: 400;
      font-size: 14px;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
    td:nth-child(3) {
      width: 108px;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: center;
    }
    td:last-child {
      width: auto;
      border-radius: 0 8px 8px 0;
      text-align: start;
      display: ${(props) => (props.isDragging ? "flex" : "table-col")};
      justify-content: start;
      flex: auto;
    }
  `,
  TableHeaderCol: styled.th`
    box-sizing: border-box;
    width: auto;
    height: 20px;
    border-right: 1px solid #e4e6ea;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 500;
  `,
  TableBodyCol: styled.td`
    box-sizing: border-box;
    align-items: center;
    padding: 8px;
    width: auto;
    height: 91px;
    align-items: center;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
  `,
  Link: styled.div`
    font-size: 10px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #5a5e6a;
    cursor: pointer;
  `,
  Image: styled.img`
    height: 75px;
    width: 50px;
  `,
}

const AdminDetailLayout = () => {
  const { adminStore } = useContext(StoreContext)

  const {
    currentCategoryId,
    layoutCateogoryList,
    adminLayout,
    onPageInitLayoutManage,
    onClickCategory,
    onDragEndModelCategoryModel,
    onSubmitLayout,
  } = adminStore

  React.useEffect(() => {
    onPageInitLayoutManage()
  }, [])

  return (
    <S.Container>
      <S.TitleBox>
        <S.TitleSubContainer>
          <S.Title>레이아웃 관리</S.Title>
        </S.TitleSubContainer>
        <S.ControllBox>
          <S.SaveButton onClick={onSubmitLayout}>저장하기</S.SaveButton>
        </S.ControllBox>
      </S.TitleBox>
      <S.Navigation>
        {layoutCateogoryList.map((layoutCategory) => (
          <S.NavigationItem
            isCurrentPage={layoutCategory.id === currentCategoryId}
            onClick={() => onClickCategory(layoutCategory.id)}
          >
            {layoutCategory.name}
          </S.NavigationItem>
        ))}
      </S.Navigation>

      <Spacer height={12} />

      <S.Table>
        <S.TableHeader>
          <S.TableHeaderRow>
            <S.TableHeaderCol>순서변경</S.TableHeaderCol>
            <S.TableHeaderCol>순서</S.TableHeaderCol>
            <S.TableHeaderCol>이미지</S.TableHeaderCol>
            <S.TableHeaderCol>이름</S.TableHeaderCol>
          </S.TableHeaderRow>
        </S.TableHeader>

        {adminLayout !== undefined &&
          adminLayout.categoryGroups.map((group, index) =>
            group.categoryId !== currentCategoryId ? (
              <></>
            ) : (
              <DragDropContext onDragEnd={onDragEndModelCategoryModel}>
                <Droppable
                  // key={uuid()}
                  droppableId={`group${group.categoryId}`}
                >
                  {(provided1, snapShot1) => (
                    <S.TableBody
                      ref={provided1.innerRef}
                      {...provided1.droppableProps}
                    >
                      {group.models.map((model, index) => (
                        <Draggable
                          key={`model/${model.id}`}
                          draggableId={`model/${model.id}`}
                          index={index}
                        >
                          {(provided2, snapShot2) => (
                            <S.TableBodyRow
                              ref={provided2.innerRef}
                              {...provided2.draggableProps}
                              isDragging={snapShot2.isDragging}
                            >
                              <S.TableBodyCol {...provided2.dragHandleProps}>
                                <DragPointer />
                              </S.TableBodyCol>
                              <S.TableBodyCol>{index + 1}</S.TableBodyCol>
                              <S.TableBodyCol>
                                <S.Image src={model.mainImageUrl} />
                              </S.TableBodyCol>
                              <S.TableBodyCol>{model.name}</S.TableBodyCol>
                            </S.TableBodyRow>
                          )}
                        </Draggable>
                      ))}
                      {provided1.placeholder}
                    </S.TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            )
          )}
      </S.Table>
    </S.Container>
  )
}

export default observer(AdminDetailLayout)
